define("mpforst-ember/app/pods/assistent-wzpx-result/index/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 8,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "data-alert", "");
          dom.setAttribute(el1, "class", "alert-box alert radius");
          dom.setAttribute(el1, "style", "margin: 1em;");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("h2");
          dom.setAttribute(el2, "style", "color: white");
          var el3 = dom.createElement("i");
          dom.setAttribute(el3, "class", "fa fa-exclamation-triangle");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("  Fehler");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("p");
          var el3 = dom.createTextNode("\n      Leider ist ein Problem aufgetreten\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 15,
                      "column": 8
                    },
                    "end": {
                      "line": 17,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          neue Bestände ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("werden angelegt");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 17,
                      "column": 8
                    },
                    "end": {
                      "line": 19,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          neuer Bestand ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("wird angelegt");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 6
                  },
                  "end": {
                    "line": 21,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element3 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element3, 1, 1);
                morphs[1] = dom.createMorphAt(element3, 3, 3);
                return morphs;
              },
              statements: [["content", "resultNewCount", ["loc", [null, [14, 8], [14, 26]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "gt", [["get", "resultNewCount", ["loc", [null, [15, 18], [15, 32]]], 0, 0, 0, 0], 1], [], ["loc", [null, [15, 14], [15, 35]]], 0, 0]], [], 0, 1, ["loc", [null, [15, 8], [19, 15]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 25,
                      "column": 8
                    },
                    "end": {
                      "line": 27,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          bestehende Bestände ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("werden aktualisiert");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 27,
                      "column": 8
                    },
                    "end": {
                      "line": 29,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          bestehender Bestand ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("wird aktualisiert");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 22,
                    "column": 6
                  },
                  "end": {
                    "line": 31,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element2 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element2, 1, 1);
                morphs[1] = dom.createMorphAt(element2, 3, 3);
                return morphs;
              },
              statements: [["content", "resultModCount", ["loc", [null, [24, 8], [24, 26]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "gt", [["get", "resultModCount", ["loc", [null, [25, 18], [25, 32]]], 0, 0, 0, 0], 1], [], ["loc", [null, [25, 14], [25, 35]]], 0, 0]], [], 0, 1, ["loc", [null, [25, 8], [29, 15]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          var child2 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 35,
                      "column": 8
                    },
                    "end": {
                      "line": 37,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          bestehende Bestände ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("bleiben unverändert");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 37,
                      "column": 8
                    },
                    "end": {
                      "line": 39,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          bestehender Bestand ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("b");
                  var el2 = dom.createTextNode("bleibt unverändert");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 6
                  },
                  "end": {
                    "line": 41,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element1, 1, 1);
                morphs[1] = dom.createMorphAt(element1, 3, 3);
                return morphs;
              },
              statements: [["content", "resultOldCount", ["loc", [null, [34, 8], [34, 26]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "gt", [["get", "resultOldCount", ["loc", [null, [35, 18], [35, 32]]], 0, 0, 0, 0], 1], [], ["loc", [null, [35, 14], [35, 35]]], 0, 0]], [], 0, 1, ["loc", [null, [35, 8], [39, 15]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          var child3 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 45,
                      "column": 8
                    },
                    "end": {
                      "line": 47,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          Bestände können nicht angelegt/aktualisiert werden, Fehler bei der Berechnung der Kennzahlen\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 47,
                      "column": 8
                    },
                    "end": {
                      "line": 49,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          Bestand kann nicht angelegt/aktualisiert werden, Fehler bei der Berechnung der Kennzahlen\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 42,
                    "column": 6
                  },
                  "end": {
                    "line": 51,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "error");
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n");
                dom.appendChild(el1, el2);
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("      ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element0 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(element0, 1, 1);
                morphs[1] = dom.createMorphAt(element0, 3, 3);
                return morphs;
              },
              statements: [["content", "resultErrorCount", ["loc", [null, [44, 8], [44, 28]]], 0, 0, 0, 0], ["block", "if", [["subexpr", "gt", [["get", "resultErrorCount", ["loc", [null, [45, 18], [45, 34]]], 0, 0, 0, 0], 1], [], ["loc", [null, [45, 14], [45, 37]]], 0, 0]], [], 0, 1, ["loc", [null, [45, 8], [49, 15]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 4
                },
                "end": {
                  "line": 53,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("ul");
              var el2 = dom.createTextNode("\n");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element4 = dom.childAt(fragment, [1]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element4, 1, 1);
              morphs[1] = dom.createMorphAt(element4, 2, 2);
              morphs[2] = dom.createMorphAt(element4, 3, 3);
              morphs[3] = dom.createMorphAt(element4, 4, 4);
              return morphs;
            },
            statements: [["block", "if", [["get", "resultNewCount", ["loc", [null, [12, 12], [12, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [12, 6], [21, 13]]]], ["block", "if", [["get", "resultModCount", ["loc", [null, [22, 12], [22, 26]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [22, 6], [31, 13]]]], ["block", "if", [["get", "resultOldCount", ["loc", [null, [32, 12], [32, 26]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [32, 6], [41, 13]]]], ["block", "if", [["get", "resultErrorCount", ["loc", [null, [42, 12], [42, 28]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [42, 6], [51, 13]]]]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 2
              },
              "end": {
                "line": 54,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [10, 4], [53, 30]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 57,
                  "column": 4
                },
                "end": {
                  "line": 57,
                  "column": 84
                }
              },
              "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Zurück");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 58,
                  "column": 4
                },
                "end": {
                  "line": 64,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "button radius tiny");
              dom.setAttribute(el1, "disabled", "disabled");
              var el2 = dom.createTextNode("Änderungen speichern");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "button radius tiny");
              dom.setAttribute(el1, "disabled", "disabled");
              var el2 = dom.createTextNode("Bestandeskennzahlen als CSV speichern");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("p");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("i");
              dom.setAttribute(el2, "class", "fa fa-cog fa-spin");
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode(" Bitte warten, Bestandeskennzahlen werden berechnet...\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 65,
                    "column": 6
                  },
                  "end": {
                    "line": 67,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        Änderungen speichern\n      ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 68,
                    "column": 6
                  },
                  "end": {
                    "line": 70,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        Bestandeskennzahlen als CSV speichern\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 64,
                  "column": 4
                },
                "end": {
                  "line": 71,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode(" \n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "link-to", ["assistent-wzpx-result.index.save", ["get", "model.betrieb_id", ["loc", [null, [65, 52], [65, 68]]], 0, 0, 0, 0], ["get", "model.teilbetrieb_id", ["loc", [null, [65, 69], [65, 89]]], 0, 0, 0, 0], ["get", "resultNewCount", ["loc", [null, [65, 90], [65, 104]]], 0, 0, 0, 0], ["get", "resultModCount", ["loc", [null, [65, 105], [65, 119]]], 0, 0, 0, 0], ["get", "resultOldCount", ["loc", [null, [65, 120], [65, 134]]], 0, 0, 0, 0]], ["class", "button radius tiny"], 0, null, ["loc", [null, [65, 6], [67, 18]]]], ["block", "link-to", ["assistent-wzpx-result.index.export", ["get", "model.id", ["loc", [null, [68, 54], [68, 62]]], 0, 0, 0, 0]], ["class", "button radius tiny"], 1, null, ["loc", [null, [68, 6], [70, 18]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 56,
                "column": 2
              },
              "end": {
                "line": 72,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "link-to", ["assistent-wzpx-summary"], ["class", "button radius secondary tiny"], 0, null, ["loc", [null, [57, 4], [57, 96]]]], ["block", "if", [["get", "loading", ["loc", [null, [58, 10], [58, 17]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [58, 4], [71, 11]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 75,
                  "column": 4
                },
                "end": {
                  "line": 77,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "assistent-wzpx-result/index/components/x-table", [], ["model", ["subexpr", "@mut", [["get", "result", ["loc", [null, [76, 61], [76, 67]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [76, 6], [76, 69]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 74,
                "column": 2
              },
              "end": {
                "line": 78,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [75, 4], [77, 30]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 8,
              "column": 0
            },
            "end": {
              "line": 79,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "mpf-magellan-section", [], ["titel", "Anstehende Änderungen", "class", "clearfix"], 0, null, ["loc", [null, [9, 2], [54, 27]]]], ["block", "knopf-bar", [], [], 1, null, ["loc", [null, [56, 2], [72, 16]]]], ["block", "mpf-magellan-section", [], ["titel", "Bestandeskennzahlen", "class", "clearfix"], 2, null, ["loc", [null, [74, 2], [78, 27]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 81,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/assistent-wzpx-result/index/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "if", [["get", "error", ["loc", [null, [1, 6], [1, 11]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [79, 7]]]], ["content", "outlet", ["loc", [null, [80, 0], [80, 10]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});