define('mpforst-ember/app/pods/user-logout/route', ['exports', 'mpforst-ember/app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.$.ajax('api/login/exit');
    },
    afterModel: function afterModel(model, transition) {
      transition.abort();
      if (_environment.default.environment === "testing") {
        window.location.href = "http://testing.unidata.at/forstlogin/web#/login/logout";
      } else {
        window.location.href = "http://forst.unidata.at/#/login/logout";
      }
    }
  });
});