define('mpforst-ember/app/pods/assistent-wzpx-setup/selected/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel() {
      this.replaceWith('assistent-wzpx-modus');
    }
  });
});