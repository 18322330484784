define('mpforst-ember/app/pods/application/controller', ['exports', 'mpforst-ember/app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
  }

  exports.default = Ember.Controller.extend({
    popups: Ember.inject.service('popups'),
    testing: Ember.computed(function () {
      return _environment.default.environment === "testing" || location.href.indexOf('unidata.dev') >= 0;
    }),
    development: Ember.computed(function () {
      return _environment.default.environment === "development";
    }),
    routeChange: Ember.observer("currentPath", function () {
      var session = null;
      if (window.sessionStorage) {
        session = window.sessionStorage.getItem('session');
        if (!session) {
          session = guid();
          window.sessionStorage.setItem('session', session);
        }
      }
      Ember.$.ajax({
        method: 'PUT',
        url: 'api/monitor/session',
        data: JSON.stringify({
          session: session,
          currentPath: this.get('currentPath')
        }),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      });
    }),
    hideMenu: Ember.computed('currentPath', function () {
      return this.get('currentPath').startsWith('info-hilfe');
    }),

    popupsArray: Ember.computed.alias('popups.popups'),

    actions: {
      addPopup: function addPopup() {
        var _get;

        (_get = this.get('popups')).addPopup.apply(_get, arguments);
      },
      closePopup: function closePopup() {
        var _get2;

        (_get2 = this.get('popups')).closePopup.apply(_get2, arguments);
      },
      toggleCompact: function toggleCompact() {
        document.body.classList.toggle('compact');
      }
    }
  });
});