define('mpforst-ember/app/pods/components/editor-latex-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    payload: null,
    formula: Ember.computed.alias('payload.formula'),
    showEditor: false,
    actions: {
      close: function close() {
        this.sendAction('saveCard', {
          formula: this.get('formula')
        });
        this.set('showEditor', false);
        this.get('editor').enableEditing();
      },
      open: function open() {
        this.get('editor').disableEditing(); //fixes stupid bugs..
        this.set('showEditor', true);
      }
    }
  });
});