define('mpforst-ember/app/pods/components/mpf-page/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    loading: true,
    cards: ['editor-html-card', 'editor-image-card', 'editor-br-card', 'editor-latex-card'],
    atoms: ['editor-link-atom'],
    init: function init() {
      var _this = this;

      this._super.apply(this, arguments);

      var model = this.get('model');
      if (typeof model === 'string' || model instanceof String) {
        // load
        Ember.$.ajax('api/page/' + model).then(function (x) {
          if (x.sections == null || x.sections[0] == null) {
            x.sections = null;
          }
          _this.set('_model', x);
          _this.set('loading', false);
        });
      } else {
        if (model.sections == null || model.sections[0] == null) {
          model.sections = null;
        }
        this.set('_model', model);
        this.set('loading', false);
      }
    },

    modelObs: Ember.observer('model', function () {
      throw new Error("component/mpf-page can't change model at runtime !");
    }),
    section: Ember.computed('_model.markdown', function () {
      var section = (this.get('_model.markdown') || '').split('{').map(function (x) {
        var res = {
          markdown: x
        };
        if (x.indexOf("section:") == 0) {
          var split = x.split('}', 2);
          var titles = split[0].replace('section:', '').trim().split(';').map(function (x) {
            return x.trim();
          });
          res = {
            title: titles[0],
            subtitle: titles[1],
            markdown: split[1]
          };
        }
        return res;
      });

      return section.filter(function (x) {
        return x.markdown.replace(/(?:\r\n|\r|\n)/g, '').trim().length !== 0;
      });
    })
  });
});