define('mpforst-ember/app/pods/assistent-planung-editor/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadCrumb: "Kennzahlen",
    betriebList: Ember.computed('model', function () {
      return Ember.$.getJSON('api/betrieb').then(function (x) {
        return x.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung
          };
        });
      });
    }),
    teilbetriebList: Ember.computed('entwurf.betrieb_id', function () {
      return Ember.$.getJSON('api/betrieb/' + this.get('model.betrieb_id')).then(function (x) {
        return x.teilbetriebe.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung
          };
        });
      });
    }),
    bestandList: Ember.computed('entwurf.teilbetrieb_id', function () {
      return Ember.$.getJSON('api/teilbetrieb/' + this.get('model.teilbetrieb_id')).then(function (x) {
        return x.bestaende.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung
          };
        });
      });
    }),
    bestandFlaecheObserver: Ember.observer('model.bestand_id', function () {
      var _this = this;

      Ember.$.getJSON('api/bestand/' + this.get('model.bestand_id')).then(function (x) {
        Ember.run(function () {
          _this.set('bestandFlaeche', x.grunddaten.flaeche);
        });
      });
    })
  });
});