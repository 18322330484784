define('mpforst-ember/app/pods/grunddaten-bestand/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadCrumb: Ember.computed('model.id', function () {
      return 'Bestand: ' + this.get('model.bezeichnung');
    })
  });
});