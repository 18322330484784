define("mpforst-ember/app/pods/components/editor-image-card/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 6
                },
                "end": {
                  "line": 12,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/editor-image-card/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "left: 50%;top: 50%;transform: translate(-50%, -50%);position: absolute;text-align: center;white-space: nowrap;text-shadow: 1px 1px white, 2px 2px white;");
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("Bild wird hochgeladen...");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n          ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n            ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("b");
              var el4 = dom.createComment("");
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode(" %");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3, 1]), 0, 0);
              return morphs;
            },
            statements: [["content", "uploadProgress", ["loc", [null, [9, 15], [9, 33]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 4
              },
              "end": {
                "line": 13,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/editor-image-card/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "upload", ["loc", [null, [5, 12], [5, 18]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [5, 6], [12, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 32,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/editor-image-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          dom.setAttribute(el2, "style", "margin: 0.25em");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "fa fa-pencil-square");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "style", "position: fixed; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.4); z-index: 10000;");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          dom.setAttribute(el3, "style", "position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); background: white; border: 1px solid black; padding: 1em");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("h2");
          var el5 = dom.createTextNode("Bild");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("form");
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("input");
          dom.setAttribute(el5, "type", "hidden");
          dom.setAttribute(el5, "name", "name");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n            ");
          dom.appendChild(el4, el5);
          var el5 = dom.createElement("input");
          dom.setAttribute(el5, "type", "file");
          dom.setAttribute(el5, "name", "file");
          dom.setAttribute(el5, "accept", "image/*");
          dom.appendChild(el4, el5);
          var el5 = dom.createTextNode("\n          ");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          var el5 = dom.createTextNode("Zurück");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [1]);
          var element2 = dom.childAt(element1, [1]);
          var element3 = dom.childAt(element1, [5]);
          var element4 = dom.childAt(fragment, [4]);
          var element5 = dom.childAt(element4, [1, 1]);
          var element6 = dom.childAt(element5, [3, 1]);
          var element7 = dom.childAt(element5, [5]);
          var morphs = new Array(7);
          morphs[0] = dom.createAttrMorph(element1, 'style');
          morphs[1] = dom.createAttrMorph(element2, 'src');
          morphs[2] = dom.createMorphAt(element1, 3, 3);
          morphs[3] = dom.createAttrMorph(element3, 'onclick');
          morphs[4] = dom.createAttrMorph(element4, 'style');
          morphs[5] = dom.createAttrMorph(element6, 'value');
          morphs[6] = dom.createAttrMorph(element7, 'onClick');
          return morphs;
        },
        statements: [["attribute", "style", ["concat", ["position: relative; outline: 1px solid rgba(0,0,255,0.3); ", ["subexpr", "if", [["get", "src", ["loc", [null, [2, 77], [2, 80]]], 0, 0, 0, 0], "cursor: default"], [], ["loc", [null, [2, 72], [2, 100]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "src", ["concat", [["subexpr", "if", [["get", "src", ["loc", [null, [3, 19], [3, 22]]], 0, 0, 0, 0], ["get", "src", ["loc", [null, [3, 23], [3, 26]]], 0, 0, 0, 0], "placeholder.png"], [], ["loc", [null, [3, 14], [3, 46]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "unless", [["get", "src", ["loc", [null, [4, 14], [4, 17]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [4, 4], [13, 15]]]], ["attribute", "onclick", ["subexpr", "action", ["open"], [], ["loc", [null, [null, null], [14, 55]]], 0, 0], 0, 0, 0, 0], ["attribute", "style", ["concat", ["display: ", ["subexpr", "if", [["get", "showEditor", ["loc", [null, [19, 30], [19, 40]]], 0, 0, 0, 0], "block", "none"], [], ["loc", [null, [19, 25], [19, 57]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "value", ["get", "name", ["loc", [null, [24, 53], [24, 57]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["close"], [], ["loc", [null, [null, null], [27, 44]]], 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 0
            },
            "end": {
              "line": 36,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/editor-image-card/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "position: relative");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("img");
          dom.setAttribute(el2, "style", "mix-blend-mode: multiply;");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1, 1]);
          var morphs = new Array(1);
          morphs[0] = dom.createAttrMorph(element0, 'src');
          return morphs;
        },
        statements: [["attribute", "src", ["concat", [["subexpr", "if", [["get", "src", ["loc", [null, [34, 53], [34, 56]]], 0, 0, 0, 0], ["get", "src", ["loc", [null, [34, 57], [34, 60]]], 0, 0, 0, 0], "placeholder.png"], [], ["loc", [null, [34, 48], [34, 80]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 36,
            "column": 7
          }
        },
        "moduleName": "mpforst-ember/app/pods/components/editor-image-card/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "editor", ["loc", [null, [1, 6], [1, 12]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [36, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});