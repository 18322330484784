define('mpforst-ember/app/pods/components/mpf-magellan-section/component', ['exports', 'mpforst-ember/app/pods/components/mpf-magellan/component'], function (exports, _component) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: "section",
        classNames: ["mpf-magellan-section"],
        sticky: false,
        willInsertElement: function willInsertElement() {
            this._parent = this.nearestOfType(_component.default);
            if (!this._parent) {
                throw 'mpf-magellan-section must be in a mpf-magellan';
            }
            this._parent.registerChild(this);
        },
        willDestroyElement: function willDestroyElement() {
            this._parent.unregisterChild(this);
        }
    });
});