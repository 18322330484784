define('mpforst-ember/app/helpers/optionlist', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.optionlist = optionlist;
  function optionlist(params /*, hash*/) {
    if (!params) {
      return;
    }

    return Ember.String.htmlSafe(params[0].map(function (x) {
      return '<option value="' + x.value + '">' + x.caption + '</option>';
    }));
  }

  exports.default = Ember.Helper.helper(optionlist);
});