define("mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 8
                  },
                  "end": {
                    "line": 8,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [7, 27], [7, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "text", "value", ["subexpr", "@mut", [["get", "model.bezeichnung", ["loc", [null, [7, 50], [7, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "required", true, "maxlength", 50], ["loc", [null, [7, 10], [7, 96]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 5,
                  "column": 6
                },
                "end": {
                  "line": 9,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Name des Betriebes", "area", "Betrieb", "name", "Name", "required", true], 0, null, ["loc", [null, [6, 8], [8, 29]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 12,
                    "column": 8
                  },
                  "end": {
                    "line": 14,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [13, 27], [13, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "text", "value", ["subexpr", "@mut", [["get", "model.adresse", ["loc", [null, [13, 50], [13, 63]]], 0, 0, 0, 0]], [], [], 0, 0], "maxlength", 255], ["loc", [null, [13, 10], [13, 79]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 6
                },
                "end": {
                  "line": 15,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Adresse Betriebssitz", "area", "Betrieb", "name", "Adresse"], 0, null, ["loc", [null, [12, 8], [14, 29]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 18,
                    "column": 8
                  },
                  "end": {
                    "line": 20,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [19, 27], [19, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "autocomplete", "value", ["subexpr", "@mut", [["get", "model.plz_ort_id", ["loc", [null, [19, 58], [19, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "items", ["subexpr", "@mut", [["get", "autocomplete_items", ["loc", [null, [19, 81], [19, 99]]], 0, 0, 0, 0]], [], [], 0, 0], "autocomplete_change", ["subexpr", "action", ["autocomplete_update"], [], ["loc", [null, [19, 120], [19, 150]]], 0, 0]], ["loc", [null, [19, 10], [19, 152]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 6
                },
                "end": {
                  "line": 21,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Postleitzahl/Ort", "area", "Betrieb", "name", "PostleitzahlOrt"], 0, null, ["loc", [null, [18, 8], [20, 29]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child3 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 24,
                    "column": 8
                  },
                  "end": {
                    "line": 26,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [25, 27], [25, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "textarea", "value", ["subexpr", "@mut", [["get", "model.leitbild", ["loc", [null, [25, 54], [25, 68]]], 0, 0, 0, 0]], [], [], 0, 0], "maxlength", 1024], ["loc", [null, [25, 10], [25, 85]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 6
                },
                "end": {
                  "line": 27,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Leitbild", "area", "Betrieb", "name", "Leitbild"], 0, null, ["loc", [null, [24, 8], [26, 29]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child4 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 8
                  },
                  "end": {
                    "line": 32,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [31, 27], [31, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "textarea", "value", ["subexpr", "@mut", [["get", "model.allgemein", ["loc", [null, [31, 54], [31, 69]]], 0, 0, 0, 0]], [], [], 0, 0], "maxlength", 1024], ["loc", [null, [31, 10], [31, 86]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 6
                },
                "end": {
                  "line": 33,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Allgemein", "area", "Betrieb", "name", "Allgemein"], 0, null, ["loc", [null, [30, 8], [32, 29]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 34,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" left side of form");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 7, 7, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 9, 9, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 11, 11, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [5, 6], [9, 32]]]], ["block", "foundation-row-column", [], ["large", 12], 1, null, ["loc", [null, [11, 6], [15, 32]]]], ["block", "foundation-row-column", [], ["large", 12], 2, null, ["loc", [null, [17, 6], [21, 32]]]], ["block", "foundation-row-column", [], ["large", 12], 3, null, ["loc", [null, [23, 6], [27, 32]]]], ["block", "foundation-row-column", [], ["large", 12], 4, null, ["loc", [null, [29, 6], [33, 32]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 38,
                    "column": 8
                  },
                  "end": {
                    "line": 40,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [39, 27], [39, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "textarea", "value", ["subexpr", "@mut", [["get", "model.wirtschaftsziel", ["loc", [null, [39, 54], [39, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "maxlength", 1024], ["loc", [null, [39, 10], [39, 92]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 6
                },
                "end": {
                  "line": 41,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Wirtschaftsziel", "area", "Betrieb", "name", "Wirtschaftsziel"], 0, null, ["loc", [null, [38, 8], [40, 29]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 8
                  },
                  "end": {
                    "line": 46,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [45, 27], [45, 31]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "textarea", "value", ["subexpr", "@mut", [["get", "model.betriebliche_besonderheiten", ["loc", [null, [45, 54], [45, 87]]], 0, 0, 0, 0]], [], [], 0, 0], "maxlength", 1024], ["loc", [null, [45, 10], [45, 104]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 6
                },
                "end": {
                  "line": 47,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Betriebliche Besonderheiten", "area", "Betrieb", "name", "BetrieblicheBesonderheiten"], 0, null, ["loc", [null, [44, 8], [46, 29]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 4
              },
              "end": {
                "line": 48,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment(" right side of form");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [37, 6], [41, 32]]]], ["block", "foundation-row-column", [], ["large", 12], 1, null, ["loc", [null, [43, 6], [47, 32]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 49,
              "column": 2
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 6, "class", "columns-no-padding"], 0, null, ["loc", [null, [3, 4], [34, 26]]]], ["block", "foundation-column", [], ["large", 6, "class", "columns-no-padding double-height"], 1, null, ["loc", [null, [35, 4], [48, 26]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 51,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(dom.childAt(fragment, [0]), 1, 1);
        return morphs;
      },
      statements: [["block", "foundation-row", [], ["class", "clearfix"], 0, null, ["loc", [null, [2, 2], [49, 21]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});