define('mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    countPromise: Ember.computed('model.betrieb_id', 'model.teilbetrieb_id', function () {
      var setup = {
        betrieb_id: this.get('model.betrieb_id') || 0,
        teilbetrieb_id: this.get('model.teilbetrieb_id') || 0
      };
      return Ember.$.ajax({
        method: 'POST',
        url: 'api/kennzahlen',
        dataType: 'json',
        data: JSON.stringify(setup),
        contentType: "application/json"
      }).then(function (x) {
        return x.count;
      });
    })
  });
});