define("mpforst-ember/app/initializers/bread-crumbs", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: "ember-breadcrumbs",
    initialize: function initialize() {
      var application = arguments[1] || arguments[0];
      application.inject("component:bread-crumbs", "router", "router:main");
      application.inject("component:bread-crumbs", "applicationController", "controller:application");
    }
  };
});