define('mpforst-ember/app/pods/info-hilfe/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  exports.default = Ember.Route.extend({
    model: function model(param) {
      var page = Ember.$.getJSON('api/page/Hilfe');
      var toc = Ember.$.getJSON('api/page').then(function (arr) {
        for (var i = 0; i < arr.length; ++i) {
          var a = arr[i];
          if (a.parent) {
            var p = arr.find(function (x) {
              return x.id == a.parent;
            });
            if (p) {
              p.model = p.model || [];
              p.model.push(a);
            }
          }
        }
        // only take "Hilfe
        return arr.find(function (x) {
          return x.id == 'Hilfe';
        }).model;
      });

      return Promise.all([page, toc]).then(function (_ref) {
        var _ref2 = _slicedToArray(_ref, 2),
            _page = _ref2[0],
            _toc = _ref2[1];

        return {
          page: _page,
          toc: _toc
        };
      });
    }
  });
});