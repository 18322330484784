define('mpforst-ember/app/components/mobiledoc-toolbar', ['exports', 'ember-mobiledoc-editor/components/mobiledoc-toolbar/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _component.default;
    }
  });
});