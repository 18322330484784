define('mpforst-ember/app/pods/components/mpf-tooltip-edit/component', ['exports', 'ember-mobiledoc-editor/utils/create-component-card', 'ember-mobiledoc-editor/utils/create-component-atom'], function (exports, _createComponentCard, _createComponentAtom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component,
      observer = Ember.observer;
  exports.default = Component.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('model_edited', JSON.parse(JSON.stringify(this.get('model'))));
    },
    didInsertElement: function didInsertElement() {
      Ember.run.scheduleOnce('afterRender', this, function () {
        this.element.scrollIntoView(true);
        window.scrollBy(0, -200);
      });
    },

    obsAuswahl: Ember.on('init', Ember.observer('model_edited.auswahlen', function () {
      var _this = this;

      this.set('_auswahl', null);
      if (this.get('model_edited.auswahlen')) {
        this._a = Ember.$.getJSON(this.get('model_edited.auswahlen'));
        var s = this._a;
        this._a.then(function (x) {
          if (s !== _this._a) {
            return;
          }
          //set new value
          _this.set('_auswahl', x);
        });
      }
    })),
    auswahlen: Ember.computed('model_edited.auswahlen_text', '_auswahl', function () {
      var _auswahl = this.get('_auswahl');
      if (!_auswahl) {
        return null;
      }
      var auswahlen_text = this.get('model_edited.auswahlen_text');
      return _auswahl.map(function (x) {
        var a = auswahlen_text.find(function (y) {
          return y.value == x.value;
        });
        if (!a) {
          a = { value: x.value, text: null };
          auswahlen_text.push(a);
        }
        return {
          value: x.value,
          caption: x.caption,
          text: a
        };
      });
    }),
    actions: {
      save: function save() {
        this.sendAction.apply(this, ['save'].concat(Array.prototype.slice.call(arguments)));
      },
      insertCard: function insertCard(editor, card) {
        // PLZ DON'T ASK
        editor.editor.insertCard(card);
      },
      insertAtom: function insertAtom(editor, card) {
        // PLZ DON'T ASK
        editor.editor.insertAtom(card);
      }
    }
  });
});