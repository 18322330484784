define('mpforst-ember/app/pods/assistent-flaechen-upload-setup/auswahl/controller', ['exports', 'mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    actions: {
      closeError: function closeError() {
        this.set('error', null);
      },
      actionDidInsertFormElement: function actionDidInsertFormElement(component) {
        var _this = this;

        var teilbetrieb_id = window.frameElement.actions.id;
        component.$().ajaxForm();
        component.$('input').change(function () {
          _this.set('upload', true);
          component.$().ajaxSubmit({
            url: 'api/flaechenUpload/' + teilbetrieb_id,
            type: 'POST',
            dataType: 'json',
            resetForm: true,

            beforeSend: function beforeSend() {
              Ember.run(function () {
                _this.set('uploadProgress', 0);
              });
            },
            uploadProgress: function uploadProgress(event, postion, total, percent) {
              Ember.run(function () {
                _this.set('uploadProgress', percent);
              });
            },
            success: function success() {
              Ember.run(function () {
                _this.set('uploadProgress', 100);
              });
            },
            complete: function complete(xhr) {
              Ember.run(function () {
                component.$().trigger('reset');
                _this.set('upload', false);
                var json = {
                  okay: false,
                  error: [{ field: 'unbekannt', message: 'Unbekannt' }]
                };
                try {
                  json = JSON.parse(xhr.responseText);
                } catch (e) {
                  // ignore
                }
                if (!json.okay) {
                  _this.set('error', json.error);
                } else {
                  window.frameElement.actions.saved(teilbetrieb_id);
                  //this.transitionToRoute('assistent-flaechen-upload.success', this.get('model.betrieb_id'), this.get('model.teilbetrieb_id'));
                }
              });
            },
            error: function error() {
              Ember.run(function () {
                _this.intermediateTransitionTo('error', {/* required */});
              });
            }
          });
        });
      }
    }
  });
});