define("mpforst-ember/app/pods/grunddaten-betrieb/components/x-details/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    filter: null,
    autocomplete_items: null,
    autocomplete_init: Ember.on("init", Ember.observer("filter", function () {
      if (this.get('filter') != null) {
        this.set('autocomplete_items', Ember.$.getJSON('api/stammdaten/plz_ort', {
          filter: this.get('filter')
        }));
      } else {
        if (this.get('model.plz_ort_id')) {
          this.set('autocomplete_items', Ember.$.getJSON('api/stammdaten/plz_ort/' + this.get('model.plz_ort_id')));
        } else {
          this.set('autocomplete_items', []);
        }
      }
    })),
    actions: {
      autocomplete_update: function autocomplete_update(value) {
        // reload items for auto complete :)
        this.set('filter', value);
      }
    }
  });
});