define('mpforst-ember/app/pods/assistent-wzpx-editor/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel() {
      var _this = this;

      //seehoehe laden
      var params = this.paramsFor('assistent-wzpx-setup.selected');
      return Ember.$.ajax('api/teilbetrieb/' + params.teilbetrieb).then(function (x) {
        Ember.run(function () {
          _this.controllerFor(_this.get('routeName')).set('seehoehe', x.mit_see_hoehe);
        });
      });
    }
  });
});