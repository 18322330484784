define('mpforst-ember/app/services/x-klimafitt', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);

      this.send = this.send.bind(this);
      this.refresh = this.send.refresh(this);
    },
    send: function send(data) {
      document.querySelectorAll('iframe').forEach(function (iframe) {
        iframe && iframe.contentWindow && iframe.contentWindow.postMessage(JSON.stringify(data));
      });
    },
    refresh: function refresh() {
      this.send({
        type: 'refresh'
      });
    }
  });
});