define('mpforst-ember/app/pods/assistent-wzpx-entwurf/auswahl/route', ['exports', 'moment'], function (exports, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var _arguments = arguments;

      var deferred = Ember.$.Deferred();

      Ember.$.getJSON('api/entwurfWzpx').then(function (data) {
        if (!data) {
          // neuen entwurf erstellen
          Ember.$.ajax({
            method: 'POST',
            url: 'api/entwurfWzpx'
          }).then(function (data) {
            deferred.resolve(Ember.$.extend(data, {
              new: true,
              caption: (0, _moment.default)(data.created).format('DD.MM.YYYY HH:mm')
            }));
          }, function () {
            deferred.reject.apply(deferred, _arguments);
          });
        } else {
          deferred.resolve(Ember.$.extend(data, {
            caption: (0, _moment.default)(data.created).format('DD.MM.YYYY HH:mm')
          }));
        }
      }, function () {
        deferred.reject.apply(deferred, _arguments);
      });

      return deferred.promise();
    },

    afterModel: function afterModel(model) {
      if (model.new) {
        // automatische weiterleitung
        this.transitionTo('assistent-wzpx-entwurf.selected', model.id);
      }
    }
  });
});