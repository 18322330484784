define('mpforst-ember/app/pods/grunddaten-betrieb/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        breadCrumb: Ember.computed('model.bezeichnung', function () {
            return 'Betrieb: ' + this.get('model.bezeichnung');
        })
    });
});