define('mpforst-ember/app/pods/assistent-taxation-editor/components/x-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    model: Ember.computed(function () {
      return {};
    }),
    classNameBindings: 'hasErrors:error',
    hasErrors: Ember.computed('model.error.length', function () {
      return (this.get('model.error') || []).length > 0;
    }),

    boni: Ember.computed('model.baumart_id', 'bonis', function () {
      var id = this.get('model.baumart_id');
      var bonis = this.get('bonis');
      return (bonis.find(function (x) {
        return x.id === id;
      }) || {}).bonitaet;
    }),

    relAbsItems: [{
      value: 0,
      caption: 'relative'
    }, {
      value: 1,
      caption: 'absolute'
    }],

    plusMinusItems: [{
      value: 0,
      caption: '++'
    }, {
      value: 1,
      caption: '+'
    }, {
      value: 2,
      caption: '='
    }, {
      value: 3,
      caption: '-'
    }, {
      value: 4,
      caption: '--'
    }],

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._change = function () {
        if (_this.get('empty')) {
          _this.sendAction('actionNew', _this.get('model'));
        }
        _this.sendAction('actionChange', _this.get('model'));
      };
      this.$().on("change", "input, select", this._change);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._change) {
        this.$().off("change", "input, select", this._change);
      }
    },


    actions: {
      delete: function _delete() {
        this.sendAction('actionDelete', this.get('model'));
      }
    }
  });
});