define('mpforst-ember/app/pods/admin-activity/components/x-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    model: Ember.A(),
    didInsertElement: function didInsertElement() {
      var _this = this;

      // LIVE WERTE
      if (window.EventSource) {
        this.source = new window.EventSource('api/monitor');

        this.source.addEventListener('session', function (e) {
          var data = JSON.parse(e.data);
          Ember.run(function () {
            var model = _this.get('model');
            var row = model.find(function (x) {
              return x.clientSession === data.clientSession && x.serverSession === data.serverSession;
            });
            if (row) {
              row.setProperties(data);
            } else {
              model.addObject(Ember.Object.create(data));
            }
          });
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      if (this.source) {
        this.source.close();
      }
    }
  });
});