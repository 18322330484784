define('mpforst-ember/app/pods/components/mpf-magellan/component', ['exports', 'mpforst-ember/app/modules/delayed-queue'], function (exports, _delayedQueue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function getRealHeight($e) {
    if (!$e) {
      return 0;
    }
    if (!$e.length) {
      return 0;
    }
    var height = 0;
    for (var i = 0; i < $e.length; ++i) {
      var lh = null;
      if ($e[i].getBoundingClientRect) {
        var rect = $e[i].getBoundingClientRect();
        if (rect.height) {
          lh = $e[i].getBoundingClientRect().height;
        }
      }
      if (lh == null) {
        lh = Ember.$($e[i]).height();
      }
      height = Math.max(height, lh);
    }
    return height;
  }

  var ComputedTitelByComponent = Ember.Object.extend({
    component: null,
    titel: Ember.computed('component', function () {
      var c = this.get('component');
      if (c) {
        return c.get('titel');
      }
      return null;
    })
  });

  exports.default = Ember.Component.extend({
    children: Ember.A(),
    scroll_id: 0,
    restoreScrollWith: 0,
    loading: true,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      return;
      var restoreScrollWith = this.get('restoreScrollWith') || 0;
      if (restoreScrollWith !== null) {
        restoreScrollWith = parseInt(restoreScrollWith);
      }
      if (this.get('loading') && !restoreScrollWith) {
        this.set('loading', false);
      }
    },

    registerChild: function registerChild(child) {
      return;
      this.get('children').pushObject(child);
      Ember.run.once(this, this.updateScrollOnce);
    },
    unregisterChild: function unregisterChild(child) {
      return;
      this.get('children').removeObject(child);
      Ember.run.once(this, this.updateScrollOnce);
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      return;
      var restoreScrollWith = this.get('restoreScrollWith') || 0;

      if (restoreScrollWith !== null) {
        // scroll documentElement and body, because firefox/chrome
        // doesn't use the same element for scrolling...
        restoreScrollWith = parseInt(restoreScrollWith);
        if (restoreScrollWith !== 0) {
          var t1 = window.setInterval(function () {
            Ember.$(document.documentElement).prop('scrollTop', restoreScrollWith);
            Ember.$('body').prop('scrollTop', restoreScrollWith);
          }, 100);
          (0, _delayedQueue.queueWaitUntil)().then(function () {
            window.clearInterval(t1);
            Ember.$(document.documentElement).prop('scrollTop', restoreScrollWith);
            Ember.$('body').prop('scrollTop', restoreScrollWith);
            Ember.run(_this, function () {
              _this.set('loading', false);
            });
          });
        } else {
          Ember.$(document.documentElement).prop('scrollTop', restoreScrollWith);
          Ember.$('body').prop('scrollTop', restoreScrollWith);
        }
      }

      Ember.$(window).on("scroll", { self: this }, this.updateScroll);
      Ember.$(window).on("resize", { self: this }, this.updateScroll);

      var t2 = window.setInterval(function () {
        _this.updateScroll({
          data: {
            self: _this
          }
        });
      }, 100);

      (0, _delayedQueue.queueWaitUntil)().then(function () {
        window.clearInterval(t2);
        _this.updateScroll({
          data: {
            self: _this
          }
        });
      });
    },
    willDestroyElement: function willDestroyElement() {
      return;
      Ember.$(window).off("scroll", this.updateScroll);
      Ember.$(window).off("resize", this.updateScroll);
    },
    updateScroll: function updateScroll(event) {
      return;
      Ember.run.debounce(event.data.self, event.data.self.updateScrollOnce, 1000 / 60);
    },
    updateScrollOnce: function updateScrollOnce() {
      return;
      if (!this.isDestroying && !this.isDestroyed) {
        this.set('scroll_id', this.get('scroll_id') + 1);
      }
    },

    magellanChildren: Ember.computed('scroll_id', function () {
      return;
      var scroll = {
        top: Math.max(Ember.$('body').scrollTop(), Ember.$(document.documentElement).scrollTop()),
        height: Math.max(Ember.$('body').prop('scrollHeight'), Ember.$(document.documentElement).prop('scrollHeight')),
        view_height: Math.max(getRealHeight(Ember.$('body')), getRealHeight(Ember.$(document.documentElement)))
      };

      // ignore when no scrollbar visible
      if (scroll.view_height >= scroll.height) {
        return [];
      }

      // calculate scroll position
      var scroll_position = scroll.top + scroll.view_height * (scroll.top / (scroll.height - scroll.view_height));

      // debug helper
      /*let $helper = Ember.$("#scrollHelper");
      if ($helper.length <= 0) {
        $helper = Ember.$("<div id='scrollHelper' style='position: absolute; height: 1px; left: 0; right: 0; background-color: red; z-index: 1000;'></div>");
        $helper.appendTo(Ember.$('body'));
      }
      $helper.css('top', scroll_position-1);*/

      var tmp = this.get('children').filter(function (x) {
        return x.titel;
      }).map(function (x) {
        var pos = {
          top: x.$().position().top,
          height: getRealHeight(x.$())
        };

        var in_view = scroll_position <= pos.top + pos.height && scroll_position >= pos.top;

        return ComputedTitelByComponent.create({
          component: x,
          active: in_view,
          pos: pos
        });
      });

      var found = false;
      for (var i = tmp.length - 1; i >= 0; --i) {
        found = found || tmp[i].active;
      }

      if (!found) {
        if (tmp.length > 0) {
          tmp[scroll_position < (scroll.height - scroll.view_height) / 2 ? 0 : tmp.length - 1].active = true;
        }
      }

      // update children
      for (var _i = 0; _i < tmp.length; ++_i) {
        tmp[_i].component.set('active', tmp[_i].active);
      }

      this.set('restoreScrollWith', scroll.top);

      return tmp;
    }),

    actions: {
      scrollToComponent: function scrollToComponent(item) {
        return;
        var scroll = {
          top: Math.max(Ember.$('body').scrollTop(), Ember.$(document.documentElement).scrollTop()),
          height: Math.max(Ember.$('body').prop('scrollHeight'), Ember.$(document.documentElement).prop('scrollHeight')),
          view_height: Math.max(getRealHeight(Ember.$('body')), getRealHeight(Ember.$(document.documentElement)))
        };

        var pos = {
          top: item.component.$().position().top,
          height: getRealHeight(item.component.$())
        };

        var new_scroll_top_top = Math.ceil(pos.top / (1 + scroll.view_height / (scroll.height - scroll.view_height)));
        var new_scroll_top_bottom = Math.floor((pos.top + pos.height) / (1 + scroll.view_height / (scroll.height - scroll.view_height)));

        // as much on top as possible
        var $mag = Ember.$('#magellan-header');
        if ($mag.length <= 0) {
          return;
        }
        var mag_height = $mag.position().top + $mag.height();
        var new_scroll_top = pos.top - mag_height;
        if (new_scroll_top_top > mag_height) {
          if (new_scroll_top < new_scroll_top_top) {
            new_scroll_top = new_scroll_top_top;
          }
        }
        if (new_scroll_top > new_scroll_top_bottom) {
          new_scroll_top = new_scroll_top_bottom;
        }

        // scroll documentElement and body, because firefox/chrome
        // doesn't use the same element for scrolling...
        Ember.$(document.documentElement).animate({
          scrollTop: new_scroll_top
        });
        Ember.$('body').animate({
          scrollTop: new_scroll_top
        });
      }
    }
  });
});