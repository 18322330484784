define('mpforst-ember/app/pods/components/x-klimafitt/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    init: function init() {
      this._super.apply(this, arguments);
      this.___router = Ember.getOwner(this).lookup('router:main');
      this.___application = Ember.getOwner(this).lookup('route:application');
      this.checkRoute = this.checkRoute.bind(this);
      this.onMessage = this.onMessage.bind(this);
    },

    iframe: Ember.computed('_element.id', 'url', function () {
      var iframe = document.createElement('iframe');
      //iframe.setAttribute('style', 'display: none');
      iframe.setAttribute('style', 'width: 1px; height: 1px; border:none; position: absolute;'); // firefox needs this
      iframe.setAttribute('src', "/klimafitt/" + this.get('_element.id') + "_div/" + this.get('url'));
      return iframe;
    }),
    onMessage: function onMessage(data) {
      var event = data;
      var source = event.source;
      // does nothing else for now
      try {
        data = JSON.parse(data.data);
      } catch (ex) {
        return;
      }
      if (data.type === 'request_route') {
        this.checkRoute();
      } else if (data.type === 'route') {
        this.get('router').transitionTo('/grunddaten/betrieb/' + data.params.betrieb + '/teilbetrieb/' + data.params.teilbetrieb + '/bestand/' + data.params.bestand);
      } else if (data.type === 'requestAnimationFrame') {
        requestAnimationFrame(function (p) {
          source.postMessage(JSON.stringify({
            type: 'requestAnimationFrame',
            value: p
          }));
        });
      }
    },
    postMessage: function postMessage(data) {
      var iframe = Ember.get(this, 'iframe');
      iframe && iframe.contentWindow && iframe.contentWindow.postMessage(JSON.stringify(data));
    },
    checkRoute: function checkRoute() {
      var params = this.___router.router.currentHandlerInfos.map(function (x) {
        return x.params;
      }).reduce(function (p, c) {
        return Object.assign(p, c);
      }, {});
      this.postMessage({
        type: 'route',
        params: params
      });
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      Ember.run.next(function () {
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }
        _this.set('_element', _this.element);
      });
      this.catchLink = this.catchLink.bind(this);
      this.element.addEventListener('click', this.catchLink);
      this.___application.router.on('didTransition', this.checkRoute);
      window.addEventListener('message', this.onMessage, false);
    },
    willDestroyElement: function willDestroyElement() {
      window.removeEventListener('message', this.onMessage, false);
      this.___application.router.off('didTransition', this.checkRoute);
      clearInterval(this._timer);
    },

    body: document.body,
    catchLink: function catchLink(e) {
      var _this2 = this;

      // search for link
      var node = e.target;
      while (node && node.tagName != 'A') {
        node = node.parentElement;
      }
      if (!node) {
        return;
      }
      e.preventDefault();
      var href = node.getAttribute('href');
      var action = href.split('#;')[1];
      if (action) {
        this.sendAction(action);
        this.postMessage({
          type: 'after_action',
          action: action
        });
      } else {
        this.get('router').transitionTo(href).then(function () {
          _this2.postMessage({
            type: 'after_href',
            href: href
          });
        });
      }
    }
  });
});