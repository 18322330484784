define('mpforst-ember/app/pods/grunddaten-teilbetrieb-neu/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'grunddaten-teilbetrieb.anzeigen',
    model: function model() {
      return Ember.$.getJSON('api/teilbetrieb/0').then(function (x) {
        return Ember.Object.create(x);
      });
    },
    afterModel: function afterModel() {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('s', 0);
    }
  });
});