define('mpforst-ember/app/pods/assistent-wzpx-editor/components/x-wzpx/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    errorOnly: false,

    modelWithEmpty: Ember.computed('edit', 'model', 'model.length', 'emptyWzpx', function () {
      var array = (this.get('model') || Ember.A()).slice();
      if (this.get('edit')) {
        array.addObject(this.get('emptyWzpx'));
      }
      /*if (this.get('errorOnly')) {
        array = array.filter(x => (x.error || []).length > 0);
        if (array.length > 10) {
          array = array.slice(0, 10);
        }
      }*/
      return array;
    }),

    emptyWzpx: null,
    initEmptyWzpx: Ember.on('init', function () {
      this.set('emptyWzpx', Ember.Object.create({
        error: Ember.A()
      }));
    }),

    actions: {
      actionNewWzpx: function actionNewWzpx(wzpx) {
        var model = this.get('model') || Ember.A();
        //default werte setzen
        if (!wzpx.get('wzp_nr')) {
          if (model.length) {
            wzpx.set('wzp_nr', model[model.length - 1].get('wzp_nr'));
          } else {
            wzpx.set('wzp_nr', 1);
          }
        }
        model.addObject(wzpx);
        this.set('model', model);
        this.set('emptyWzpx', Ember.Object.create({
          error: Ember.A()
        }));
      },
      actionDeleteWzpx: function actionDeleteWzpx(wzpx) {
        var model = this.get('model');
        model.removeObject(wzpx);
        this.sendAction('actionChange', this.get('model'));
      },
      actionChange: function actionChange() {
        console.log("x-wzpx change");
        this.sendAction('actionChange', this.get('model'));
      }
    }
  });
});