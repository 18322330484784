define('mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-baumartgruppe/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    umtrieb: Ember.computed("model.baumartengruppen", function () {
      return (this.get('model.baumartengruppen') || []).filter(function (x) {
        return x.id === 1;
      })[0];
    }),
    min_umtrieb: Ember.computed("model.baumartengruppen", function () {
      return (this.get('model.baumartengruppen') || []).filter(function (x) {
        return x.id === 2;
      })[0];
    }),
    korrektur: Ember.computed("model.baumartengruppen", function () {
      return (this.get('model.baumartengruppen') || []).filter(function (x) {
        return x.id === 3;
      })[0];
    }),
    verringerung_menge: Ember.computed("model.baumartengruppen", function () {
      return (this.get('model.baumartengruppen') || []).filter(function (x) {
        return x.id === 4;
      })[0];
    }),
    verringerung_menge_schutzwald: Ember.computed("model.baumartengruppen", function () {
      return (this.get('model.baumartengruppen') || []).filter(function (x) {
        return x.id === 5;
      })[0];
    })
  });
});