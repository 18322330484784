define('mpforst-ember/app/pods/assistent-wzpx-result/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel() {
      console.log('increase version');
      var controller = this.controllerFor('assistent-wzpx-result.index');
      controller.set('version', (controller.get('version') || 0) + 1);
    },
    actions: {
      willTransition: function willTransition() {
        console.log('decrease version');
        var controller = this.controllerFor('assistent-wzpx-result.index');
        if (controller.get('loading')) {
          controller.set('version', (controller.get('version') || 0) + 1);
        }
      }
    }
  });
});