define('mpforst-ember/app/pods/components/mpf-input/x-number/component', ['exports', 'mpforst-ember/app/pods/components/numeric-input/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    edit: true,
    value: null,
    valueFormated: Ember.computed('value', function () {
      return (0, _component.formatFloat)(this.get('value'), this.get('fraction'));
    })
  });
});