define('mpforst-ember/app/pods/components/quick-input/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: 'input',
        type: 'text',
        classNames: ['input-field'],
        placeholder: '',
        maxlength: null,
        required: false,
        obsValue: Ember.observer('value', function () {
            if (this.skip) {
                return;
            }

            if (this.$input) {
                this.$input.val(this.get('value'));
            }
        }),
        didInsertElement: function didInsertElement() {
            var _this = this;

            //this.$input = Ember.$('<input type="' + this.get('type') + '" />');
            this.$input = this.$();
            this.$input.type = this.get('type');
            this.$input.prop('required', this.get('required'));
            this.$input.attr('placeholder', this.get('placeholder'));
            if (this.get('maxlength')) {
                this.$input.attr('maxlength', this.get('maxlength'));
            }
            var val = this.get('value');
            if (val && val !== '') {
                this.$input.val(this.get('value'));
            }

            if (isInputEventSupported()) {
                this.$input.on('input', function (e) {
                    Ember.run(function () {
                        _this.skip = true;
                        _this.set('value', e.target.value);
                        _this.skip = false;

                        if (_this.change) {
                            _this.change(e);
                        }
                    });
                });
            } else {
                this.$input.keydown(function (e) {
                    Ember.run(function () {
                        if (_this.change) {
                            _this.change(e);
                        }
                    });
                });
                this.$input.keyup(function (e) {
                    Ember.run(function () {
                        _this.skip = true;
                        _this.set('value', e.target.value);
                        _this.skip = false;
                    });
                });
            }
            this.$input.change(function (e) {
                Ember.run(function () {
                    _this.skip = true;
                    _this.set('value', e.target.value);
                    _this.skip = false;

                    if (_this.change) {
                        _this.change(e);
                    }
                });
            });
        }
    });


    function isInputEventSupported() {
        var el = document.createElement('input');
        var isSupported = 'oninput' in el;
        if (!isSupported) {
            el.setAttribute('oninput', 'return;');
            isSupported = typeof el['input'] === 'function';
        }
        el = null;
        return isSupported;
    }
});