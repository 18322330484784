define('mpforst-ember/app/router', ['exports', 'mpforst-ember/app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = Ember.Router.extend({
    location: _environment.default.locationType
  });

  Router.map(function () {
    // route forwarding is done in app.js
    // with a custom Resolver
    var assisten_sub_route = function assisten_sub_route() {
      this.route('assistent-wzpx', { resetNamespace: true, path: 'wzpx' }, function () {
        this.route('success', { path: 'success/:betrieb/:teilbetrieb/:new/:mod/:old' });
        this.route('einleitung', { path: '' });
        this.route('assistent-wzpx-entwurf', { resetNamespace: true, path: 'entwurf' }, function () {
          this.route('auswahl', { path: '' });
          this.route('selected', { path: ':entwurf' }, function () {
            this.route('assistent-wzpx-setup', { resetNamespace: true, path: 'setup' }, function () {
              this.route('auswahl', { path: '' });
              this.route('selected', { path: ':teilbetrieb' }, function () {
                this.route('assistent-wzpx-modus', { resetNamespace: true, path: 'modus' }, function () {
                  this.route('auswahl', { path: '' });
                  this.route('assistent-wzpx-import', { resetNamespace: true, path: 'import' });
                  this.route('assistent-wzpx-editor', { resetNamespace: true, path: 'editor' }, function () {
                    this.route('assistent-wzpx-summary', { resetNamespace: true, path: 'zusammenfassung' }, function () {
                      this.route('index', { path: '' }, function () {
                        this.route('export', { path: 'export/:entwurf' });
                      });
                      this.route('assistent-wzpx-result', { resetNamespace: true, path: 'ergebnis' }, function () {
                        this.route('index', { path: '' }, function () {
                          this.route('save', { path: 'save/:betrieb/:teilbetrieb/:new/:mod/:old' });
                          this.route('export', { path: 'export/:entwurf' });
                        });
                      });
                    });
                  });
                });
              });
            });
          });
        });
      });

      this.route('assistent-taxation', { resetNamespace: true, path: 'taxation' }, function () {
        this.route('success', { path: 'success/:betrieb/:teilbetrieb/:bestand' });
        this.route('einleitung', { path: '' });
        this.route('assistent-taxation-entwurf', { resetNamespace: true, path: 'entwurf' }, function () {
          this.route('auswahl', { path: '' });
          this.route('selected', { path: ':entwurf' }, function () {
            this.route('assistent-taxation-setup', { resetNamespace: true, path: 'setup' }, function () {
              this.route('auswahl', { path: '' });
              this.route('assistent-taxation-editor', { resetNamespace: true, path: 'editor' }, function () {
                this.route('editor', { path: '' });
                this.route('assistent-taxation-result', { resetNamespace: true, path: 'result' }, function () {
                  this.route('save', { path: 'save/:betrieb/:teilbetrieb/:bestand' });
                });
              });
            });
          });
        });
      });

      this.route('assistent-export', { resetNamespace: true, path: 'export' }, function () {
        this.route('einleitung', { path: '' });
        this.route('assistent-export-setup', { resetNamespace: true, path: 'setup' }, function () {
          this.route('download', { path: 'download' });
        });
      });

      this.route('assistent-planung', { resetNamespace: true, path: 'planung' }, function () {
        this.route('success', { path: 'success/:betrieb/:teilbetrieb/:bestand' });
        this.route('einleitung', { path: '' });
        this.route('assistent-planung-setup', { resetNamespace: true, path: 'setup' }, function () {
          this.route('auswahl', { path: '' });
          this.route('assistent-planung-editor', { resetNamespace: true, path: ':bestand/editor' }, function () {
            this.route('save', { path: 'save/:betrieb/:teilbetrieb/:bestand' });
          });
        });
      });

      this.route('assistent-nachweisung', { resetNamespace: true, path: 'nachweisung' }, function () {
        this.route('success', { path: 'success/:betrieb/:teilbetrieb/:bestand' }); //< unused
        this.route('einleitung', { path: '' }); //< unused
        this.route('assistent-nachweisung-setup', { resetNamespace: true, path: 'setup' }, function () {
          //< unused
          this.route('auswahl', { path: '' }); //< unused
          this.route('assistent-nachweisung-editor', { resetNamespace: true, path: ':bestand/editor' }, function () {
            this.route('save', { path: 'save/:betrieb/:teilbetrieb/:bestand' });
          });
        });
      });

      this.route('assistent-kennzahlen', { resetNamespace: true, path: 'kennzahlen' }, function () {
        this.route('success', { path: 'success/:count' });
        this.route('einleitung', { path: '' });
        this.route('assistent-kennzahlen-setup', { resetNamespace: true, path: 'setup' }, function () {
          this.route('auswahl', { path: '' }, function () {
            this.route('save', { path: 'save/:betrieb/:teilbetrieb/:bestand' });
          });
        });
      });

      this.route('assistent-teilbetrieb-neu', { resetNamespace: true, path: 'teilbetrieb/neu' });
      this.route('assistent-bestand-neu', { resetNamespace: true, path: 'bestand/neu' });

      this.route('assistent-flaechen-upload', { resetNamespace: true, path: 'flaeche' }, function () {
        this.route('success', { path: 'success/:betrieb/:teilbetrieb' });
        this.route('einleitung', { path: '' });
        this.route('assistent-flaechen-upload-setup', { resetNamespace: true, path: 'setup' }, function () {
          this.route('auswahl', { path: '' });
        });
      });
    };

    var report_sub_route = function report_sub_route() {
      this.route('report-betrieb', { resetNamespace: true, path: 'betrieb' }, function () {
        this.route('generate', { path: ':betrieb_id' });
      });

      this.route("report-teilbetrieb", { resetNamespace: true, path: 'teilbetrieb' }, function () {
        this.route('generate', { path: ':teilbetrieb_id' });
      });

      this.route("report-bestand", { resetNamespace: true, path: 'bestand' }, function () {
        this.route('generate', { path: ':ebene/:ebene_id' });
      });

      this.route("report-hiebsatz", { resetNamespace: true, path: 'hiebsatz' }, function () {
        this.route('generate', { path: ':teilbetrieb_id/:revier_id/:type/:darstellung/:nuzungsklasse' });
        this.route('nan');
      });
    };

    this.route('grunddaten', function () {
      this.route('uebersicht', { path: '' });
      this.route('grunddaten-betrieb-neu', { resetNamespace: true, path: 'betrieb/neu' }, function () {
        this.route('save');
      });
      this.route('grunddaten-betrieb', { resetNamespace: true, path: 'betrieb/:betrieb' }, function () {
        this.route('anzeigen', { path: '' });
        this.route('bearbeiten', function () {
          this.route('save');
          this.route('delete-confirmed');
          this.route('delete');
        });

        this.route('grunddaten-teilbetrieb-neu', { resetNamespace: true, path: 'teilbetrieb/neu' }, function () {
          this.route('save');
        });
        this.route('grunddaten-teilbetrieb', { resetNamespace: true, path: 'teilbetrieb/:teilbetrieb' }, function () {
          this.route('anzeigen', { path: '' });
          this.route('bearbeiten', function () {
            this.route('save');
            this.route('delete-confirmed');
            this.route('delete');
          });

          this.route('grunddaten-bestand-neu', { resetNamespace: true, path: 'bestand/neu' }, function () {
            this.route('save');
          });
          this.route('grunddaten-bestand', { resetNamespace: true, path: 'bestand/:bestand' }, function () {
            this.route('anzeigen', { path: '' });
            this.route('bearbeiten', function () {
              this.route('save');
              this.route('delete-confirmed');
              this.route('delete');
            });
          });
        });
      });
    });

    this.route('assistent', assisten_sub_route);

    this.route('report', report_sub_route);

    this.route('info-einleitung', { path: 'einleitung' });
    this.route('info-hilfe', { path: 'hilfe' }, function () {
      this.route('index', { path: '' });
      this.route('page', { path: ':id' });
    });
    this.route('info-hilfe-tooltips', { path: 'hilfe-tooltips/:area' }, function () {
      this.route('index', { path: ':name' });
    });
    this.route('info-impressum', { path: 'impressum' });

    this.route('debug', function () {
      this.route('errorTest');
      this.route('loadingTest');
      this.route('numericInput');
      this.route('multiParametersLink');
      this.route('errorCatchTest');
    });

    this.route('admin-performance', { path: 'performance' });
    this.route('admin-activity', { path: 'activity' });
    this.route('admin-page', { path: 'page' }, function () {
      this.route('index', { path: '' });
      this.route('page', { path: ':id' }, function () {
        this.route('index', { path: '' });
        this.route('edit', function () {
          this.route('save');
          this.route('delete');
        });
      });
    });
    this.route('admin-help-toc', { path: 'help_toc' }, function () {
      this.route('save');
    });
    this.route('admin-file', { path: 'file' }, function () {
      this.route('index', { path: '' });
      this.route('neu', { path: 'neu' }, function () {
        this.route('save');
      });
    });
    this.route('admin-help-tooltip', { path: 'tooltip' });
    this.route('admin-stats', { path: 'stats' });

    this.route('user-logout', { path: 'logout' });

    this.route('unterlagen', { path: 'unterlagen' });

    this.route('export', { path: 'export' }, function () {
      this.route('index', { path: '' });
    });

    this.route('import', { path: 'import' }, function () {
      this.route('index', { path: '' });
    });

    this.route('catch-all', { path: '*:' });
  });

  exports.default = Router;
});