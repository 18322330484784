define('mpforst-ember/app/pods/assistent-bestand-neu/route', ['exports', 'mpforst-ember/app/objects/local-storage-object'], function (exports, _localStorageObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return _localStorageObject.default.create({
        cache_key: this.get('routeName')
      });
    }
  });
});