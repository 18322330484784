define('mpforst-ember/app/pods/admin-page/page/edit/save/route', ['exports', 'ember-mobiledoc-text-renderer'], function (exports, _emberMobiledocTextRenderer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'loading',
    model: function model() {
      var model = JSON.parse(JSON.stringify(this.modelFor('admin-page.page.edit'))); //take copy
      model.check_new = this.paramsFor('admin-page.page').id == 'neu';
      if (!model.id) {
        model.id = model.name.replace(/\s/g, '_').replace(/\W/g, '');
      }
      if (model.sections) {
        var renderer = new _emberMobiledocTextRenderer.default();
        var rendered = '';
        for (var i = 0; i < model.sections.length; ++i) {
          if (model.sections[i].content) {
            var r = renderer.render(model.sections[i].content);
            if (r && r.result) {
              rendered += r.result + '\n';
            }
          }
        }
        model.text = rendered;
      }
      return new Promise(function (resolve) {
        Ember.$.ajax({
          method: 'PUT',
          url: 'api/page/save/' + model.id,
          data: JSON.stringify(model),
          contentType: "application/json; charset=utf-8",
          dataType: "json"
        }).then(resolve, function () {
          resolve(false);
        });
      });
    },
    afterModel: function afterModel(data) {
      if (data) {
        this.transitionTo('admin-page');
        // message anzeigen
        Ember.$('#notify').notify({
          type: 'success',
          closable: false,
          message: {
            text: 'Seite erfolgreich gespeichert'
          }
        }).show();
      } else {
        this.transitionTo('admin-page.page.edit');
        // message anzeigen
        Ember.$('#notify').notify({
          type: 'error',
          closable: false,
          message: {
            text: 'Seite konnte nicht gespeichert werden'
          }
        }).show();
      }
    }
  });
});