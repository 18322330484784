define('mpforst-ember/app/pods/assistent-taxation/einleitung/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel() /*model*/{
      //this.transitionTo('assistent-taxation-entwurf');
    }
  });
});