define('mpforst-ember/app/pods/assistent-taxation-editor/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor('assistent-taxation-entwurf.selected');

      return Ember.$.ajax({
        method: 'PUT',
        url: 'api/entwurfTaxation/' + model.get('id'),
        data: JSON.stringify(model),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function (x) {
        return Ember.Object.create(x);
      });
    }
  });
});