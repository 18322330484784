define('mpforst-ember/app/pods/assistent-export-setup/download/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function transform(x) {
    if (x.get('triChecked') !== 1 && x.get('triChecked') !== 2) {
      return null;
    }
    var tmp = {};
    tmp.id = x.get('id');
    if (x.children) {
      tmp.children = x.get('children').map(function (y) {
        return transform(y);
      }).filter(function (y) {
        return y;
      });
    }
    return tmp;
  }

  exports.default = Ember.Route.extend({
    model: function model(params) {
      var model = this.modelFor('assistent-export-setup');
      var req = model.children.map(function (x) {
        return transform(x);
      }).filter(function (x) {
        return x;
      });

      return Ember.$.ajax({
        url: 'api/export/' + model.type,
        contentType: "application/json",
        data: JSON.stringify(req),
        dataType: "json",
        method: "POST"
      });
    },
    afterModel: function afterModel(params) {
      // start download
      var $iframe = Ember.$('<iframe>');

      $iframe.attr('src', 'api/export/download/' + (params | 0));
      $iframe.css({
        position: 'fixed',
        left: "-10px",
        top: "-10px",
        width: "1px",
        height: "1px"
      });
      $iframe.appendTo(Ember.$('body'));

      this.replaceWith('assistent-export-setup');
    }
  });
});