define('mpforst-ember/app/pods/info-hilfe-tooltips/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    tooltips: Ember.inject.service('tooltips'),
    model: function model(param) {
      return this.get('tooltips').items.filter(function (x) {
        return x.area === param.area && x.subject;
      });
    }
  });
});