define("mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 8
                },
                "end": {
                  "line": 5,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.beschreibung.ueberhaelter_stk", ["loc", [null, [4, 44], [4, 79]]], 0, 0, 0, 0]], [], [], 0, 0], "min", 0, "max", 999, "fraction", 0, "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [4, 110], [4, 114]]], 0, 0, 0, 0]], [], [], 0, 0], "postfix", "Stk"], ["loc", [null, [4, 12], [4, 130]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Stückzahl am Ort", "hiebsatz", true, "area", "Bestand", "name", "StueckzahlAmOrt"], 0, null, ["loc", [null, [3, 8], [5, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 8
                },
                "end": {
                  "line": 11,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.beschreibung.ueberhaelter_masse", ["loc", [null, [10, 42], [10, 79]]], 0, 0, 0, 0]], [], [], 0, 0], "min", 0, "max", 999, "fraction", 0, "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [10, 110], [10, 114]]], 0, 0, 0, 0]], [], [], 0, 0], "postfix", "fm"], ["loc", [null, [10, 10], [10, 129]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 4
              },
              "end": {
                "line": 12,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Masse am Ort", "hiebsatz", true, "area", "Bestand", "name", "MasseAmOrt"], 0, null, ["loc", [null, [9, 8], [11, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 8
                },
                "end": {
                  "line": 17,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.beschreibung.ueberhaelter_laubholz_prozent", ["loc", [null, [16, 40], [16, 88]]], 0, 0, 0, 0]], [], [], 0, 0], "min", 0, "max", 999, "fraction", 0, "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [16, 119], [16, 123]]], 0, 0, 0, 0]], [], [], 0, 0], "postfix", "%"], ["loc", [null, [16, 8], [16, 137]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 18,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Anteil Laubholz in % der Stückzahl", "area", "Bestand", "name", "AnteilLaubholz", "hiebsatz", true], 0, null, ["loc", [null, [15, 8], [17, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 21,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 22,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 3], 0, null, ["loc", [null, [2, 4], [6, 26]]]], ["block", "foundation-column", [], ["large", 3], 1, null, ["loc", [null, [8, 4], [12, 26]]]], ["block", "foundation-column", [], ["large", 3], 2, null, ["loc", [null, [14, 4], [18, 26]]]], ["block", "foundation-column", [], ["large", 3], 3, null, ["loc", [null, [20, 4], [21, 26]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 23,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-ueberhaelter/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "foundation-row", [], ["class", "clearfix"], 0, null, ["loc", [null, [1, 0], [22, 19]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});