define('mpforst-ember/app/pods/admin-page/page/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    breadCrumb: Ember.computed.alias('model.name'),
    buttons: [{
      title: 'Bearbeiten',
      action: 'edit',
      class: 'success'
    }],
    section: Ember.computed('model.markdown', function () {
      var section = (this.get('model.markdown') || '').split('{').map(function (x) {
        var res = {
          markdown: x
        };
        if (x.indexOf("section:") == 0) {
          var split = x.split('}', 2);
          var titles = split[0].replace('section:', '').trim().split(';').map(function (x) {
            return x.trim();
          });
          res = {
            title: titles[0],
            subtitle: titles[1],
            markdown: split[1]
          };
        }
        return res;
      });

      return section.filter(function (x) {
        return x.markdown.replace(/(?:\r\n|\r|\n)/g, '').trim().length !== 0;
      });
    }),
    actions: {
      edit: function edit() {
        this.transitionToRoute('admin-page.page.edit');
      },
      actionbarHandling: function actionbarHandling(action) {
        this.send.apply(this, [action].concat(_toConsumableArray(Array.prototype.slice.call(arguments, 1))));
      }
    }
  });
});