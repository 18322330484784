define('mpforst-ember/app/pods/components/x-table/x-column/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        title: null,
        setup: false,
        initColumn: Ember.on('init', function () {
            if (!this.get('setup')) {
                return;
            }
            var parent = this.get('parent');
            parent.send('setupColumn', this.get('id'), this.get('title'), this.get('width'), this.get('sortKey'));
        })
    });
});