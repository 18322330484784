define('mpforst-ember/app/pods/assistent-wzpx-entwurf/selected/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(param) {
      return Ember.$.getJSON('api/entwurfWzpx/' + param.entwurf).then(function (x) {
        var t = Ember.Object.create(x);
        if (t.bestand && t.bestand.length) {
          for (var i = 0; i < t.bestand.length; ++i) {
            //wzpx
            var bestand = t.bestand[i];
            bestand.error = Ember.A(bestand.error);
            bestand.wzpx = Ember.A(bestand.wzpx);
            for (var j = 0; j < bestand.wzpx.length; ++j) {
              var wzpx = bestand.wzpx[j];
              wzpx.error = Ember.A(wzpx.error);

              // old ember sucks 
              bestand.wzpx[j] = Ember.Object.create(bestand.wzpx[j]);
            }
          }
        }
        return t;
      });
    }
  });
});