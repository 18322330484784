define('mpforst-ember/app/pods/grunddaten-betrieb/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(param) {
      var betriebId = param.betrieb;
      var key = 'legacy_mpforst_betrieb_' + betriebId;
      try {
        var tmp = JSON.parse(sessionStorage.getItem(key));
        if (tmp) {
          return Ember.Object.create(tmp);
        }
      } catch (e) {
        /* IGNORE */
        e;
      }
      return Ember.$.getJSON('api/betrieb/' + betriebId).then(function (x) {
        try {
          sessionStorage.setItem(key, JSON.stringify(x));
        } catch (e) {
          /* IGNORE */
          e;
        }
        return Ember.Object.create(x);
      });
    }
  });
});