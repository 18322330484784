define('mpforst-ember/app/pods/admin-page/page/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var param = this.paramsFor('admin-page.page');
      var id = param.id;
      if (id == 'neu') {
        id = '';
      }
      return Ember.$.getJSON('api/page/' + id);
    }
  });
});