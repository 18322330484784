define('mpforst-ember/app/pods/admin-page/page/edit/delete/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'loading',
    model: function model() {
      var model = this.modelFor('admin-page.page.edit');
      return Ember.$.ajax({
        method: 'DELETE',
        url: 'api/page/delete/' + model.id
      });
    },
    afterModel: function afterModel(data) {
      this.transitionTo('admin-page');
      // message anzeigen
      Ember.$('#notify').notify({
        type: 'success',
        closable: false,
        message: {
          text: 'Seite erfolgreich gelöscht'
        }
      }).show();
    }
  });
});