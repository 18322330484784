define("mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 8
                },
                "end": {
                  "line": 5,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.beschreibung.alter", ["loc", [null, [4, 42], [4, 66]]], 0, 0, 0, 0]], [], [], 0, 0], "min", 0, "max", 999, "fraction", 0, "edit", false, "postfix", "Jahre", "emptyValue", "(wird aus Kennzahlen berechnet)"], ["loc", [null, [4, 10], [4, 165]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Alter", "area", "Bestand", "name", "Alter"], 0, null, ["loc", [null, [3, 8], [5, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 9,
                  "column": 8
                },
                "end": {
                  "line": 11,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.beschreibung.rel_alter", ["loc", [null, [10, 42], [10, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "min", 0, "max", 999, "fraction", 0, "edit", false, "postfix", "%U", "emptyValue", "(wird aus Kennzahlen berechnet)"], ["loc", [null, [10, 10], [10, 166]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 8,
                "column": 4
              },
              "end": {
                "line": 12,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Reifegrad", "area", "Bestand", "name", "relativesAlter"], 0, null, ["loc", [null, [9, 8], [11, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 8
                },
                "end": {
                  "line": 17,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.beschreibung.min_alter", ["loc", [null, [16, 42], [16, 70]]], 0, 0, 0, 0]], [], [], 0, 0], "min", 0, "max", 999, "fraction", 0, "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [16, 101], [16, 105]]], 0, 0, 0, 0]], [], [], 0, 0], "postfix", "Jahre"], ["loc", [null, [16, 10], [16, 123]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 14,
                "column": 4
              },
              "end": {
                "line": 18,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Minimales Bestandesalter", "area", "Bestand", "name", "MinBestandesalter"], 0, null, ["loc", [null, [15, 8], [17, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 21,
                  "column": 8
                },
                "end": {
                  "line": 23,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "@mut", [["get", "model.beschreibung.max_alter", ["loc", [null, [22, 44], [22, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "min", 0, "max", 999, "fraction", 0, "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [22, 103], [22, 107]]], 0, 0, 0, 0]], [], [], 0, 0], "postfix", "Jahre"], ["loc", [null, [22, 12], [22, 125]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 20,
                "column": 4
              },
              "end": {
                "line": 24,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Maximales Bestandesalter", "area", "Bestand", "name", "MaxBestandesalter"], 0, null, ["loc", [null, [21, 8], [23, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 25,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 3], 0, null, ["loc", [null, [2, 4], [6, 26]]]], ["block", "foundation-column", [], ["large", 3], 1, null, ["loc", [null, [8, 4], [12, 26]]]], ["block", "foundation-column", [], ["large", 3], 2, null, ["loc", [null, [14, 4], [18, 26]]]], ["block", "foundation-column", [], ["large", 3], 3, null, ["loc", [null, [20, 4], [24, 26]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 30,
                    "column": 8
                  },
                  "end": {
                    "line": 32,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.dominierende_wuchsklasse_id", ["loc", [null, [31, 42], [31, 88]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", true, "items", ["subexpr", "unbound", [["subexpr", "concat", ["api/stammdaten/wuchsklasse?bloesse=", ["get", "wuchsklasse_id", ["loc", [null, [31, 160], [31, 174]]], 0, 0, 0, 0]], [], ["loc", [null, [31, 114], [31, 175]]], 0, 0]], [], ["loc", [null, [31, 105], [31, 176]]], 0, 0]], ["loc", [null, [31, 10], [31, 178]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 32,
                    "column": 8
                  },
                  "end": {
                    "line": 34,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.dominierende_wuchsklasse_id", ["loc", [null, [33, 42], [33, 88]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", false, "items", "api/stammdaten/wuchsklasse?visible_wzpx=false", "emptyValue", "(wird aus Kennzahlen berechnet)", "displayEmptyItem", true], ["loc", [null, [33, 10], [33, 222]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 29,
                  "column": 6
                },
                "end": {
                  "line": 35,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "edit", ["loc", [null, [30, 14], [30, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [30, 8], [34, 15]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 4
              },
              "end": {
                "line": 36,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "dominierende Wuchsklasse", "area", "Bestand", "name", "domierendeWuchsklasse"], 0, null, ["loc", [null, [29, 6], [35, 27]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 39,
                  "column": 8
                },
                "end": {
                  "line": 41,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.durchmesser_verteilung_id", ["loc", [null, [40, 42], [40, 86]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [40, 92], [40, 96]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/durchmesserverteilung", "displayEmptyItem", true], ["loc", [null, [40, 10], [40, 165]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 4
              },
              "end": {
                "line": 42,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Durchmesserverteilung", "area", "Bestand", "name", "Durchmesserverteilung"], 0, null, ["loc", [null, [39, 8], [41, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 45,
                  "column": 8
                },
                "end": {
                  "line": 47,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.bestand_zustand_id", ["loc", [null, [46, 42], [46, 79]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [46, 85], [46, 89]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/bestandeszustand", "displayEmptyItem", true], ["loc", [null, [46, 10], [46, 153]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 44,
                "column": 4
              },
              "end": {
                "line": 48,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Bestandeszustand", "area", "Bestand", "name", "Bestandeszustand"], 0, null, ["loc", [null, [45, 8], [47, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 51,
                  "column": 8
                },
                "end": {
                  "line": 53,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.bestand_geschichte_id", ["loc", [null, [52, 42], [52, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [52, 88], [52, 92]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/bestandesgeschichte", "displayEmptyItem", true], ["loc", [null, [52, 10], [52, 159]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 50,
                "column": 4
              },
              "end": {
                "line": 54,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Bestandesgeschichte", "area", "Bestand", "name", "Bestandesgeschichte"], 0, null, ["loc", [null, [51, 8], [53, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 27,
              "column": 0
            },
            "end": {
              "line": 55,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 3], 0, null, ["loc", [null, [28, 4], [36, 26]]]], ["block", "foundation-column", [], ["large", 3], 1, null, ["loc", [null, [38, 4], [42, 26]]]], ["block", "foundation-column", [], ["large", 3], 2, null, ["loc", [null, [44, 4], [48, 26]]]], ["block", "foundation-column", [], ["large", 3], 3, null, ["loc", [null, [50, 4], [54, 26]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 57,
              "column": 0
            },
            "end": {
              "line": 69,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reveal-modal-bg");
          dom.setAttribute(el1, "style", "display: block;");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reveal-modal open");
          dom.setAttribute(el1, "data-reveal", "");
          dom.setAttribute(el1, "aria-labelledby", "errorModalTitle");
          dom.setAttribute(el1, "aria-hidden", "false");
          dom.setAttribute(el1, "role", "dialog");
          dom.setAttribute(el1, "style", "padding: 0.5em; position: fixed; display: block; opacity: 1; visibility: visible; top: 88px;");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "alert-box alert");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          dom.setAttribute(el3, "style", "color: inherit");
          var el4 = dom.createElement("i");
          dom.setAttribute(el4, "class", "fa fa-exclamation-triangle");
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode(" Kennzahlen werden gelöscht");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("p");
          var el4 = dom.createTextNode("Durch das Ändern der Wuchsklasse zu Blöße werden die bestehenden Kennzahlen nach dem Speichern gelöscht.");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("div");
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "right button radius secondary no-mb");
          var el5 = dom.createTextNode("Abbrechen");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n          ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("button");
          dom.setAttribute(el4, "class", "button radius no-mb");
          var el5 = dom.createTextNode("Fortfahren");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [3, 1, 5]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element0, [3]);
          var morphs = new Array(2);
          morphs[0] = dom.createElementMorph(element1);
          morphs[1] = dom.createElementMorph(element2);
          return morphs;
        },
        statements: [["element", "action", ["abortRemoveKennzahlen"], [], ["loc", [null, [64, 62], [64, 96]]], 0, 0], ["element", "action", ["okayRemoveKennzahlen"], [], ["loc", [null, [65, 46], [65, 79]]], 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 70,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "foundation-row", [], ["class", "clearfix"], 0, null, ["loc", [null, [1, 0], [25, 19]]]], ["block", "foundation-row", [], ["class", "clearfix"], 1, null, ["loc", [null, [27, 0], [55, 19]]]], ["block", "if", [["get", "removeKennzahlenPopup", ["loc", [null, [57, 6], [57, 27]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [57, 0], [69, 7]]]]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});