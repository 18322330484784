define('mpforst-ember/app/pods/assistent-wzpx-result/index/save/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var model = this.modelFor('assistent-wzpx-entwurf.selected');
      return Ember.$.ajax('api/entwurfWzpx/Finalize/' + model.get('id')).then(function () {
        return params;
      });
    },
    afterModel: function afterModel(params) {
      window.frameElement.actions.saved(params.teilbetrieb);
      return;
      this.replaceWith('assistent-wzpx.success', params.betrieb, params.teilbetrieb, params.new, params.mod, params.old);
    }
  });
});