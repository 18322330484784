define("mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    queryParams: ["s", "assistent"],

    assistent: null,

    teilbetrieb_neu_label: 'Neuen Teilbetrieb anlegen',
    buttons: [{
      title: 'Neuen Betrieb anlegen',
      action: 'newBetrieb',
      class: 'success'
    }, {
      title: 'Neuen Teilbetrieb anlegen',
      action: 'new',
      class: 'success'
    }, {
      title: 'Bearbeiten',
      action: 'edit',
      class: 'alert'
    }],

    actions: {
      newBetrieb: function newBetrieb() {
        this.transitionToRoute('grunddaten-betrieb-neu');
      },

      new: function _new() {
        this.transitionToRoute('grunddaten-teilbetrieb-neu');
      },

      edit: function edit() {
        this.transitionToRoute('grunddaten-betrieb.bearbeiten');
      },

      actionbarHandling: function actionbarHandling(action) {
        this.send.apply(this, [action].concat(_toConsumableArray(Array.prototype.slice.call(arguments, 1))));
      }
    }
  });
});