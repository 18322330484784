define('mpforst-ember/app/pods/components/event-didinsertelement/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.sendAction.apply(this, ['action', this].concat(Array.prototype.slice.call(arguments)));
    }
  });
});