define('mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-gruppe-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    items: Ember.computed('item.id', function () {
      return "api/stammdaten/ertragstafel/" + this.get('item.id');
    }),
    guppen: [{
      value: 1,
      caption: '1'
    }, {
      value: 2,
      caption: '2'
    }, {
      value: 3,
      caption: '3'
    }, {
      value: 4,
      caption: '4'
    }, {
      value: 5,
      caption: '5'
    }]
  });
});