define('mpforst-ember/app/pods/components/grunddaten-auswahl/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    //default settings
    displayBetrieb: true,
    displayTeilbetrieb: true,
    displayBestand: true,
    warnEmpty: true, // can only be used with showAllOption = false
    showAllOption: false, // can only be used with warnEmpty = false
    edit: true,

    _setupArray: function _setupArray(x) {
      var array = x.map(function (y) {
        return {
          value: y.id,
          caption: y.bezeichnung
        };
      });
      if (this.get('showAllOption')) {
        array = [{
          value: 0,
          caption: 'alle'
        }].concat(array);
      }
      return array;
    },

    betriebList: Ember.computed('model', function () {
      var _this = this;

      this.send('loadingInc');
      return Ember.$.getJSON('api/betrieb').then(function (x) {
        _this.send('loadingDec');
        return _this._setupArray(x);
      }, function () {
        _this.send('loadingDec');
      });
    }),
    teilbetriebList: Ember.computed('model.betrieb_id', function () {
      var _this2 = this;

      this.send('loadingInc');
      return Ember.$.getJSON('api/betrieb/' + this.get('model.betrieb_id')).then(function (x) {
        _this2.send('loadingDec');
        return _this2._setupArray(x.teilbetriebe);
      }, function () {
        _this2.send('loadingDec');
      });
    }),
    bestandList: Ember.computed('model.teilbetrieb_id', function () {
      var _this3 = this;

      this.send('loadingInc');
      return Ember.$.getJSON('api/teilbetrieb/' + this.get('model.teilbetrieb_id')).then(function (x) {
        _this3.send('loadingDec');
        return _this3._setupArray(x.bestaende);
      }, function () {
        _this3.send('loadingDec');
      });
    }),
    update: Ember.observer('model.betrieb_id', 'model.teilbetrieb_id', 'model.bestand_id', function () {
      if (!this.get('model.betrieb_id') && (this.get('model.teilbetrieb_id') || this.get('model.bestand_id'))) {
        this.setProperties({
          'model.teilbetrieb_id': null,
          'model.bestand_id': null
        });
      }
      if (!this.get('model.teilbetrieb_id') && this.get('model.bestand_id')) {
        this.setProperties({
          'model.bestand_id': null
        });
      }
    }),
    _setLoading: function _setLoading() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.set('loading', this._loading);
    },
    actions: {
      loadingInc: function loadingInc() {
        this._loading = (this._loading || 0) + 1;
        Ember.run.once(this, this._setLoading);
      },
      loadingDec: function loadingDec() {
        this._loading = (this._loading || 0) - 1;
        Ember.run.once(this, this._setLoading);
      }
    }
  });
});