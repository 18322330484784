define('mpforst-ember/app/pods/assistent-planung/success/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return {
        betrieb: parseInt(params.betrieb),
        teilbetrieb: parseInt(params.teilbetrieb),
        bestand: parseInt(params.bestand)
      };
    }
  });
});