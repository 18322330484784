define('mpforst-ember/app/pods/components/x-table/component', ['exports', 'mpforst-ember/app/objects/local-storage-object'], function (exports, _localStorageObject) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        columns: null, // init in columnsInit
        paging: 1,
        _current: null,
        search: null,
        sortKey: null,
        sortOrder: true,
        sortable: true,
        _currentSortCol: null,

        _currentSortColInit: Ember.observer('columns', 'columns.length', function () {
            if (this.get('sortKey')) {
                return;
            }
            var cols = this.get('columns') || [];
            for (var i = 0; i < cols.length; ++i) {
                if (cols[i].sortKey) {
                    this.set('_currentSortCol', cols[i]);
                    this.set('sortKey', cols[i].sortKey);
                    return;
                }
            }
        }),

        cached: Ember.computed('cacheKey', function () {
            return _localStorageObject.default.create({
                cache_key: this.get('cacheKey')
            });
        }),

        columnsInit: Ember.on('init', function () {
            this.set('columns', Ember.A());
        }),

        resetPaging: Ember.observer('search', function () {
            this.set('_current', null);
        }),

        sortedModel: Ember.computed('model', 'model.length', 'sortKey', 'sortOrder', function () {
            var model = this.get('model');
            var sortKey = this.get('sortKey');
            var sortOrder = this.get('sortOrder');
            if (!sortKey) {
                return model;
            }
            if (sortKey === 'bezeichnung') {
                var sorted = model.copy();
                sorted.forEach(function (o) {
                    o._sortKey = (((o || {}).bezeichnung || '').match(/((?:[0-9]+)|(?:[^0-9]+))/g) || []).map(function (x) {
                        return isNaN(parseInt(x)) ? x.toLowerCase() : parseInt(x);
                    });
                });
                sorted.sort(function (a, b) {
                    var l = Math.max(a._sortKey.length, b._sortKey.length);
                    for (var i = 0; i < l; ++i) {
                        var va = a._sortKey[i] == null || a._sortKey[i] == undefined ? '' : a._sortKey[i];
                        var vb = b._sortKey[i] == null || b._sortKey[i] == undefined ? '' : b._sortKey[i];
                        if (va < vb) {
                            return -1;
                        }
                        if (va > vb) {
                            return 1;
                        }
                    }
                    return 0;
                });
            } else {
                var sorted = model.copy().sortBy(sortKey);
            }
            if (!sortOrder) {
                sorted.reverse();
            }
            return sorted;
        }),

        filteredModel: Ember.computed('sortedModel', 'sortedModel.length', 'searchKey', 'search', function () {
            var search = this.get('search');
            var model = this.get('sortedModel');
            var key = this.get('searchKey');
            if (!key || !search) {
                return model;
            }

            return model.filter(function (item) {
                if (item.get) {
                    return String(item.get(key)).indexOf(search) === 0;
                }
                return String(item[key]).indexOf(search) === 0;
            });
        }),

        pagingModel: Ember.computed('filteredModel', 'filteredModel.length', 'currentPage', 'paging', function () {
            var paging = this.get('paging');
            if (!paging) {
                return this.get('filteredModel');
            }
            var current = this.get('currentPage');
            return this.get('filteredModel').slice(current.offset * paging, current.offset * paging + paging);
        }),

        currentPage: Ember.computed('_current', 'pages', function () {
            var current = this.get('_current');
            var pages = this.get('pages');
            if (pages.length === 0) {
                return { offset: 0, label: 0 };
            }
            if (current == null) {
                current = pages[0];
            }
            return current;
        }),

        pages: Ember.computed('filteredModel', 'filteredModel.length', 'paging', function () {
            var paging = this.get('paging');
            var model = this.get('filteredModel');
            var count = Math.ceil(model.length / paging);

            var array = Ember.A();
            for (var i = 0; i < count; ++i) {
                array.addObject(Ember.Object.create({
                    offset: i,
                    lable: i + 1
                }));
            }
            return array;
        }),

        pagingFront: Ember.computed('pages', 'currentPage', function () {
            var pages = this.get('pages');
            if (pages.length === 0) {
                return [];
            }
            var current = this.get('currentPage');
            if (current.offset < 4) {
                return [];
            }
            return pages.slice(0, 2);
        }),

        pagingMiddle: Ember.computed('pages', 'currentPage', function () {
            var pages = this.get('pages');
            if (pages.length === 0) {
                return [];
            }
            var current = this.get('currentPage');
            if (current.offset - 1 < 0) {
                return pages.slice(current.offset, current.offset + 4);
            }
            return pages.slice(current.offset - 1, current.offset - 1 + 4);
        }),

        pagingBack: Ember.computed('pages', 'currentPage', function () {
            var pages = this.get('pages');
            if (pages.length === 0) {
                return [];
            }

            var current = this.get('currentPage');
            if (current.offset >= pages.length - 4) {
                return [];
            }

            return pages.slice(pages.length - 2, pages.length - 2 + 2);
        }),

        hasPageNext: Ember.computed('currentPage', 'pages', function () {
            var current = this.get('currentPage');
            var pages = this.get('pages');
            return current.offset + 1 < pages.length;
        }),

        hasPagePrev: Ember.computed('currentPage', function () {
            var current = this.get('currentPage');
            return current.offset - 1 > 0;
        }),

        showPaging: Ember.computed('filteredModel', 'filteredModel.length', 'paging', function () {
            var paging = this.get('paging');
            if (!paging) {
                return false;
            }
            var length = this.get('filteredModel.length');
            return length > paging;
        }),

        actions: {
            setupColumn: function setupColumn(id, title, width, sortKey) {
                var columns = this.get('columns');
                if (!columns.isAny('id', id)) {
                    columns.addObject(Ember.Object.create({
                        id: id,
                        title: title,
                        width: width,
                        sortKey: sortKey
                    }));
                }
            },
            setPage: function setPage(page) {
                this.set('_current', page);
                return false;
            },
            setPageNext: function setPageNext() {
                this.send('setPage', this.get('pages')[this.get('currentPage').offset + 1]);
            },
            setPagePrev: function setPagePrev() {
                this.send('setPage', this.get('pages')[this.get('currentPage').offset - 1]);
            },
            closeRow: function closeRow() {
                this.set('cached.openRowValue', null);
            },
            openRow: function openRow(rowValue) {
                this.set('cached.openRowValue', rowValue);
            },
            setSortBy: function setSortBy(col) {
                var _currentSortCol = this.get('_currentSortCol');
                if (_currentSortCol === col) {
                    this.toggleProperty('sortOrder');
                    return;
                }
                this.set('_currentSortCol', col);
                this.set('sortKey', col.sortKey);
            }
        }
    });
});