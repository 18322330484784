define('mpforst-ember/app/helpers/hash', ['exports', 'ember-mobiledoc-editor/helpers/hash'], function (exports, _hash) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _hash.default;
    }
  });
  Object.defineProperty(exports, 'hash', {
    enumerable: true,
    get: function () {
      return _hash.hash;
    }
  });
});