define('mpforst-ember/app/pods/assistent-taxation-editor/editor/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel(model) {
      var _this = this;

      var bestand = Ember.$.ajax('api/bestand/' + model.get('bestand_id')).then(function (data) {
        _this.controllerFor(_this.get('routeName')).set('bestand', data);
      });
      var teilbetrieb = Ember.$.ajax('api/teilbetrieb/' + model.get('teilbetrieb_id')).then(function (data) {
        _this.controllerFor(_this.get('routeName')).set('bonis', data.baumarten);
      });
      return Ember.RSVP.all([bestand, teilbetrieb]);
    }
  });
});