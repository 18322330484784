define('mpforst-ember/app/pods/assistent-wzpx-editor/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadCrumb: Ember.computed('model.bestand', function () {
      var bestand = this.get('model.bestand') || [];
      if (bestand.length <= 50) {
        return 'Editor';
      }
    })
  });
});