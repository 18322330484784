define('mpforst-ember/app/helpers/add1', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.add1 = add1;
  function add1(params /*, hash*/) {
    return params[0] + 1;
  }

  exports.default = Ember.Helper.helper(add1);
});