define('mpforst-ember/app/pods/components/editor-br-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'span',
    classNames: ['word-empty-line'],
    classNameBindings: ['wordEmptyLineEditor'],
    wordEmptyLineEditor: Ember.computed.bool('editor')
  });
});