define('mpforst-ember/app/pods/assistent-nachweisung-editor/components/x-produktion-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    model: Ember.computed(function () {
      return {};
    }),

    foerderfaehigItems: [{
      value: 0,
      caption: 'nicht förderfähig'
    }, {
      value: 1,
      caption: 'förderfähig'
    }],

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._change = function () {
        if (_this.get('empty')) {
          _this.sendAction('actionNew', _this.get('model'));
        }
        _this.sendAction('actionChange', _this.get('model'));
      };
      this.$().on("change", "input, select", this._change);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._change) {
        this.$().off("change", "input, select", this._change);
      }
    },


    actions: {
      delete: function _delete() {
        this.sendAction('actionDelete', this.get('model'));
      }
    }
  });
});