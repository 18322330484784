define('mpforst-ember/app/pods/components/rotated-text/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'svg',
    angle: -90,
    lines: Ember.computed('value', function () {
      return this.get('value').split('\\n');
    }),
    textAnchor: 'start',

    didInsertElement: function didInsertElement() {
      this._update();
    },

    textObsorver: Ember.observer('value', 'angle', function () {
      Ember.run.once(this, this._update);
    }),

    _update: function _update() {
      var $this = this.$();
      var $text = $this.find('text');

      $text.attr('transform', '');

      var bb = $text[0].getBBox();
      var a = parseFloat(this.get('angle'));

      var w = Math.abs(Math.cos(a / 360 * 2 * Math.PI) * bb.width) + Math.abs(Math.cos((a + 90) / 360 * 2 * Math.PI) * bb.height) + 3;
      var h = Math.abs(Math.sin(a / 360 * 2 * Math.PI) * bb.width) + Math.abs(Math.sin((a + 90) / 360 * 2 * Math.PI) * bb.height) + 3;

      $this.css('width', w + 'px').css('height', h + 'px');

      $text.attr('transform', 'translate(' + w / 2 + ', ' + h / 2 + ') rotate(' + a + ') translate(' + -bb.width / 2 + ', ' + -bb.height / 2 + ')');
    }
  });
});