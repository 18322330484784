define('mpforst-ember/app/pods/assistent-wzpx-editor/components/x-bestand/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tbody',

    actions: {
      open: function open() {
        this.sendAction('actionOpen', this.get('model'));
      },
      close: function close() {
        this.sendAction('actionClose', this.get('model'));
      },
      delete: function _delete() {
        this.sendAction('actionDelete', this.get('model'));
      },

      actionNewWzpx: function actionNewWzpx(wzpx) {
        var wzpxArr = this.get('model.wzpx') || Ember.A();
        wzpxArr.addObject(wzpx);
        this.set('model.wzpx', wzpx);
      },
      actionChange: function actionChange() {
        console.log("x-bestand change");
        this.sendAction('actionChange', this.get('model'));
      }
    },

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._change = function () {
        if (_this.get('empty')) {
          _this.sendAction('actionNew', _this.get('model'));
        }
        _this.sendAction('actionChange', _this.get('model'));
      };
      this.$('.bestand').on("change", "input, select", this._change);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._change) {
        this.$('.bestand').off("change", "input, select", this._change);
      }
    }
  });
});