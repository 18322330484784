define("mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("h2");
          var el2 = dom.createTextNode("Kennzahlen aktualisieren");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 4
                },
                "end": {
                  "line": 27,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "data-alert", "");
              dom.setAttribute(el1, "class", "alert-box secondary radius");
              dom.setAttribute(el1, "style", "margin: 1em;");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h3");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-exclamation-circle");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("  Die Kennzahlen von ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" nicht mehr aktuell");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createTextNode("Es haben sich Parameter geändert, welche die Kennzahlen beeinflussen.");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createTextNode("Kennzahlen müssen neu berechnet werden.");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1, 1]);
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(element0, 2, 2);
              morphs[1] = dom.createMorphAt(element0, 4, 4);
              return morphs;
            },
            statements: [["inline", "promise-value", [["get", "countPromise", ["loc", [null, [19, 94], [19, 106]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 78], [19, 108]]], 0, 0], ["inline", "if", [["subexpr", "gt", [["subexpr", "promise-value", [["get", "countPromise", ["loc", [null, [19, 133], [19, 145]]], 0, 0, 0, 0]], [], ["loc", [null, [19, 118], [19, 146]]], 0, 0], 1], [], ["loc", [null, [19, 114], [19, 149]]], 0, 0], "Beständen sind", "Bestand ist"], [], ["loc", [null, [19, 109], [19, 182]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 27,
                  "column": 4
                },
                "end": {
                  "line": 36,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "data-alert", "");
              dom.setAttribute(el1, "class", "alert-box success radius");
              dom.setAttribute(el1, "style", "margin: 1em;");
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("h3");
              dom.setAttribute(el2, "style", "color: white;");
              var el3 = dom.createElement("i");
              dom.setAttribute(el3, "class", "fa fa-check-circle-o");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode(" Die Kennzahlen aller Bestände sind aktuell");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n        ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("p");
              dom.setAttribute(el2, "class", "no-mb");
              var el3 = dom.createTextNode("\n          ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("ul");
              dom.setAttribute(el3, "class", "no-mb");
              var el4 = dom.createTextNode("\n            ");
              dom.appendChild(el3, el4);
              var el4 = dom.createElement("li");
              var el5 = dom.createTextNode("Eine Aktualisierung der Kennzahlen ist nicht nötig.");
              dom.appendChild(el4, el5);
              dom.appendChild(el3, el4);
              var el4 = dom.createTextNode("\n          ");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 2
              },
              "end": {
                "line": 37,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "promise-value", [["get", "countPromise", ["loc", [null, [17, 25], [17, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 10], [17, 38]]], 0, 0]], [], 0, 1, ["loc", [null, [17, 4], [36, 11]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "clear: both");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          return morphs;
        },
        statements: [["inline", "grunddaten-auswahl", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [7, 10], [7, 15]]], 0, 0, 0, 0]], [], [], 0, 0], "displayBetrieb", true, "displayTeilbetrieb", true, "displayBestand", false, "loading", ["subexpr", "@mut", [["get", "loading", ["loc", [null, [11, 12], [11, 19]]], 0, 0, 0, 0]], [], [], 0, 0], "warnEmpty", false, "showAllOption", true], ["loc", [null, [6, 2], [14, 4]]], 0, 0], ["block", "unless", [["subexpr", "promise-loading", [["get", "countPromise", ["loc", [null, [16, 29], [16, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [16, 12], [16, 42]]], 0, 0]], [], 0, null, ["loc", [null, [16, 2], [37, 13]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 2
              },
              "end": {
                "line": 42,
                "column": 69
              }
            },
            "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Zurück");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 45,
                    "column": 6
                  },
                  "end": {
                    "line": 49,
                    "column": 44
                  }
                },
                "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("Weiter");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes() {
                return [];
              },
              statements: [],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 44,
                  "column": 4
                },
                "end": {
                  "line": 50,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["block", "link-to", ["assistent-kennzahlen-setup.auswahl.save", ["subexpr", "if", [["get", "model.betrieb_id", ["loc", [null, [46, 14], [46, 30]]], 0, 0, 0, 0], ["get", "model.betrieb_id", ["loc", [null, [46, 31], [46, 47]]], 0, 0, 0, 0], 0], [], ["loc", [null, [46, 10], [46, 50]]], 0, 0], ["subexpr", "if", [["get", "model.teilbetrieb_id", ["loc", [null, [47, 14], [47, 34]]], 0, 0, 0, 0], ["get", "model.teilbetrieb_id", ["loc", [null, [47, 35], [47, 55]]], 0, 0, 0, 0], 0], [], ["loc", [null, [47, 10], [47, 58]]], 0, 0], ["subexpr", "if", [["get", "model.bestand_id", ["loc", [null, [48, 14], [48, 30]]], 0, 0, 0, 0], ["get", "model.bestand_id", ["loc", [null, [48, 31], [48, 47]]], 0, 0, 0, 0], 0], [], ["loc", [null, [48, 10], [48, 50]]], 0, 0]], ["class", "button radius tiny"], 0, null, ["loc", [null, [45, 6], [49, 56]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 43,
                "column": 2
              },
              "end": {
                "line": 51,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["subexpr", "promise-value", [["get", "countPromise", ["loc", [null, [44, 25], [44, 37]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 10], [44, 38]]], 0, 0]], [], 0, null, ["loc", [null, [44, 4], [50, 13]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 41,
              "column": 0
            },
            "end": {
              "line": 52,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["assistent"], ["class", "button radius secondary tiny"], 0, null, ["loc", [null, [42, 2], [42, 81]]]], ["block", "unless", [["subexpr", "and", [["get", "loading", ["loc", [null, [43, 17], [43, 24]]], 0, 0, 0, 0], ["subexpr", "promise-loading", [["get", "countPromise", ["loc", [null, [43, 42], [43, 54]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 25], [43, 55]]], 0, 0]], [], ["loc", [null, [43, 12], [43, 56]]], 0, 0]], [], 1, null, ["loc", [null, [43, 2], [51, 13]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 55,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "foundation-column", [], ["large", 12, "class", "clearfix"], 0, null, ["loc", [null, [1, 0], [3, 22]]]], ["block", "mpf-magellan-section", [], ["titel", "Betrieb / Teilbetrieb", "class", "clearfix"], 1, null, ["loc", [null, [5, 0], [39, 25]]]], ["block", "knopf-bar", [], [], 2, null, ["loc", [null, [41, 0], [52, 14]]]], ["content", "outlet", ["loc", [null, [54, 0], [54, 10]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1, child2]
    };
  }());
});