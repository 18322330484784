define('mpforst-ember/app/services/tooltips', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    items: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('items', []);
    },
    load: function load() {
      var _this = this;

      return Ember.$.getJSON('api/tooltips').then(function (x) {
        _this.set('items', x);
        return null;
      });
    }
  });
});