define('mpforst-ember/app/pods/grunddaten-bestand/anzeigen/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor('grunddaten-bestand');
      this.controllerFor(this.get('routeName')).set('teilbetrieb_id', parseInt(this.paramsFor('grunddaten-teilbetrieb').teilbetrieb));
      return model;
    }
  });
});