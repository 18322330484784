define('mpforst-ember/app/pods/assistent-export-setup/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        breadCrumb: "Einstellungen",
        type: [{
            value: 'excel',
            caption: 'Microsoft Excel' /*, {
                                          value: 'access',
                                          caption: 'Microsoft Access'
                                       }, {
                                          value: 'json',
                                          caption: 'JSON'
                                       }, {
                                          value: 'xml',
                                          caption: 'XML'
                                       }*/ }]
    });
});