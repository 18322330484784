define('mpforst-ember/app/pods/admin-page/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    buttons: [{
      title: 'Neue Seite anlegen',
      action: 'new',
      class: 'success'
    }],
    _model: Ember.computed('model', function () {
      var m = this.get('model');
      for (var i = 0; i < m.length; ++i) {
        var p = m[i];
        var parent = m.find(function (x) {
          return x.id === p.parent;
        });
        if (parent) {
          p.found_parent = true;
          parent.children = parent.children || [];
          parent.children.push(p);
        }
      }
      return m.filter(function (x) {
        return !x.found_parent;
      });
    }),
    actions: {
      new: function _new() {
        this.transitionToRoute('admin-page.page.edit', 'neu');
      },
      actionbarHandling: function actionbarHandling(action) {
        this.send.apply(this, [action].concat(_toConsumableArray(Array.prototype.slice.call(arguments, 1))));
      }
    }
  });
});