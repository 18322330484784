define('mpforst-ember/app/components/render-mobiledoc', ['exports', 'ember-mobiledoc-dom-renderer/components/render-mobiledoc'], function (exports, _renderMobiledoc) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _renderMobiledoc.default;
    }
  });
});