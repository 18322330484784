define('mpforst-ember/app/helpers/json', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.json = json;
  function json(params /*, hash*/) {
    return JSON.stringify(params, null, 4);
  }

  exports.default = Ember.Helper.helper(json);
});