define('mpforst-ember/app/pods/grunddaten-bestand/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(param) {
      var bestandId = param.bestand;
      var key = 'legacy_mpforst_bestand_' + bestandId;
      try {
        var tmp = JSON.parse(sessionStorage.getItem(key));
        if (tmp) {
          return Ember.Object.create(tmp);
        }
      } catch (e) {
        /* IGNORE */
        e;
      }
      return Ember.$.getJSON('api/bestand/' + bestandId).then(function (x) {
        return Ember.$.getJSON('api-v2/nachweisung/' + bestandId).then(function (y) {
          x.nachweisung = y;
          try {
            sessionStorage.setItem(key, JSON.stringify(x));
          } catch (e) {
            /* IGNORE */
            e;
          }
          return Ember.Object.create(x);
        });
      });
    }
  });
});