define('mpforst-ember/app/pods/grunddaten-bestand/bearbeiten/save/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'loading',
    xKlimafitt: Ember.inject.service(),
    model: function model() {
      var model = this.modelFor('grunddaten-bestand.bearbeiten');
      return Ember.$.ajax({
        method: 'PUT',
        url: 'api/bestand/' + model.id,
        data: JSON.stringify(model),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      });
    },
    afterModel: function afterModel(data) {
      window.frameElement.actions.saved(data.id);
      return;

      // OLD STUFF:
      this.transitionTo('grunddaten-bestand', data.id);

      // Routen neu laden:
      this.controllerFor('grunddaten-bestand').get('target').router.refresh();
      // message anzeigen
      Ember.$('#notify').notify({
        type: 'success',
        closable: false,
        message: {
          text: 'Bestand erfolgreich gespeichert'
        }
      }).show();
      this.get('xKlimafitt').refresh();
    },
    actions: {
      error: function error(reason, transition) {
        transition.abort();
        var model = this.modelFor('grunddaten-bestand.bearbeiten');
        if (reason.status === 409) {
          Ember.set(model, 'error', {
            messages: ["Die Bezeichnung wird bereits verwendet"]
          });
        } else {
          try {
            Ember.set(model, 'error', JSON.parse(reason.responseText));
          } catch (e) {
            Ember.set(model, 'error', {
              messages: [reason.status + " " + reason.responseText]
            });
          }
        }
        this.transitionTo('grunddaten-bestand.bearbeiten');
        //debugger;
      }
    }
  });
});