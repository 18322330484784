define('mpforst-ember/app/pods/assistent-nachweisung-editor/components/x-naturschutz/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    edit: true,

    modelWithEmpty: Ember.computed('edit', 'model', 'model.length', 'emptyRow', function () {
      var array = (this.get('model') || Ember.A()).slice();
      if (this.get('edit')) {
        array.addObject(this.get('emptyRow'));
      }

      return array;
    }),
    emptyRow: null,
    initEmptyRow: Ember.on('init', function () {
      this.set('emptyRow', Ember.Object.create({}));
    }),

    actions: {
      actionNewRow: function actionNewRow(row) {
        var model = this.get('model') || Ember.A();
        model.addObject(row);
        this.set('model', model);
        this.set('emptyRow', Ember.Object.create({}));
      },
      actionDeleteRow: function actionDeleteRow(row) {
        var model = this.get('model');
        model.removeObject(row);
        this.sendAction('actionChange', this.get('model'));
      },
      actionChangeRow: function actionChangeRow() /*row*/{
        this.sendAction('actionChange', this.get('model'));
      }
    }
  });
});