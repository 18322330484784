define('mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    queryParams: ["s"],
    ertragstafel1: Ember.computed('model.baumarten.[]', function () {
      var arr = this.get('model.baumarten');
      return arr.slice(0, Math.ceil(arr.length / 2));
    }),
    ertragstafel2: Ember.computed('model.baumarten.[]', function () {
      var arr = this.get('model.baumarten');
      return arr.slice(Math.ceil(arr.length / 2), arr.length);
    }),

    bestand_neu_label: 'neuen Bestand anlegen',
    buttons: [{
      title: 'neuen Teilbetrieb anlegen',
      action: 'newTeilbetrieb',
      class: 'success'
    }, {
      title: 'neuen Bestand anlegen',
      action: 'new',
      class: 'success'
    }, {
      title: 'Bearbeiten',
      action: 'edit',
      class: 'alert'
    }],

    assistents: [{
      title: 'GIS Upload',
      action: 'gisUpload',
      class: ''
    }],

    showAssistentUpload: Ember.computed.equal('assistent', 'gis-upload'),

    popups: Ember.inject.service('popups'),

    actions: {
      newTeilbetrieb: function newTeilbetrieb() {
        this.transitionToRoute('grunddaten-teilbetrieb-neu');
      },

      new: function _new() {
        this.transitionToRoute('grunddaten-bestand-neu');
      },

      edit: function edit() {
        this.transitionToRoute('grunddaten-teilbetrieb.bearbeiten');
      },

      gisUpload: function gisUpload() {
        // muh
        this.set('assistent', 'gis-upload');
      },

      hideUpload: function hideUpload() {
        // muh
        this.set('assistent', undefined);
      },

      actionbarHandling: function actionbarHandling(action) {
        this.send.apply(this, [action].concat(_toConsumableArray(Array.prototype.slice.call(arguments, 1))));
      },

      openHelp: function openHelp() {
        var _get;

        (_get = this.get('popups')).openHelp.apply(_get, arguments);
      }
    }
  });
});