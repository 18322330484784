define('mpforst-ember/app/pods/assistent-flaechen-upload/success/controller', ['exports', 'mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    breadCrumb: 'Ergebnis',
    actions: {
      refreshKennzahlen: function refreshKennzahlen() {
        this.notifyPropertyChange('countPromise');
      }
    }
  });
});