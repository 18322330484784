define('mpforst-ember/app/pods/grunddaten-bestand/anzeigen/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    buttons: [{
      title: 'neuen Bestand anlegen',
      action: 'new',
      class: 'success'
    }, {
      title: 'Bearbeiten',
      action: 'edit',
      class: 'alert'
    }],

    kennzahlen: Ember.computed('model.kennzahlen', function () {
      if (!this.get('model.kennzahlen')) {
        return null;
      }
      return [{
        okay: true,
        result: this.get('model.kennzahlen')
      }];
    }),

    kennzahlenStatus: Ember.computed('model.kennzahlen_status', function () {
      var m = this.get('model.kennzahlen_status');
      switch (m) {
        case 0:
          return "von WZPX berechnet";
        case 1:
          return "von WZPX mit Fläche berechnet";
        case 2:
          return "von Taxation berechnet";
        case -1:
          return "von WZPX (Altdaten)";
        case -2:
          return "von WZPX mit Fläche (Altdaten)";
        case -3:
          return "von Taxation (Altdaten)";
      }
      return "von unbekannter Quelle (z.B.: Altdaten) berechnet";
    }),

    showPlanung: Ember.computed('model.produktion', 'model.nutzung', 'model.naturschutz', function () {
      return this.get('model.produktion') || this.get('model.nutzung') || this.get('model.naturschutz');
    }),

    showNachweisung: Ember.computed('model.nachweisung.produktion', 'model.nachweisung.nutzung', 'model.nachweisung.naturschutz', function () {
      return this.get('model.nachweisung.produktion') || this.get('model.nachweisung.nutzung') || this.get('model.nachweisung.naturschutz');
    }),

    kennzahlenFirstResult: Ember.computed('model.kennzahlen', function () {
      return (this.get('model.kennzahlen') || [null])[0];
    }),

    actions: {
      new: function _new() {
        this.transitionToRoute('grunddaten-bestand-neu');
      },

      edit: function edit() {
        this.transitionToRoute('grunddaten-bestand.bearbeiten');
      },

      actionbarHandling: function actionbarHandling(action) {
        this.send.apply(this, [action].concat(_toConsumableArray(Array.prototype.slice.call(arguments, 1))));
      },

      refresh: function refresh() {
        var _this = this;

        Ember.$.ajax('api/bestand/' + this.get('model.id')).then(function (model) {
          Ember.run(function () {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }
            // neues model anzeigen
            _this.get('model').setProperties(model);
          });
        }, function () {
          throw new Error('error refresh kennzahlen, reload bestand');
        });
      }
    }
  });
});