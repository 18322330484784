define("mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 2
              },
              "end": {
                "line": 5,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "mpf-actionbar", [], ["buttons", ["subexpr", "@mut", [["get", "buttons", ["loc", [null, [4, 28], [4, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "actionbarHandling"], ["loc", [null, [4, 4], [4, 64]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 2
              },
              "end": {
                "line": 8,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "mpf-actionbar", [], ["buttons", ["subexpr", "@mut", [["get", "assistents", ["loc", [null, [7, 28], [7, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "actionbarHandling", "title", "Tools"], ["loc", [null, [7, 4], [7, 81]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 30,
                "column": 2
              },
              "end": {
                "line": 32,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "grunddaten-betrieb/components/x-details", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [31, 52], [31, 57]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [31, 63], [31, 67]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [31, 4], [31, 69]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 34,
                "column": 2
              },
              "end": {
                "line": 36,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "grunddaten-betrieb/components/x-katasterflaechen", [], ["model", ["subexpr", "@mut", [["get", "model.katasterflaeche", ["loc", [null, [35, 61], [35, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [35, 88], [35, 92]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [35, 4], [35, 94]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child4 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 38,
                "column": 2
              },
              "end": {
                "line": 40,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "grunddaten-betrieb/components/x-nutzungsflaechen", [], ["model", ["subexpr", "@mut", [["get", "model.nutzungsflaeche", ["loc", [null, [39, 61], [39, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [39, 88], [39, 92]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [39, 4], [39, 94]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child5 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 43,
                  "column": 4
                },
                "end": {
                  "line": 52,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "grunddaten-betrieb/components/x-teilbetriebe", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [44, 59], [44, 64]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [44, 6], [44, 66]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 42,
                "column": 2
              },
              "end": {
                "line": 53,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "mpf-magellan-section", [], ["titel", "Teilbetriebe", "class", "clearfix"], 0, null, ["loc", [null, [43, 4], [52, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child6 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 63,
                        "column": 14
                      },
                      "end": {
                        "line": 65,
                        "column": 14
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                    return morphs;
                  },
                  statements: [["content", "error", ["loc", [null, [64, 20], [64, 29]]], 0, 0, 0, 0]],
                  locals: ["error"],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 8
                    },
                    "end": {
                      "line": 69,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "data-alert", "");
                  dom.setAttribute(el1, "class", "alert-box alert radius");
                  dom.setAttribute(el1, "style", "margin: 1em;");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("h2");
                  dom.setAttribute(el2, "style", "color: white");
                  var el3 = dom.createElement("i");
                  dom.setAttribute(el3, "class", "fa fa-warning");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("  Fehler");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("p");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("ul");
                  var el4 = dom.createTextNode("\n");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3, 1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "each", [["get", "model.error.messages", ["loc", [null, [63, 22], [63, 42]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [63, 14], [65, 23]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            var child1 = function () {
              var child0 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 73,
                          "column": 14
                        },
                        "end": {
                          "line": 73,
                          "column": 105
                        }
                      },
                      "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("Löschen");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 71,
                        "column": 10
                      },
                      "end": {
                        "line": 75,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("span");
                    dom.setAttribute(el1, "class", "right");
                    dom.setAttribute(el1, "style", "margin-left: 1.25em;");
                    var el2 = dom.createTextNode("\n              ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode("\n            ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                    return morphs;
                  },
                  statements: [["block", "link-to", ["grunddaten-betrieb.bearbeiten.delete"], ["class", "radius alert tiny button"], 0, null, ["loc", [null, [73, 14], [73, 117]]]]],
                  locals: [],
                  templates: [child0]
                };
              }();
              var child1 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 77,
                          "column": 12
                        },
                        "end": {
                          "line": 77,
                          "column": 104
                        }
                      },
                      "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("Speichern");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 76,
                        "column": 10
                      },
                      "end": {
                        "line": 78,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["block", "link-to", ["grunddaten-betrieb-neu.save"], ["class", "right radius success tiny button"], 0, null, ["loc", [null, [77, 12], [77, 116]]]]],
                  locals: [],
                  templates: [child0]
                };
              }();
              var child2 = function () {
                var child0 = function () {
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 79,
                          "column": 12
                        },
                        "end": {
                          "line": 79,
                          "column": 111
                        }
                      },
                      "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("Speichern");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes() {
                      return [];
                    },
                    statements: [],
                    locals: [],
                    templates: []
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 78,
                        "column": 10
                      },
                      "end": {
                        "line": 80,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["block", "link-to", ["grunddaten-betrieb.bearbeiten.save"], ["class", "right radius success tiny button"], 0, null, ["loc", [null, [79, 12], [79, 123]]]]],
                  locals: [],
                  templates: [child0]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 70,
                      "column": 8
                    },
                    "end": {
                      "line": 81,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(2);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "unless", [["get", "new", ["loc", [null, [71, 20], [71, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [71, 10], [75, 21]]]], ["block", "if", [["get", "new", ["loc", [null, [76, 16], [76, 19]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [76, 10], [80, 17]]]]],
                locals: [],
                templates: [child0, child1, child2]
              };
            }();
            var child2 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 81,
                      "column": 8
                    },
                    "end": {
                      "line": 92,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "data-alert", "");
                  dom.setAttribute(el1, "class", "alert-box alert radius");
                  dom.setAttribute(el1, "style", "margin: 1em;");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("h2");
                  dom.setAttribute(el2, "style", "color: white");
                  var el3 = dom.createElement("i");
                  dom.setAttribute(el3, "class", "fa fa-warning");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("  Fehler");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("p");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("ul");
                  var el4 = dom.createTextNode("\n                ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createTextNode("Der Name des Betriebes muss ausgefüllt werden");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n\n          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("button");
                  dom.setAttribute(el1, "class", "right radius success tiny button");
                  dom.setAttribute(el1, "disabled", "true");
                  var el2 = dom.createTextNode("Speichern");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes() {
                  return [];
                },
                statements: [],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 6
                  },
                  "end": {
                    "line": 99,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "onclick", "javascript:window.frameElement.actions.aborted()");
                dom.setAttribute(el1, "class", "radius tiny button");
                var el2 = dom.createTextNode("Abbrechen");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "if", [["get", "model.error", ["loc", [null, [58, 14], [58, 25]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [58, 8], [69, 15]]]], ["block", "if", [["get", "model.bezeichnung", ["loc", [null, [70, 14], [70, 31]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [70, 8], [92, 15]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 4
                },
                "end": {
                  "line": 100,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "edit", ["loc", [null, [57, 12], [57, 16]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [57, 6], [99, 13]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 55,
                "column": 2
              },
              "end": {
                "line": 101,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "knopf-bar", [], [], 0, null, ["loc", [null, [56, 4], [100, 18]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 0
            },
            "end": {
              "line": 103,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(7);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
          morphs[5] = dom.createMorphAt(fragment, 10, 10, contextualElement);
          morphs[6] = dom.createMorphAt(fragment, 12, 12, contextualElement);
          dom.insertBoundary(fragment, 0);
          return morphs;
        },
        statements: [["block", "if", [["get", "buttons", ["loc", [null, [3, 8], [3, 15]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [3, 2], [5, 9]]]], ["block", "if", [["get", "assistents", ["loc", [null, [6, 8], [6, 18]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [6, 2], [8, 9]]]], ["block", "mpf-magellan-section", [], ["titel", "Allgemein", "class", "clearfix"], 2, null, ["loc", [null, [30, 2], [32, 27]]]], ["block", "mpf-magellan-section", [], ["titel", "Betriebsflächen nach Kataster", "area", "Betrieb", "name", "BetriebsflaecheNachKataster", "class", "clearfix"], 3, null, ["loc", [null, [34, 2], [36, 27]]]], ["block", "mpf-magellan-section", [], ["titel", "Betriebsflächen laut Nutzung", "area", "Betrieb", "name", "BetriebsflaecheNachNutzung", "class", "clearfix"], 4, null, ["loc", [null, [38, 2], [40, 27]]]], ["block", "unless", [["get", "edit", ["loc", [null, [42, 12], [42, 16]]], 0, 0, 0, 0]], [], 5, null, ["loc", [null, [42, 2], [53, 13]]]], ["block", "if", [["get", "edit", ["loc", [null, [55, 8], [55, 12]]], 0, 0, 0, 0]], [], 6, null, ["loc", [null, [55, 2], [101, 9]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4, child5, child6]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 106,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "id", "navmap");
        dom.setAttribute(el1, "class", "navmap");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        return morphs;
      },
      statements: [["block", "mpf-magellan", [], ["restoreScrollWith", ["subexpr", "@mut", [["get", "s", ["loc", [null, [2, 34], [2, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [2, 0], [103, 17]]]], ["content", "outlet", ["loc", [null, [105, 0], [105, 10]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});