define('mpforst-ember/app/pods/grunddaten/uebersicht/controller', ['exports', 'mpforst-ember/app/objects/local-storage-object'], function (exports, _localStorageObject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    queryParams: ["s"],
    buttons: [{
      title: 'Neuen Betrieb anlegen',
      action: 'new',
      class: 'success'
    }],
    cache: _localStorageObject.default.create({
      cache_key: 'grunddaten.index'
    }),
    actions: {
      new: function _new() {
        this.transitionToRoute('grunddaten-betrieb-neu');
      },
      actionbarHandling: function actionbarHandling(action) {
        this.send.apply(this, [action].concat(_toConsumableArray(Array.prototype.slice.call(arguments, 1))));
      },
      openBetrieb: function openBetrieb(id) {
        this.set('cache.betriebId', id);
      },
      closeBetrieb: function closeBetrieb() /*id*/{
        this.set('cache.betriebId', null);
      },
      openTeilbetrieb: function openTeilbetrieb(id) {
        this.set('cache.teilbetriebId', id);
      },
      closeTeilbetrieb: function closeTeilbetrieb() /*id*/{
        this.set('cache.teilbetriebId', null);
      }
    }
  });
});