define('mpforst-ember/app/pods/components/foundation-postfix/component', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Component.extend({
        tagName: '',
        hide: Ember.computed('hideWhenEmpty', 'label', function () {
            return this.get('hideWhenEmpty') && !this.get('label');
        })
    });
});