define('mpforst-ember/app/pods/components/mpf-input/x-autocomplete/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function ensurePromise(x) {
    return new Ember.RSVP.Promise(function (resolve) {
      resolve(x);
    });
  }

  var cache = {};
  var cache_def = {};
  var cache_todo = [];

  function mergedRequestForCache() {
    if (cache_todo.length === 0) {
      return;
    }

    cache_todo.forEach(function (res) {
      var key = 'legacy_mpforst_stammdaten_' + res.replace(/\//g, '_');
      try {
        var data = sessionStorage.getItem(key);
        if (data) {
          data = JSON.parse(data);
          cache_def[res].resolve(data);
          cache[res] = data;
          return;
        }
      } catch (e) {
        e;
        /* IGNORE */
      }
      Ember.$.ajax({
        url: res,
        method: 'GET',
        //data: JSON.stringify(cache_todo),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function (data) {
        try {
          sessionStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
          e;
          /* IGNORE */
        }
        cache_def[res].resolve(data);
        cache[res] = data;
      });
    });

    cache_todo = [];
  }

  exports.default = Ember.Component.extend({
    loading: true,
    edit: true,
    value: null,
    items: null,
    _items: null,
    _version: 0,

    itemsObserver: Ember.on('init', Ember.observer('items', function () {
      var _this = this;

      var items = this.get('items');
      var promise;
      if (typeof items === 'string' || items instanceof String) {
        if (cache[items]) {
          if (Array.isArray(cache[items])) {
            this.setProperties({
              loading: false,
              _items: cache[items]
            });
            return;
          } else {
            promise = cache[items];
          }
        } else {
          var deferred = Ember.$.Deferred();

          promise = deferred.promise();
          cache_def[items] = deferred;
          cache[items] = promise;
          cache_todo.push(items);

          //Ember.run.next(() => {
          mergedRequestForCache();
          //});
        }
      } else if (Array.isArray(items)) {
        this.setProperties({
          loading: false,
          _items: items
        });
        return;
      } else {
        promise = ensurePromise(this.get('items'));
      }

      // promise route
      this._version += 1;
      // get the items
      var captured_version = this._version;
      promise.then(function (x) {
        if (captured_version !== _this._version) {
          return;
        }
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.setProperties({
          loading: false,
          _items: x
        });
      }, function () {
        if (captured_version !== _this._version) {
          return;
        }
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.setProperties({
          loading: false,
          _items: []
        });
      });
    })),

    _update_value: 0,
    _value_observer: Ember.observer('value', '_items', function () {
      if (this.get('value') == null) {
        return;
      }
      this.set('_update_value', this.get('_update_value') + 1);
    }),

    _caption: Ember.computed('type', '_update_value', function () {
      var data = this.getProperties('value', '_items');
      if (!data._items || !data._items.length) {
        return null;
      }
      var items = data._items.filter(function (x) {
        /* jslint eqeq: true */
        return x.value == data.value;
      });
      if (!items.length) {
        // keine items gefunden..
        return null;
      }
      return items[0].caption;
    }),

    caption: Ember.computed('_caption', {
      get: function get() {
        return this.get('_caption');
      },
      set: function set(key, value) {
        if (value !== this.get('_caption')) {
          this.set('value', null);
          this.sendAction('autocomplete_change', value);
        }
        return value;
      }
    }),

    _items_length: Ember.computed('_items', function () {
      return Math.max(2, this.get('_items').length);
    }),

    _focus_out_timer: null,

    isAutoComplete: Ember.computed('_items', function () {
      return Ember.$.isArray(this.get('_items'));
    }),

    actions: {
      autocomplete: function autocomplete(event, e) {
        var _this2 = this;

        if (event === 'input focus' || event === 'select focus') {
          Ember.run.cancel(this._focus_out_timer);
          this.set('display_autocomplete', true);
        } else if (event === 'input blur' || event === 'select blur') {
          Ember.run.cancel(this._focus_out_timer);
          this._focus_out_timer = Ember.run.next(function () {
            if (_this2.isDestroyed || _this2.isDestroying) {
              return;
            }
            _this2.set('display_autocomplete', false);
            // reset to useable state
            var _items = _this2.get('_items');
            if (_items) {
              var caption = _this2.get('caption');
              var items = _items.filter(function (x) {
                /* jslint eqeq: true */
                return x.caption == caption;
              });
              if (items.length !== 1) {
                _this2.set('value', null);
                _this2.set('caption', null);
              } else {
                _this2.set('value', items[0].value);
              }
            } else {
              _this2.set('value', null);
            }
          });
        } else if (event === 'select keydown') {
          if (e.keyCode === 13 /*ENTER*/ || e.keyCode === 9 /*TAB*/) {
              if (e.keyCode === 13 /*ENTER*/) {
                  Ember.run.cancel(this._focus_out_timer);
                  this.$('input').focus();
                  this.set('display_autocomplete', false);
                }
              this.set('value', e.target.value);
            }
        } else if (event === 'select click') {
          Ember.run.cancel(this._focus_out_timer);
          this.$('input').focus();
          this.set('display_autocomplete', false);
          this.set('value', e.target.value);
        } else if (event === 'input keydown') {
          e = arguments[2];
          if (e.keyCode === 40 /*ARROW DOWN*/ || e.keyCode === 38 /* ARROW UP */) {
              var $select = this.$('select');
              var value = $select.val() || '';
              if (value === '') {
                if (e.keyCode === 40 /*ARROW DOWN*/) {
                    value = $select.find('option:first-child').attr('value');
                  } else {
                  value = $select.find('option:last-child').attr('value');
                }
              } else {
                // not very save string manipulation..
                if (e.keyCode === 40 /*ARROW DOWN*/) {
                    value = $select.find('option[value="' + value + '"]').next().attr('value');
                  } else {
                  value = $select.find('option[value="' + value + '"]').prev().attr('value');
                }
              }
              $select.val(value);
              $select.focus();
            } else if (e.keyCode === 9 /*TAB*/) {
              Ember.run.cancel(this._focus_out_timer);
              this.set('display_autocomplete', false);
            }
        }
      }
    }
  });
});