define('mpforst-ember/app/pods/assistent-wzpx-result/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function delayedPromise(f, t) {
    var df = Ember.$.Deferred();
    window.setTimeout(function () {
      var _arguments = arguments;

      f().then(function () {
        df.resolve.apply(df, _arguments);
      }, function () {
        df.reject.apply(df, _arguments);
      });
    }, t);
    return df.promise();
  }

  function rejectedPromise() {
    var df = Ember.$.Deferred();
    df.reject();
    return df.promise();
  }

  exports.default = Ember.Controller.extend({
    loading: true,
    version: 0,
    internal_version: 0,

    _versionUpdate: function _versionUpdate() {
      this.set('internal_version', this.get('internal_version') + 1);
    },
    versionUpdate: Ember.observer('version', function () {
      this._hidden_version = this.get('internal_version') + 1;
      Ember.run.debounce(this, this._versionUpdate, 1000);
    }),
    result: Ember.computed('internal_version', function () {
      var _this = this;

      var application = Ember.getOwner(this).lookup('controller:application');
      console.log("Current Route Name: ", application.currentRouteName);
      if (application.currentRouteName !== 'assistent-wzpx-result.index.index') {
        return;
      }
      console.log("start with internal_version = " + this.get('internal_version'));
      var model = this.get('model');
      if (!model) {
        return;
      }
      var notify = Ember.$('#notify').notify({
        //type: '',
        closable: false,
        message: {
          html: '<i class="fa fa-cog fa-spin"></i>&nbsp;Berechnung wird gestartet...'
        },
        fadeOut: {
          enable: true,
          delay: 10000
        }
      });
      notify.show();
      var version = this.get('internal_version');
      console.log('Restart with internal_version ', version);
      var resultArray = Ember.A();
      this.set('loading', true);
      var promise = Ember.$.Deferred();
      promise.resolve(null);
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = model.bestand[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var bestand = _step.value;

          promise = promise.then(function (bestand) {
            var data = JSON.stringify({
              bestand_name: bestand.bestand_name
            });
            var url = 'api/entwurfWzpx/result/' + model.get('id');
            console.log(version + " != " + this._hidden_version);
            if (version !== this._hidden_version) {
              return rejectedPromise();
            }
            notify.$note.html('<i class="fa fa-cog fa-spin"></i>&nbsp;' + (resultArray.length + 1) + '/' + model.bestand.length + ' wird berechnet...');
            return delayedPromise(function () {
              return Ember.$.ajax({
                method: 'POST',
                url: url,
                data: data,
                contentType: "application/json; charset=utf-8",
                dataType: "json"
              }).then(function (data) {
                Ember.run(function () {
                  resultArray.pushObject({
                    okay: data.okay,
                    bestand: bestand,
                    exists: data.exists,
                    result: data.result
                  });
                });
              });
            }, 1);
          }.bind(this, bestand));
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      promise.done(function () {
        if (version === _this._hidden_version) {
          Ember.run(function () {
            _this.set('loading', false);
          });
        }
        notify.hide();
      }).fail(function () {
        if (version === _this._hidden_version) {
          Ember.run(function () {
            _this.set('loading', false);
            _this.set('error', true);
          });
        }
        notify.hide();
      });

      return resultArray;
    }),
    resultNewCount: Ember.computed('result', 'result.length', function () {
      return this.get('result').filterBy('exists', false).filterBy('okay', true).length;
    }),
    resultModCount: Ember.computed('result', 'result.length', function () {
      return this.get('result').filterBy('exists', true).filterBy('okay', true).length;
    }),
    resultOldCount: Ember.computed('result', 'result.length', function () {
      return this.get('model.bestand_existing_count') - this.get('result').filterBy('exists', true).length;
    }),
    resultErrorCount: Ember.computed('result', 'result.length', function () {
      return this.get('result').filterBy('okay', false).length;
    })
  });
});