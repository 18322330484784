define("mpforst-ember/app/pods/components/foundation-label/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 2,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/components/foundation-label/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("span");
        dom.setAttribute(el1, "class", "input-label");
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(element0, 0, 0);
        morphs[1] = dom.createMorphAt(element0, 1, 1);
        morphs[2] = dom.createMorphAt(element0, 2, 2);
        morphs[3] = dom.createMorphAt(fragment, 1, 1, contextualElement);
        return morphs;
      },
      statements: [["inline", "unbound", [["get", "titel", ["loc", [null, [1, 36], [1, 41]]], 0, 0, 0, 0]], [], ["loc", [null, [1, 26], [1, 43]]], 0, 0], ["inline", "mpf-input-tooltip", [], ["required", ["subexpr", "@mut", [["get", "required", ["loc", [null, [1, 72], [1, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "kennzahlen", ["subexpr", "@mut", [["get", "kennzahlen", ["loc", [null, [1, 92], [1, 102]]], 0, 0, 0, 0]], [], [], 0, 0], "hiebsatz", ["subexpr", "@mut", [["get", "hiebsatz", ["loc", [null, [1, 112], [1, 120]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 43], [1, 122]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", ["subexpr", "@mut", [["get", "area", ["loc", [null, [1, 146], [1, 150]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [1, 156], [1, 160]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [1, 122], [1, 162]]], 0, 0], ["content", "yield", ["loc", [null, [1, 169], [1, 178]]], 0, 0, 0, 0]],
      locals: [],
      templates: []
    };
  }());
});