define('mpforst-ember/app/pods/grunddaten-teilbetrieb/bearbeiten/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    ertragstafel1: Ember.computed('model.baumarten.[]', function () {
      var arr = this.get('model.baumarten');
      return arr.slice(0, Math.ceil(arr.length / 2));
    }),
    ertragstafel2: Ember.computed('model.baumarten.[]', function () {
      var arr = this.get('model.baumarten');
      return arr.slice(Math.ceil(arr.length / 2), arr.length);
    }),

    obsWarnVorgabewerte: Ember.observer('model.wuchsgebiet_id', 'model.betriebsart_id', function () {
      this.set('warnVorgabewerte', true);
    }),
    obsWarnReset: Ember.observer('model', function () {
      this.set('warnVorgabewerte', false);
    }),

    breadCrumb: "Bearbeiten",
    edit: true,

    actions: {
      loadDefaults: function loadDefaults(hideNotify) {
        var _this = this;

        var model = this.get('model');
        this.set('loading', true);

        Ember.$.ajax({
          method: 'POST',
          url: 'api/teilbetrieb/replaceDefaults',
          data: JSON.stringify(model),
          contentType: "application/json; charset=utf-8",
          dataType: "json"
        }).then(function (data) {
          data = Ember.Object.create(data);
          Ember.run(function () {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }
            _this.set('loading', false);
            delete data.id;
            _this.get('model').setProperties(data);
            if (!hideNotify) {
              Ember.$('#notify').notify({
                type: 'success',
                closable: false,
                message: {
                  text: 'Vorgabewerte erfolgreich geladen'
                }
              }).show();
            }
          });
        });
      }
    }
  });
});