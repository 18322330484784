define("mpforst-ember/app/pods/components/mpf-magellan-section/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 6
                },
                "end": {
                  "line": 7,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/mpf-magellan-section/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h3");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("h5");
              dom.setAttribute(el1, "class", "mpf-magellan-title h5-hack");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1]);
              var morphs = new Array(5);
              morphs[0] = dom.createAttrMorph(element3, 'class');
              morphs[1] = dom.createMorphAt(element3, 0, 0);
              morphs[2] = dom.createMorphAt(element3, 1, 1);
              morphs[3] = dom.createMorphAt(element3, 2, 2);
              morphs[4] = dom.createMorphAt(dom.childAt(fragment, [3]), 0, 0);
              return morphs;
            },
            statements: [["attribute", "class", ["concat", ["mpf-magellan-title ", ["subexpr", "if", [["get", "active", ["loc", [null, [5, 43], [5, 49]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [5, 38], [5, 60]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "titel", ["loc", [null, [5, 62], [5, 71]]], 0, 0, 0, 0], ["inline", "mpf-input-tooltip", [], ["required", ["subexpr", "@mut", [["get", "required", ["loc", [null, [5, 100], [5, 108]]], 0, 0, 0, 0]], [], [], 0, 0], "kennzahlen", ["subexpr", "@mut", [["get", "kennzahlen", ["loc", [null, [5, 120], [5, 130]]], 0, 0, 0, 0]], [], [], 0, 0], "hiebsatz", ["subexpr", "@mut", [["get", "hiebsatz", ["loc", [null, [5, 140], [5, 148]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 71], [5, 150]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", ["subexpr", "@mut", [["get", "area", ["loc", [null, [5, 174], [5, 178]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [5, 184], [5, 188]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [5, 150], [5, 190]]], 0, 0], ["content", "subtitel", ["loc", [null, [6, 47], [6, 59]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 11,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/mpf-magellan-section/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "mpf-magellan-spacer");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("div");
            dom.setAttribute(el1, "class", "clearfix");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            dom.insertBoundary(fragment, 0);
            return morphs;
          },
          statements: [["block", "s.sticky", [], ["below", "#magellan-header", "sticky-class", "white-bg"], 0, null, ["loc", [null, [4, 6], [7, 19]]]], ["content", "yield", ["loc", [null, [9, 6], [9, 15]]], 0, 0, 0, 0]],
          locals: ["s"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 2
            },
            "end": {
              "line": 12,
              "column": 2
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/mpf-magellan-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "sticky-container", [], [], 0, null, ["loc", [null, [3, 4], [11, 25]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 12,
              "column": 2
            },
            "end": {
              "line": 22,
              "column": 2
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/mpf-magellan-section/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("    ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h3");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n        ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("h5");
          dom.setAttribute(el3, "class", "mpf-magellan-title h5-hack");
          var el4 = dom.createComment("");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "mpf-magellan-spacer");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          dom.setAttribute(el2, "class", "clearfix");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element0 = dom.childAt(fragment, [1]);
          var element1 = dom.childAt(element0, [1]);
          var element2 = dom.childAt(element1, [1]);
          var morphs = new Array(6);
          morphs[0] = dom.createAttrMorph(element2, 'class');
          morphs[1] = dom.createMorphAt(element2, 0, 0);
          morphs[2] = dom.createMorphAt(element2, 1, 1);
          morphs[3] = dom.createMorphAt(element2, 2, 2);
          morphs[4] = dom.createMorphAt(dom.childAt(element1, [3]), 0, 0);
          morphs[5] = dom.createMorphAt(element0, 5, 5);
          return morphs;
        },
        statements: [["attribute", "class", ["concat", ["mpf-magellan-title ", ["subexpr", "if", [["get", "active", ["loc", [null, [15, 43], [15, 49]]], 0, 0, 0, 0], "active"], [], ["loc", [null, [15, 38], [15, 60]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["content", "titel", ["loc", [null, [15, 62], [15, 71]]], 0, 0, 0, 0], ["inline", "mpf-input-tooltip", [], ["required", ["subexpr", "@mut", [["get", "required", ["loc", [null, [15, 100], [15, 108]]], 0, 0, 0, 0]], [], [], 0, 0], "kennzahlen", ["subexpr", "@mut", [["get", "kennzahlen", ["loc", [null, [15, 120], [15, 130]]], 0, 0, 0, 0]], [], [], 0, 0], "hiebsatz", ["subexpr", "@mut", [["get", "hiebsatz", ["loc", [null, [15, 140], [15, 148]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 71], [15, 150]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", ["subexpr", "@mut", [["get", "area", ["loc", [null, [15, 174], [15, 178]]], 0, 0, 0, 0]], [], [], 0, 0], "name", ["subexpr", "@mut", [["get", "name", ["loc", [null, [15, 184], [15, 188]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [15, 150], [15, 190]]], 0, 0], ["content", "subtitel", ["loc", [null, [16, 47], [16, 59]]], 0, 0, 0, 0], ["content", "yield", ["loc", [null, [19, 6], [19, 15]]], 0, 0, 0, 0]],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 24,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/components/mpf-magellan-section/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "sticky", ["loc", [null, [2, 8], [2, 14]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [2, 2], [22, 9]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});