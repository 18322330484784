define('mpforst-ember/app/pods/components/x-delayed/component', ['exports', 'mpforst-ember/app/modules/delayed-queue'], function (exports, _delayedQueue) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    displayElement: false,
    didInsertElement: function didInsertElement() {
      var _this = this;

      (0, _delayedQueue.default)(function () {
        Ember.run.later(function () {
          if (_this.isDestorying || _this.isDestroyed) {
            return;
          }
          _this.set('displayElement', true);
        });
      });
    }
  });
});