define("mpforst-ember/app/pods/grunddaten-bestand-neu/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadCrumb: "Neu",
    edit: true,
    new: true
  });
});