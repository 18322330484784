define('mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-map/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  // Polyfill
  if (!window.location.origin) {
    window.location.origin = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port : '');
  }

  var instances = {};

  window.addEventListener('message', function (event) {
    if (event.origin !== window.location.origin) {
      return;
    }
    // search component

    var _loop = function _loop(key) {
      if (instances[key].$()[0].contentWindow === event.source) {
        Ember.run(function () {
          instances[key].send('onMessage', JSON.parse(event.data));
        });
        return {
          v: void 0
        };
      }
    };

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = Object.keys(instances)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var key = _step.value;

        var _ret = _loop(key);

        if ((typeof _ret === 'undefined' ? 'undefined' : _typeof(_ret)) === "object") return _ret.v;
      }
      // component not found :(
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  });

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  exports.default = Ember.Component.extend({
    tagName: 'iframe',
    attributeBindings: 'src',
    src: 'map/template.html',
    willInsertElement: function willInsertElement() {
      instances[this.get('elementId')] = this;
    },
    willDestroyElement: function willDestroyElement() {
      delete instances[this.get('elementId')];
    },

    actions: {
      onRequestSetPosition: function onRequestSetPosition() {
        this.send('postMessage', 'setPosition', this.getProperties('lat', 'lng'));
      },
      onSetPosition: function onSetPosition(data) {
        if (!this.get('edit')) {
          return;
        }
        this.setProperties(data);
        this.send('postMessage', 'setPosition', data);
      },
      onMessage: function onMessage(data) {
        this.send('on' + capitalizeFirstLetter(data.command), data.data);
      },
      postMessage: function postMessage(command, data) {
        this.$()[0].contentWindow.postMessage(JSON.stringify({
          command: command,
          data: data
        }), window.location.origin);
      }
    }
  });
});