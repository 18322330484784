define('mpforst-ember/app/pods/grunddaten-betrieb/anzeigen/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    queryParams: {
      s: {
        replace: true
      },
      assistent: {}
    },
    model: function model() {
      return this.modelFor('grunddaten-betrieb');
    }
  });
});