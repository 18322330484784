define('mpforst-ember/app/pods/components/mpf-popup/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _extends = Object.assign || function (target) {
    for (var i = 1; i < arguments.length; i++) {
      var source = arguments[i];

      for (var key in source) {
        if (Object.prototype.hasOwnProperty.call(source, key)) {
          target[key] = source[key];
        }
      }
    }

    return target;
  };

  var Component = Ember.Component,
      observer = Ember.observer;
  exports.default = Component.extend({
    classNames: ["editor-popup"],
    title: Ember.computed.alias('model.title'),
    width: Ember.computed.alias('model.w'),
    height: Ember.computed.alias('model.h'),
    left: Ember.computed(function () {
      return Ember.$(window).width() * 0.5 - (this.get('width') || 512) * 0.5;
    }),
    top: Ember.computed(function () {
      return Ember.$(window).height() * 0.5 - (this.get('height') || 512) * 0.5;
    }),
    obsTitel: observer('title', function () {
      this.$().dialog('option', 'title', this.get('title'));
    }),
    obsWidth: observer('width', function () {
      Ember.run.once(this, this._update);
    }),
    obsHeight: observer('height', function () {
      Ember.run.once(this, this._update);
    }),
    obsLeft: observer('left', function () {
      Ember.run.once(this, this._update);
    }),
    obsTop: observer('top', function () {
      Ember.run.once(this, this._update);
    }),
    _update: function _update() {
      return; // don't update

      var left = this.get('left') + 'px';
      var top = this.get('top') + 'px';
      var width = this.get('width') + 'px';
      var height = this.get('height') + 'px';
      this.$().parent().css({
        left: left,
        top: top,
        width: width,
        height: height
      });
    },
    willDestroyElement: function willDestroyElement() {
      this.$().dialog("destroy");
      //somehow need to clean .. html
      this.$().html('');
    },
    didInsertElement: function didInsertElement() {
      var left = this.get('left') + 'px';
      var top = this.get('top') + 'px';
      var width = (this.get('width') || 512) + 'px';
      var height = (this.get('height') || 512) + 'px';
      var self = this;
      this.$().dialog({
        title: this.get('title'),
        width: this.get('width') || 512,
        height: this.get('height') || 512,
        closeOnEscape: false,
        create: function create(event) {
          Ember.$(event.target).parent().css('position', 'fixed');
          Ember.$(event.target).parent().css({
            left: left,
            top: top
          });
          Ember.$(event.target).find('iframe').on('load', function (e) {
            e.target.contentDocument.addEventListener("click", function () {
              //bring to front
              self.$().dialog("moveToTop");
            });
          });
          Ember.run.next(function () {
            Ember.$(event.target).parent().css({
              left: left,
              top: top,
              width: width,
              height: height
            });
          });
        },
        resizeStart: function resizeStart(event) {
          Ember.$(event.target).parent().css('position', 'fixed');
        },
        resizeStop: function resizeStop(event, ui) {
          Ember.$(event.target).parent().css('position', 'fixed');
          self.setProperties(_extends({}, ui.position, ui.size));
        },
        dragStop: function dragStop(event, ui) {
          self.setProperties(_extends({}, ui.position, ui.size));
        },
        beforeClose: function beforeClose(event, ui) {
          event.preventDefault();
          self.sendAction('close');
        }
      });
    }
  });
});