define('mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    latLngChanged: false,
    _latLngChanged: function _latLngChanged() {
      var _this = this;

      var props = Ember.getProperties(this.get('model'), 'lat', 'lng');
      if (!props.lat || !props.lng) {
        return;
      }
      // wuchsgebiet laden
      Ember.$.ajax({
        method: 'POST',
        url: 'api/teilbetrieb/wuchsHoehe',
        data: JSON.stringify(props),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function (data) {
        Ember.run(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }
          Ember.setProperties(_this.get('model'), {
            mit_see_hoehe: data.hoehe,
            wuchsgebiet_id: data.wuchsgebiet
          });
          _this.set('hideForNew', false);
        });
      });

      // bundesland bezirk laden
      Ember.$.ajax({
        method: 'POST',
        url: 'api/teilbetrieb/bundeslandBezirk',
        data: JSON.stringify(props),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function (data) {
        Ember.run(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }
          Ember.setProperties(_this.get('model'), {
            bundesland: data.bundesland,
            bezirk: data.bezirk
          });
        });
      });

      // pefc laden
      Ember.$.ajax({
        method: 'POST',
        url: 'api/teilbetrieb/pefc',
        data: JSON.stringify(props),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function (data) {
        Ember.run(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }
          Ember.setProperties(_this.get('model'), {
            pefc: data.name
          });
        });
      });
    },
    latLngChangedObserver: Ember.observer('model.lat', 'model.lng', function () {
      Ember.run.once(this, this._latLngChanged);
    }),
    initLatLng: Ember.on('init', function () {
      Ember.run.once(this, this._latLngChanged);
    }),
    isMapVisible: false,
    actions: {
      mapOpen: function mapOpen() {
        this.setProperties({
          isMapVisible: true,
          lat_tmp: this.get('model.lat'),
          lng_tmp: this.get('model.lng')
        });
      },
      mapSave: function mapSave() {
        this.setProperties({
          'model.lat': this.get('lat_tmp'),
          'model.lng': this.get('lng_tmp'),
          isMapVisible: false
        });
      },
      mapClose: function mapClose() {
        this.set('isMapVisible', false);
      }
    }
  });
});