define('mpforst-ember/app/pods/components/mpf-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    type: 'text',
    emptyValue: '-',
    renderElement: false,
    subComponentName: Ember.computed('type', function () {
      return 'mpf-input/x-' + this.get('type');
    })
  });
});