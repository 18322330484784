define('mpforst-ember/app/pods/assistent-export-setup/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var SelectedObject = Ember.Object.extend({

    selectedItems: Ember.computed('children.@each.triChecked', function () {
      this._debug("update selectedItems");
      var c = this.get('children');
      if (c) {
        return c.filterBy('triChecked', 1);
      }
    }), //Ember.computed.filterBy('children', 'triChecked', 1),
    anySelectedItems: Ember.computed.filterBy('children', 'triChecked', 2),

    init: function init() {
      this._super.apply(this, arguments);

      this._triChecked = 1;
      this._debug = this._debug.bind(this);
    },
    _debug: function _debug(text) {
      //console.log("DEBUG: ", this.get('name') + " ", ...arguments);
    },


    _getTriChecked: function _getTriChecked() {
      if (this.get('allItemSelected')) {
        this._debug("allItemSelected == true");
        return 1;
      }
      if (this.get('anyItemSelected')) {
        this._debug("anyItemSelected == true");
        return 2;
      }
      if (!this.get('allItemSelected') && !this.get('anyItemSelected') && this.get('children.length')) {
        this._debug("anyItemSelected == false && allItemSelected == false");
        if (this._triChecked === 1) {
          this._debug("force state 2");
          //this._triChecked = 2;
          return 2;
        }
      }
      this._debug("return state ", this._triChecked);
      /*if (this._triChecked === 1) {
        this._triChecked = 2;
        this._debug("change state to ", this._triChecked);
      }*/
      return this._triChecked;
    },

    triChecked: Ember.computed('allItemSelected', 'anyItemSelected', 'children.[]', {
      get: function get(v) {
        this._debug("get triChecked");
        return this._getTriChecked();
      },
      set: function set(k, v) {
        var _this = this;

        this._debug("set to ", v);
        this._triChecked = v;
        Ember.run.next(function () {
          var c = _this.get('children');
          //das folgende macht alles kaputt
          if (v === 1 && c) {
            c.setEach('triChecked', 1);
          } else if (v === 2 && (!c || !c.length)) {
            _this._triChecked = 0;
          } else if ((v === 2 || v === 0) && c) {
            c.setEach('triChecked', 0);
          }
          _this.notifyPropertyChange('triChecked');
        });
        return v;
      }
    }),

    anyItemSelected: Ember.computed('selectedItems.[]', 'anySelectedItems.[]', 'children.[]', function () {
      this._debug("Update anyItemSelected");
      return this.get('children.length') > 0 && (this.get('anySelectedItems.length') > 0 || this.get('selectedItems.length') > 0);
    }),
    allItemSelected: Ember.computed('selectedItems.[]', 'children.[]', function () {
      this._debug("Update allItemSelected");
      return this.get('children.length') > 0 && this.get('selectedItems.length') === this.get('children.length');
    })
  });

  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.$.getJSON('api/betrieb').then(function (x) {
        var res = {};
        res.children = Ember.makeArray(x.map(function (y) {
          return SelectedObject.create({
            level: 'Betrieb',
            id: y.id,
            name: y.bezeichnung,
            children: Ember.makeArray(y.teilbetriebe.map(function (z) {
              return SelectedObject.create({
                level: 'Teilbetrieb',
                id: z.id,
                name: z.bezeichnung,
                children: Ember.makeArray(z.bestaende.map(function (aa) {
                  return SelectedObject.create({
                    level: 'Bestand',
                    id: aa.id,
                    name: aa.bezeichnung
                    // children... need to create api for this
                  });
                }))
              });
            }))
          });
        }));
        res.type = 'excel';
        return res;
      });
    }
  });
});