define("mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 12,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        dom.setAttribute(el2, "class", "mpf-magellan-title");
        var el3 = dom.createTextNode("Verjüngung");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        dom.setAttribute(el2, "class", "mpf-magellan-title");
        var el3 = dom.createTextNode("Überhälter");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        dom.setAttribute(el2, "class", "mpf-magellan-title");
        var el3 = dom.createTextNode("Zwischenbestand");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("h5");
        dom.setAttribute(el2, "class", "mpf-magellan-title");
        var el3 = dom.createTextNode("Totholz");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var morphs = new Array(5);
        morphs[0] = dom.createMorphAt(element0, 1, 1);
        morphs[1] = dom.createMorphAt(element0, 5, 5);
        morphs[2] = dom.createMorphAt(element0, 9, 9);
        morphs[3] = dom.createMorphAt(element0, 13, 13);
        morphs[4] = dom.createMorphAt(element0, 17, 17);
        return morphs;
      },
      statements: [["inline", "grunddaten-bestand/components/x-beschreibung/x-beschreibung", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [2, 72], [2, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [2, 83], [2, 87]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 4], [2, 89]]], 0, 0], ["inline", "grunddaten-bestand/components/x-beschreibung/x-verjuengung", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 71], [4, 76]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [4, 82], [4, 86]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [4, 4], [4, 88]]], 0, 0], ["inline", "grunddaten-bestand/components/x-beschreibung/x-ueberhaelter", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [6, 72], [6, 77]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [6, 83], [6, 87]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [6, 4], [6, 89]]], 0, 0], ["inline", "grunddaten-bestand/components/x-beschreibung/x-zwischenbestand", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [8, 75], [8, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [8, 86], [8, 90]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [8, 4], [8, 92]]], 0, 0], ["inline", "grunddaten-bestand/components/x-beschreibung/x-totholz", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [10, 67], [10, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [10, 78], [10, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [10, 4], [10, 84]]], 0, 0]],
      locals: [],
      templates: []
    };
  }());
});