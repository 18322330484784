define("mpforst-ember/app/pods/grunddaten-bestand/bearbeiten/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadCrumb: "Bearbeiten",
    edit: true
  });
});