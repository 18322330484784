define('mpforst-ember/app/pods/components/editor-wysiwyg/component', ['exports', 'ember-mobiledoc-editor/utils/create-component-card', 'ember-mobiledoc-editor/utils/create-component-atom'], function (exports, _createComponentCard, _createComponentAtom) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var ignoreTags = ['DIV', 'P'];
  var markupTag = ['B', 'CODE', 'EM', 'I', 'S', 'STRONG', 'SUB', 'SUP', 'U'];
  var sectionTag = ['ASIDE', 'BLOCKQUOTE', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'];
  var textTags = ['SPAN', 'PRE', 'BR', 'UL', 'MARK', 'SMALL', 'DEL', 'INS'].concat(markupTag, sectionTag);

  var first = true;

  function contentTextOnly(node, builder, env) {
    var addSection = env.addSection,
        addMarkerable = env.addMarkerable,
        nodeFinished = env.nodeFinished;


    if (node.nodeType !== 1) {
      if (node.nodeType === 3) {
        if (node.previousSibling && node.previousSibling.data == "EndFragment") {
          //ignore it, hack for WORD
          nodeFinished();
          return;
        }
        var _t = (node.data || '').replace(/\0/g, '');
        var _s = builder.createMarker(_t);
        addMarkerable(_s);
        nodeFinished();
      }
      return;
    }

    var t = (node.textContent || '').replace(/\0/g, '');

    node.setAttribute('style', null);
    node.setAttribute('class', null);
    if (node.tagName === 'A') {
      var _payload = {
        typ: 2,
        extern: node.href,
        caption: t
      };
      if (t.trim() != '') {
        var _s2 = builder.createAtom('editor-link-atom', '', _payload);
        addMarkerable(_s2);
      }
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = node.getElementsByTagName('img')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var img = _step.value;

          contentTextOnly(img, builder, env);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      nodeFinished();
      return;
    }
    if (node.tagName === 'IMG') {
      var _payload2 = {
        src: node.src
      };
      var _s3 = builder.createCardSection('editor-image-card', _payload2);
      addSection(_s3);
      nodeFinished();
      return;
    }
    if (node.tagName === 'O:P' && !t.trim()) {
      var _s4 = builder.createCardSection('editor-br-card');
      addSection(_s4);
      nodeFinished();
      return;
    }

    if (textTags.includes(node.tagName)) {
      // onyl TEXT !
      if (markupTag.includes(node.tagName)) {
        var b = builder.createMarkup(node.tagName);
        var _s5 = builder.createMarker(t, [b]);
        addMarkerable(_s5);
      } else if (sectionTag.includes(node.tagName)) {
        var _b = builder.createMarker(t);
        var _s6 = builder.createMarkupSection(node.tagName, [_b]);
        addSection(_s6);
      } else {
        var _s7 = builder.createMarker(t);
        addMarkerable(_s7);
      }
      nodeFinished();
      return;
    }

    if (ignoreTags.includes(node.tagName)) {
      // ignore this
      return;
    }

    // all other cases:
    var payload = {
      html: node.outerHTML
    };
    var s = builder.createCardSection('editor-html-card', payload);
    addSection(s);
    nodeFinished();
  }

  exports.default = Ember.Component.extend({
    tagName: '',
    cards: Ember.computed(function () {
      return [(0, _createComponentCard.default)('editor-image-card'), (0, _createComponentCard.default)('editor-html-card'), (0, _createComponentCard.default)('editor-br-card'), (0, _createComponentCard.default)('editor-latex-card')];
    }),
    atoms: Ember.computed(function () {
      return [(0, _createComponentAtom.default)('editor-link-atom')];
    }),
    parserPlugins: Ember.computed(function () {
      return [contentTextOnly];
    }),
    _mobiledoc: Ember.computed('mobiledoc', function () {
      var m = this.get('mobiledoc');
      if (m) {
        if (!m.version) {
          return null;
        }
      }
      return m;
    })
  });
});