define('mpforst-ember/app/pods/components/editor-link-atom/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function inIframe() {
    try {
      return window.self !== window.top;
    } catch (e) {
      return true;
    }
  }

  exports.default = Ember.Component.extend({
    tagName: 'span',
    payload: null,
    popups: Ember.inject.service('popups'),
    intern: Ember.computed.alias('payload.intern'),
    extern: Ember.computed.alias('payload.extern'),
    tooltip: Ember.computed.alias('payload.tooltip'),
    typ: Ember.computed.alias('payload.typ'),
    caption: Ember.computed.alias('payload.caption'),
    dropdownTyp: [{
      value: 1,
      caption: 'intern'
    }, {
      value: 2,
      caption: 'extern'
    }, {
      value: 3,
      caption: 'tooltip'
    }],
    showHref: Ember.computed('typ', function () {
      return this.get('typ') == 2;
    }),
    showTooltip: Ember.computed('typ', function () {
      return this.get('typ') == 3;
    }),
    didInsertElement: function didInsertElement() {
      var _this = this;

      if (this.get('editor')) {
        //load intern pages
        Ember.$.getJSON('api/page').then(function (pages) {
          if (!_this.isDestroyed) {
            _this.set('dropdownIntern', pages.map(function (x) {
              return {
                value: x.id,
                caption: '[' + x.id + '] ' + x.name
              };
            }));
          }
        });
        Ember.$.getJSON('api/tooltips').then(function (tooltips) {
          if (!_this.isDestroyed) {
            _this.set('dropdownTooltip', tooltips.sort(function (a, b) {
              return a.id - b.id;
            }).map(function (x) {
              return {
                value: x.area + '/' + x.name,
                caption: '[' + x.id + '] ' + x.area + ' / ' + x.name
              };
            }));
          }
        });
      }
    },

    dropdownIntern: null,
    dropdownTooltip: null,
    actions: {
      close: function close() {
        this.sendAction('saveAtom', this.get('caption'), {
          typ: this.get('typ'),
          caption: this.get('caption'),
          extern: this.get('extern'),
          intern: this.get('intern'),
          tooltip: this.get('tooltip')
        });
        this.set('showEditor', false);
        this.get('editor').enableEditing();
      },
      open: function open() {
        this.get('editor').disableEditing(); //fixes stupid bugs..
        this.set('showEditor', true);
      },
      openHelp: function openHelp(e) {
        var to = (this.get('tooltip') || '').split('/');
        if (!inIframe()) {
          this.get('popups').openHelp('Quickinfo ' + to[0], 'hilfe-tooltips/' + to[0] + '/' + to[1]);
          e.preventDefault();
        }
      }
    }
  });
});