define('mpforst-ember/app/pods/components/mpf-input/x-date/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    edit: true,
    value: null,
    valueFormated: Ember.computed('value', function () {
      var s = (this.value || '').split('-');
      return [s[2].slice(0, 2), s[1].slice(0, 2), s[0].slice(0, 4)].join('.');
    })
  });
});