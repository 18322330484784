define('mpforst-ember/app/pods/error/controller', ['exports', 'mpforst-ember/app/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadCrumb: "Error",
    loading: true,
    testing: Ember.computed(function () {
      return _environment.default.environment === "testing";
    }),
    actions: {
      actionDidInsertElement: function actionDidInsertElement() {
        var _this = this;

        if (!window.errorLoading) {
          Ember.run.next(this, function () {
            // create ajax request
            Ember.$.getJSON('api/login/check').then(function (data) {
              Ember.run.next(_this, function () {
                if (data.okay) {
                  _this.set('session', false);
                  _this.set('loading', false);
                } else {
                  _this.set('session', true);
                  _this.set('loading', false);
                }
              });
            }, function () {
              Ember.run.next(function () {
                _this.set('session', false);
                _this.set('loading', false);
              });
            });
          });
        } else {
          var timer = window.setInterval(function () {
            if (window.errorLoading) {
              return;
            }
            window.clearInterval(timer);
            // not loading !
            _this.send('actionDidInsertElement');
          }, 1000);
        }
      }
    }
  });
});