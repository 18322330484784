define('mpforst-ember/app/mobiledoc-titleize/helper', ['exports', 'ember-mobiledoc-editor/helpers/mobiledoc-titleize'], function (exports, _mobiledocTitleize) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _mobiledocTitleize.default;
    }
  });
  Object.defineProperty(exports, 'mobiledocTitleize', {
    enumerable: true,
    get: function () {
      return _mobiledocTitleize.mobiledocTitleize;
    }
  });
});