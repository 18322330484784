define('mpforst-ember/app/pods/assistent-wzpx/success/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return {
        betrieb: parseInt(params.betrieb),
        teilbetrieb: parseInt(params.teilbetrieb),
        new: parseInt(params.new),
        mod: parseInt(params.mod),
        old: parseInt(params.old)
      };
    },
    afterModel: function afterModel(model) {
      window.frameElement.actions.saved(model.teilbetrieb);
    }
  });
});