define('mpforst-ember/app/resolver', ['exports', 'ember-resolver'], function (exports, _emberResolver) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  String.prototype.reverse = function () {
    return this.split('').reverse().join('');
  };

  String.prototype.erase = function (startIndex, count) {
    return this.substr(0, startIndex) + this.substr(startIndex + count);
  };

  exports.default = _emberResolver.default.extend({
    removeBeforeLoading: function removeBeforeLoading(parsedName) {
      if (parsedName.fullNameWithoutType.match('/loading$') == null && parsedName.fullNameWithoutType.match('/error$') == null) {
        return null;
      }

      var slIndex1 = parsedName.fullNameWithoutType.reverse().indexOf('/');
      var slIndex2 = parsedName.fullNameWithoutType.reverse().indexOf('/', slIndex1 + 1);
      if (slIndex2 < 0) {
        slIndex2 = parsedName.fullNameWithoutType.length;
      }
      if (slIndex1 >= 0) {
        var newName = parsedName.fullNameWithoutType.reverse().erase(slIndex1, slIndex2 - slIndex1).reverse();
        return Ember.$.extend(parsedName, {
          fullNameWithoutType: newName
        });
      }
      return null;
    },
    customResolve: function customResolve(parsedName, resolveFunc) {
      var resolved = resolveFunc(parsedName);
      if (resolved) {
        return resolved;
      }

      //remove substate
      var newName = parsedName.fullNameWithoutType.replace('-loading', '/loading').replace('-error', '/error');
      parsedName = Ember.$.extend(parsedName, {
        fullNameWithoutType: newName
      });
      resolved = resolveFunc(parsedName);
      if (resolved) {
        return resolved;
      }
      //try loading
      var newParseName = this.removeBeforeLoading(parsedName);
      if (newParseName) {
        return this.resolve(newParseName, resolveFunc);
      }

      return Ember.TEMPLATES['not_found'];
    },
    resolveTemplate: function resolveTemplate(parsedName) {
      return this.customResolve(parsedName, this._super.bind(this));
    },
    resolveRoute: function resolveRoute(parsedName) {
      return this.customResolve(parsedName, this._super.bind(this));
    },
    resolveController: function resolveController(parsedName) {
      return this.customResolve(parsedName, this._super.bind(this));
    }
  });
});