define('mpforst-ember/app/pods/grunddaten-betrieb-neu/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'grunddaten-betrieb.anzeigen',
    model: function model() {
      return Ember.Object.create({});
    },
    afterModel: function afterModel() {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('s', 0);
    }
  });
});