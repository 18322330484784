define("mpforst-ember/app/pods/components/mpf-tooltip-edit/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 7,
                  "column": 4
                },
                "end": {
                  "line": 11,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "toolbar");
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 1, 1);
              return morphs;
            },
            statements: [["inline", "mobiledoc-toolbar", [], ["editor", ["subexpr", "@mut", [["get", "editor", ["loc", [null, [9, 35], [9, 41]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [9, 8], [9, 43]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 6,
                "column": 2
              },
              "end": {
                "line": 12,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "s.sticky", [], ["below", "#magellan-header"], 0, null, ["loc", [null, [7, 4], [11, 17]]]]],
          locals: ["editor"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 5,
              "column": 0
            },
            "end": {
              "line": 13,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "editor-wysiwyg", [], ["mobiledoc", ["subexpr", "readonly", [["get", "model_edited.short", ["loc", [null, [6, 40], [6, 58]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 30], [6, 59]]], 0, 0], "on-change", ["subexpr", "action", [["subexpr", "mut", [["get", "model_edited.short", ["loc", [null, [6, 83], [6, 101]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 78], [6, 102]]], 0, 0]], [], ["loc", [null, [6, 70], [6, 103]]], 0, 0]], 0, null, ["loc", [null, [6, 2], [12, 21]]]]],
        locals: ["s"],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 20,
                    "column": 8
                  },
                  "end": {
                    "line": 48,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "mobiledoc-toolbar__control");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "type", "button");
                dom.setAttribute(el2, "title", "Link");
                dom.setAttribute(el2, "class", "mobiledoc-toolbar__button secondary radius tiny button");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "fa fa-link");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "mobiledoc-toolbar__control");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "type", "button");
                dom.setAttribute(el2, "title", "Link");
                dom.setAttribute(el2, "class", "mobiledoc-toolbar__button secondary radius tiny button");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "fa fa-file-image-o");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n          ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("li");
                dom.setAttribute(el1, "class", "mobildoc-toolbard__control");
                var el2 = dom.createTextNode("\n            ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "type", "button");
                dom.setAttribute(el2, "title", "Html");
                dom.setAttribute(el2, "class", "mobiledoc-toolbar__button secondary radius tiny button");
                var el3 = dom.createTextNode("\n              ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "fa fa-file-code-o");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element1 = dom.childAt(fragment, [1, 1]);
                var element2 = dom.childAt(fragment, [3, 1]);
                var element3 = dom.childAt(fragment, [5, 1]);
                var morphs = new Array(3);
                morphs[0] = dom.createElementMorph(element1);
                morphs[1] = dom.createElementMorph(element2);
                morphs[2] = dom.createElementMorph(element3);
                return morphs;
              },
              statements: [["element", "action", ["insertAtom", ["get", "editor", ["loc", [null, [26, 36], [26, 42]]], 0, 0, 0, 0], "editor-link-atom"], [], ["loc", [null, [26, 14], [26, 63]]], 0, 0], ["element", "action", ["insertCard", ["get", "editor", ["loc", [null, [35, 36], [35, 42]]], 0, 0, 0, 0], "editor-image-card"], [], ["loc", [null, [35, 14], [35, 64]]], 0, 0], ["element", "action", ["insertCard", ["get", "editor", ["loc", [null, [44, 36], [44, 42]]], 0, 0, 0, 0], "editor-html-card"], [], ["loc", [null, [44, 14], [44, 63]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 18,
                  "column": 4
                },
                "end": {
                  "line": 50,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "class", "toolbar");
              var el2 = dom.createElement("div");
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 1, 1);
              return morphs;
            },
            statements: [["block", "mobiledoc-toolbar", [], ["editor", ["subexpr", "@mut", [["get", "editor", ["loc", [null, [20, 36], [20, 42]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [20, 8], [48, 30]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 17,
                "column": 2
              },
              "end": {
                "line": 51,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "s.sticky", [], ["below", "#magellan-header"], 0, null, ["loc", [null, [18, 4], [50, 17]]]]],
          locals: ["editor"],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 16,
              "column": 0
            },
            "end": {
              "line": 52,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "editor-wysiwyg", [], ["mobiledoc", ["subexpr", "readonly", [["get", "model_edited.long", ["loc", [null, [17, 40], [17, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 30], [17, 58]]], 0, 0], "on-change", ["subexpr", "action", [["subexpr", "mut", [["get", "model_edited.long", ["loc", [null, [17, 82], [17, 99]]], 0, 0, 0, 0]], [], ["loc", [null, [17, 77], [17, 100]]], 0, 0]], [], ["loc", [null, [17, 69], [17, 101]]], 0, 0]], 0, null, ["loc", [null, [17, 2], [51, 21]]]]],
        locals: ["s"],
        templates: [child0]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 70,
                "column": 6
              },
              "end": {
                "line": 76,
                "column": 6
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
          },
          isEmpty: false,
          arity: 1,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("tr");
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n        ");
            dom.appendChild(el1, el2);
            var el2 = dom.createElement("td");
            var el3 = dom.createComment("");
            dom.appendChild(el2, el3);
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n      ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(dom.childAt(element0, [1]), 0, 0);
            morphs[1] = dom.createMorphAt(dom.childAt(element0, [3]), 0, 0);
            morphs[2] = dom.createMorphAt(dom.childAt(element0, [5]), 0, 0);
            return morphs;
          },
          statements: [["content", "a.value", ["loc", [null, [72, 12], [72, 23]]], 0, 0, 0, 0], ["content", "a.caption", ["loc", [null, [73, 12], [73, 25]]], 0, 0, 0, 0], ["inline", "textarea", [], ["value", ["subexpr", "@mut", [["get", "a.text.text", ["loc", [null, [74, 29], [74, 40]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [74, 12], [74, 42]]], 0, 0]],
          locals: ["a"],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 56,
              "column": 0
            },
            "end": {
              "line": 79,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("table");
          dom.setAttribute(el1, "class", "table-no-tbody-padding custom-table tooltips-edit-textarea-fix");
          dom.setAttribute(el1, "style", "width: 100%;");
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("col");
          dom.setAttribute(el2, "style", "width: 4em");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("col");
          dom.setAttribute(el2, "style", "width: 15em");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("col");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("thead");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("tr");
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createTextNode("Wert");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createTextNode("Bezeichnung");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n        ");
          dom.appendChild(el3, el4);
          var el4 = dom.createElement("th");
          var el5 = dom.createTextNode("Beschreibung");
          dom.appendChild(el4, el5);
          dom.appendChild(el3, el4);
          var el4 = dom.createTextNode("\n      ");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("tbody");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 9]), 1, 1);
          return morphs;
        },
        statements: [["block", "each", [["get", "auswahlen", ["loc", [null, [70, 14], [70, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [70, 6], [76, 15]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 79,
              "column": 0
            },
            "end": {
              "line": 82,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("br");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 84,
            "column": 83
          }
        },
        "moduleName": "mpforst-ember/app/pods/components/mpf-tooltip-edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("Bezeichnung");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("Kurze Hilfe");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("Detaillierte Hilfe");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("hr");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("b");
        var el2 = dom.createTextNode("Auswahlmöglichkeiten");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("button");
        dom.setAttribute(el1, "class", "radius tiny right");
        var el2 = dom.createTextNode("Speichern");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element4 = dom.childAt(fragment, [22]);
        var morphs = new Array(6);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 8, 8, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 13, 13, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 18, 18, contextualElement);
        morphs[4] = dom.createMorphAt(fragment, 20, 20, contextualElement);
        morphs[5] = dom.createElementMorph(element4);
        return morphs;
      },
      statements: [["inline", "input", [], ["type", ["subexpr", "@mut", [["get", "text", ["loc", [null, [2, 13], [2, 17]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model_edited.subject", ["loc", [null, [2, 24], [2, 44]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [2, 0], [2, 46]]], 0, 0], ["block", "sticky-container", [], [], 0, null, ["loc", [null, [5, 0], [13, 21]]]], ["block", "sticky-container", [], [], 1, null, ["loc", [null, [16, 0], [52, 21]]]], ["inline", "mpf-input", [], ["type", "select", "edit", true, "value", ["subexpr", "@mut", [["get", "model_edited.auswahlen", ["loc", [null, [55, 42], [55, 64]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/urls", "displayEmptyItem", true], ["loc", [null, [55, 0], [55, 116]]], 0, 0], ["block", "if", [["get", "model_edited.auswahlen", ["loc", [null, [56, 6], [56, 28]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [56, 0], [82, 7]]]], ["element", "action", ["save", ["get", "model_edited", ["loc", [null, [84, 50], [84, 62]]], 0, 0, 0, 0]], [], ["loc", [null, [84, 34], [84, 64]]], 0, 0]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});