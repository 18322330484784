define('mpforst-ember/app/pods/assistent-wzpx-editor/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    afterModel: function afterModel(model, transition) {
      if (model.bestand && model.bestand.length > 50) {
        //nur wenn man versucht auf den editor zuzugreifen
        if (transition.targetName === 'assistent-wzpx-editor.index') {
          this.replaceWith('assistent-wzpx-summary');
        }
      }
    }
  });
});