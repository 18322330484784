define('mpforst-ember/app/pods/grunddaten-teilbetrieb/controller', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Controller.extend({
        breadCrumb: Ember.computed('model.bezeichnung', function () {
            return 'Teilbetrieb: ' + this.get('model.bezeichnung');
        }),

        classMap: 'map-mode-min',

        actions: {
            maxMap: function maxMap() {
                this.set('classMap', 'map-mode-max');
                document.body.style.overflow = 'hidden';
            },
            minMap: function minMap() {
                this.set('classMap', 'map-mode-min');
                document.body.style.overflow = '';
            }
        }
    });
});