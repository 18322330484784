define('mpforst-ember/app/pods/assistent-wzpx-editor/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    saving: false,
    _lastUpdate: null,
    _doAgain: false,

    errors: Ember.computed('model', 'model.version', function () {
      var model = this.get('model');
      return !!model.bestand.find(function (x) {
        return x.error && x.error.length;
      });
    }),

    actions: {
      actionChange: function actionChange() {
        var _this = this;

        var model = this.get('model');
        if (!this._lastUpdate) {
          this.set('saving', true);
          this._lastUpdate = Ember.$.ajax({
            method: 'PUT',
            url: 'api/entwurfWzpx/' + model.get('id'),
            data: JSON.stringify(model),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
          }).then(function (data) {
            Ember.run.later(function () {
              _this._lastUpdate = null;
              if (_this._doAgain) {
                _this._doAgain = false;
                Ember.run(function () {
                  _this.actions.actionChange.apply(_this);
                });
              } else {
                Ember.run(function () {
                  _this.set('saving', false);
                  var model = _this.get('model');
                  var bestandIndexClient = 0;
                  var bestandIndexServer = 0;
                  for (; bestandIndexClient < model.bestand.length; ++bestandIndexClient) {
                    var bestandClient = model.bestand[bestandIndexClient];
                    var bestandServer = data.bestand[bestandIndexServer];

                    bestandClient.error.clear();
                    // copy bestand errors
                    if (bestandClient.wzpx.length === 0) {
                      // error message anzeigen
                      bestandClient.error.addObject({
                        message: 'Ein Bestand benötigt mindestens eine WZPX-Zeile'
                      });
                      //skipped serverside
                      continue;
                    }
                    // check if name exists
                    var name = bestandClient.bestand_name;
                    if (name === null || name === '') {
                      bestandClient.error.addObject({
                        message: 'Name des Bestandes wird benötigt'
                      });
                      //skipped serverside
                      continue;
                    }
                    if (model.bestand.find(function (e) {
                      return e !== bestandClient && e.bestand_name && name && e.bestand_name.toUpperCase() === name.toUpperCase();
                    })) {
                      bestandClient.error.addObject({
                        message: 'Name des Bestandes wird bereits benutzt'
                      });
                      //skipped serverside
                      continue;
                    }

                    // fehler vom Bestand kopieren
                    bestandClient.error.addObjects(bestandServer.error);

                    // copy wzpx errors
                    for (var wi = 0; wi < bestandClient.wzpx.length; ++wi) {
                      // neue werte setzen
                      bestandClient.wzpx[wi].error.clear();
                      bestandClient.wzpx[wi].error.addObjects(bestandServer.wzpx[wi].error);
                    }

                    //nächsten serverseitigen Bestand
                    ++bestandIndexServer;
                  }
                  model.set('version', (model.get('version') || 0) + 1); // work around for computed with @each.@each
                  _this.set('model', model);
                });
              }
            }, 1500);
          }, function () {
            Ember.$('#notify').notify({
              type: 'alert',
              closable: false,
              message: {
                text: 'Fehler beim Speichern des Entwurfes'
              }
            }).show();
            _this._lastUpdate = null;
            _this._doAgain = false;
            Ember.run(function () {
              _this.set('saving', false);
            });
          });
        } else {
          this._doAgain = true;
        }
      }
    }
  });
});