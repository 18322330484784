define('mpforst-ember/app/pods/components/mpf-actionbar/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    actions: {
      sendAction: function sendAction() {
        this.sendAction.apply(this, ['action'].concat(Array.prototype.slice.call(arguments)));
      }
    }
  });
});