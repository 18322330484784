define("mpforst-ember/app/pods/debug/loading-test/route", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            var delayedLoad = Ember.$.Deferred();
            setTimeout(function () {
                delayedLoad.resolve("done");
            }, 10000);
            return delayedLoad.promise();
        },
        actions: {
            errorTest: function errorTest() {
                throw new Error("Error Test");
            }
        }
    });
});