define('mpforst-ember/app/pods/assistent-wzpx-result/index/components/x-table/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    _jqueryScrollEvent: null,
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._jqueryScrollEvent = function () {
        _this._updateScroll();
      };
      Ember.$(window).scroll(this._jqueryScrollEvent);
      console.log('table-header', this.$('thead'));
    },
    willDestroyElement: function willDestroyElement() {
      if (this._jqueryScrollEvent) {
        Ember.$(window).off('scroll', null, this._jqueryScrollEvent);
        this._jqueryScrollEvent = null;
      }
    },
    _updateScroll: function _updateScroll() {
      var $tables = this.$('thead');
      if ($tables.length < 1) {
        return;
      }
      var $normal = Ember.$($tables[0]);

      var scrollTop = Math.max(Ember.$('body').scrollTop(), Ember.$(document.documentElement).scrollTop());
      var tableTop = this.$().offset().top;
      var $mag = Ember.$('#magellan-header');
      if ($mag.length <= 0) {
        return;
      }
      var headerHeight = this.$().height() - $normal.height();
      var magHeight = $mag.position().top + $mag.height();
      scrollTop += magHeight;
      scrollTop += 5; // einwenig spiel (padding ?)
      if (scrollTop > tableTop) {
        if (scrollTop - tableTop < headerHeight) {
          $normal.css('transform', 'translateX(-1px) translateY(' + (scrollTop - tableTop) + 'px)');
        } else {
          $normal.css('transform', 'translateX(-1px) translateY(' + headerHeight + 'px)');
        }
      } else {
        $normal.css('transform', 'translateX(-1px) translateY(0px)');
      }
    }
  });
});