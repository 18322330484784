define('mpforst-ember/app/pods/assistent-taxation-editor/editor/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    betriebList: Ember.computed('model', function () {
      return Ember.$.getJSON('api/betrieb').then(function (x) {
        return x.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung
          };
        });
      });
    }),
    teilbetriebList: Ember.computed('model.betrieb_id', function () {
      return Ember.$.getJSON('api/betrieb/' + this.get('model.betrieb_id')).then(function (x) {
        return x.teilbetriebe.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung
          };
        });
      });
    }),
    bestandList: Ember.computed('model.teilbetrieb_id', function () {
      return Ember.$.getJSON('api/teilbetrieb/' + this.get('model.teilbetrieb_id')).then(function (x) {
        return x.bestaende.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung
          };
        });
      });
    }),
    bestandFlaecheObserver: Ember.observer('model.bestand_id', function () {
      var _this = this;

      Ember.$.getJSON('api/bestand/' + this.get('model.bestand_id')).then(function (x) {
        Ember.run(function () {
          _this.set('bestandFlaeche', x.grunddaten.flaeche);
        });
      });
    }),
    update: Ember.observer('model.betrieb_id', 'model.teilbetrieb_id', 'model.bestand_id', function () {
      if (!this.get('model.betrieb_id') && (this.get('model.teilbetrieb_id') || this.get('model.bestand_id'))) {
        this.setProperties({
          'model.teilbetrieb_id': null,
          'model.bestand_id': null
        });
      }
      if (!this.get('model.teilbetrieb_id') && this.get('model.bestand_id')) {
        this.setProperties({
          'model.bestand_id': null
        });
      }
    }),
    bestandIsOkay: Ember.computed('bestand.grunddaten.flaeche', function () {
      var props = this.getProperties('bestand.grunddaten.flaeche');
      return Object.keys(props).filter(function (x) {
        return props[x] == null;
      }).length === 0;
    }),
    saveObserver: Ember.observer('model.bestockung', function () {
      this.send('actionChange');
    }),

    saving: false,
    _lastUpdate: null,
    _doAgain: false,
    actions: {
      actionChange: function actionChange() {
        var _this2 = this;

        if (!this._lastUpdate) {
          this.set('saving', true);
          var model = this.get('model');
          this._lastUpdate = Ember.$.ajax({
            method: 'PUT',
            url: 'api/entwurfTaxation/' + model.get('id'),
            data: JSON.stringify(model),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
          }).then(function (data) {
            Ember.run.later(function () {
              _this2._lastUpdate = null;
              if (_this2._doAgain) {
                _this2._doAgain = false;
                Ember.run(function () {
                  _this2.actions.actionChange.apply(_this2);
                });
              } else {
                Ember.run(function () {
                  _this2.set('saving', false);
                  var model = _this2.get('model');

                  if (model.error) {
                    model.error.clear();
                  } else {
                    model.error = Ember.A();
                  }

                  if (data && data.error) {
                    model.error.addObjects(data.error);
                  }
                  for (var mi = 0; mi < model.input.length; ++mi) {
                    if (model.input[mi].error) {
                      model.input[mi].error.clear();
                    } else {
                      model.input[mi].set('error', Ember.A());
                    }
                    if (data && data.input && data.input[mi] && data.input[mi].error) {
                      model.input[mi].error.addObjects(data.input[mi].error);
                    }
                  }
                  model.set('version', (model.get('version') || 0) + 1); // work around for computed with @each.@each
                  _this2.set('model', model);
                });
              }
            }, 500);
          }, function () {
            Ember.$('#notify').notify({
              type: 'alert',
              closable: false,
              message: {
                text: 'Fehler beim Speichern des Entwurfes'
              }
            }).show();
            _this2._lastUpdate = null;
            _this2._doAgain = false;
            Ember.run(function () {
              _this2.set('saving', false);
            });
          });
        } else {
          this._doAgain = true;
        }
      }
    }
  });
});