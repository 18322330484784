define('mpforst-ember/app/pods/admin-help-tooltip/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    tooltips: Ember.inject.service('tooltips'),
    beforeModel: function beforeModel() {
      //force reload of tooltips
      return this.get('tooltips').load();
    },
    model: function model() {
      return Ember.$.getJSON('api/tooltips?with_long=True');
    },
    actions: {
      save: function save(model) {
        var _this = this;

        var controller = this.controllerFor('admin-help-tooltip');
        controller.set('saving', true);
        Ember.$.ajax({
          method: 'PUT',
          url: 'api/tooltips',
          data: JSON.stringify(model),
          contentType: "application/json; charset=utf-8",
          dataType: "json"
        }).then(function () {
          controller.set('saving', false);
          _this.refresh();
        });
      }
    }
  });
});