define('mpforst-ember/app/services/popups', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    popups: null,

    init: function init() {
      this._super.apply(this, arguments);
      this.set('popups', Ember.A());
    },
    addPopup: function addPopup(pop) {
      // create popup
      try {
        window.open(pop.src, '_blank', 'width=' + pop.w + ',height=' + pop.h + ',toolbar=yes,scrollbars=yes,resizable=yes');
      } catch (e) {
        var pops = this.get('popups');
        pops.addObject(pop);
        this.set('popups', pops);
      }
    },
    closePopup: function closePopup(pop) {
      var pops = this.get('popups');
      pops.removeObject(pop);
      this.set('popups', pops);
    },
    openHelp: function openHelp(title, src) {
      this.addPopup({
        title: title || "Hilfe",
        src: src || 'hilfe',
        w: Ember.$(window).width() * 0.6,
        h: Ember.$(window).height() * 0.6
      });
    }
  });
});