define('mpforst-ember/app/pods/grunddaten-teilbetrieb/bearbeiten/delete-confirmed/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'loading',
    xKlimafitt: Ember.inject.service(),
    model: function model() {
      var model = this.modelFor('grunddaten-teilbetrieb.bearbeiten');
      return Ember.$.ajax({
        method: 'DELETE',
        url: 'api/teilbetrieb/' + model.id
      });
    },
    afterModel: function afterModel() {
      window.frameElement.actions.deleted();
      return;
      this.transitionTo('grunddaten-betrieb');
      // Routen neu laden:
      this.controllerFor('grunddaten-betrieb').get('target').router.refresh();
      // message anzeigen
      Ember.$('#notify').notify({
        type: 'success',
        closable: false,
        message: {
          text: 'Teilbetrieb erfolgreich gelöscht'
        }
      }).show();
      this.get('xKlimafitt').refresh();
    }
  });
});