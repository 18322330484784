define('mpforst-ember/app/pods/admin-help-toc/save/route', ['exports', 'ember-mobiledoc-text-renderer'], function (exports, _emberMobiledocTextRenderer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function foreachPage(model, f, order) {
    order = order || 0;
    for (var i = 0; i < model.length; ++i) {
      var m = model[i];
      f(m, ++order);
      if (model[i].model) {
        order = foreachPage(model[i].model, f, order);
      }
    }
    return order;
  }

  exports.default = Ember.Route.extend({
    templateName: 'loading',
    model: function model() {
      var model = this.modelFor('admin-help-toc');
      //save this
      var promises = [];
      foreachPage(model, function (page, order) {
        promises.push(Ember.$.ajax({
          method: 'PUT',
          url: 'api/page/saveOrder/' + page.id,
          data: JSON.stringify({
            id: page.id,
            order: order
          }),
          contentType: "application/json; charset=utf-8",
          dataType: "json"
        }));
      });
      return Promise.all(promises);
    },
    afterModel: function afterModel(data) {
      this.transitionTo('admin-help-toc');
    }
  });
});