define('mpforst-ember/app/pods/assistent-wzpx-summary/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor('assistent-wzpx-entwurf.selected');
      return Ember.$.getJSON('api/entwurfWzpx/' + model.get('id')).then(function (x) {
        return Ember.Object.create(x);
      });
    }
  });
});