define('mpforst-ember/app/helpers/promise-loading', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  function ensurePromise(x) {
    return new Ember.RSVP.Promise(function (resolve) {
      resolve(x);
    });
  }

  exports.default = Ember.Helper.extend({
    compute: function compute(_ref) {
      var _this = this;

      var _ref2 = _slicedToArray(_ref, 1),
          promise = _ref2[0];

      if (this._promise !== promise || !this._done) {
        this._done = false;
        this._loading = true;
        this._error = false;
        this._promise = promise;
        this._value = null;
        // load data from promise
        ensurePromise(promise).then(function (value) {
          if (_this._promise !== promise) {
            return;
          }
          _this._done = true;
          _this._loading = false;
          _this._value = value;
          _this.recompute();
        }, function () {
          if (_this._promise !== promise) {
            return;
          }
          _this._done = true;
          _this._error = true;
          _this._loading = false;
          _this.recompute();
        });
      }
      return this._loading;
    }
  });
});