define('mpforst-ember/app/pods/assistent-wzpx-entwurf/selected/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    _lastUpdate: null,
    _do_again: false,
    updateObserver: Ember.observer('model.change_id', function () {
      var _this = this;

      var model = this.get('model');
      if (model.get('change_id') > 0) {
        if (!this._lastUpdate) {
          this._lastUpdate = Ember.$.ajax({
            method: 'PUT',
            url: 'api/entwurfWzpx/' + model.get('id'),
            data: JSON.stringify(model),
            contentType: "application/json; charset=utf-8",
            dataType: "json"
          }).then(function () {
            _this._lastUpdate = null;
            if (_this._do_again) {
              _this._do_again = false;
              _this.set('model.change_id', _this.get('model.change_id') + 1);
            }
          }, function () {
            Ember.$('#notify').notify({
              type: 'alert',
              closable: false,
              message: {
                text: 'Fehler beim Speichern des Entwurfes'
              }
            }).show();
            _this._lastUpdate = null;
            _this._do_again = false;
          });
        } else {
          this._do_again = true;
        }
      } else {
        model.set('change_id', 0);
      }
    })
  });
});