define('mpforst-ember/app/helpers/x-and', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.xAnd = xAnd;
  function xAnd(value /*, hash*/) {
    //debugger;
    return value.every(function (x) {
      return x;
    });
  }

  exports.default = Ember.Helper.helper(xAnd);
});