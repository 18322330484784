define('mpforst-ember/app/pods/assistent-wzpx-result/index/export/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return {
        download: 'api/entwurfWzpx/resultCsv/' + params.entwurf
      };
    },
    afterModel: function afterModel(model) {
      if (model && model.download) {
        var $iframe = Ember.$(document.createElement('iframe'));
        $iframe.css({
          visibility: 'hidden',
          position: 'absolute',
          width: 1,
          height: 1,
          left: 1,
          top: 1
        });
        $iframe.appendTo(Ember.$('body'));
        $iframe.attr('src', model.download);
        window.setTimeout(function () {
          $iframe.remove();
        }.bind($iframe), 60 * 1000); //nach 60 sek löschen
      }
      this.replaceWith('assistent-wzpx-result.index');
    }
  });
});