define('mpforst-ember/app/pods/grunddaten-betrieb/components/x-katasterflaechen-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    model: Ember.computed(function () {
      return {};
    }),
    classNameBindings: 'hasErrors:error',
    hasErrors: Ember.computed('model.error.length', function () {
      return (this.get('model.error') || []).length > 0;
    }),

    autocomplete_items: [],
    autoCompleteUpdate: Ember.on("init", Ember.observer("filter", function () {
      if (this.get('filter') != null) {
        this.set('autocomplete_items', Ember.$.getJSON('api/stammdaten/katastralgemeinde', {
          filter: this.get('filter')
        }));
      } else {
        if (this.get('model.katastralgemeinde_id')) {
          this.set('autocomplete_items', Ember.$.getJSON('api/stammdaten/katastralgemeinde/' + this.get('model.katastralgemeinde_id')));
        } else {
          this.set('autocomplete_items', []);
        }
      }
    })),

    didInsertElement: function didInsertElement() {
      var _this = this;

      this._change = function () {
        if (_this.get('empty')) {
          _this.sendAction('actionNew', _this.get('model'));
        }
        _this.sendAction('actionChange', _this.get('model'));

        // do validation
        var values = _this.get('model').getProperties('katastralgemeinde_id', 'einlagezahl', 'katasterflaeche');
        var errors = [];
        if (values.katastralgemeinde_id !== 0 && values.katastralgemeinde_id !== "" && !values.katastralgemeinde_id) {
          errors.push({
            message: 'Katastralgemeinde muss ausgefüllt werden'
          });
        }
        if (values.einlagezahl !== 0 && values.einlagezahl !== "" && !values.einlagezahl) {
          errors.push({
            message: 'Einlagezahl muss ausgefüllt werden'
          });
        }
        if (values.katasterflaeche !== 0 && values.katasterflaeche !== "" && !values.katasterflaeche) {
          errors.push({
            message: 'Fläche muss ausgefüllt werden'
          });
        }
        _this.set('model.error', errors);
      };
      this.$().on("change", "input, select", this._change);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._change) {
        this.$().off("change", "input, select", this._change);
      }
    },


    actions: {
      delete: function _delete() {
        this.sendAction('actionDelete', this.get('model'));
      },
      autocomplete_katastralgemeinde: function autocomplete_katastralgemeinde(item, value) {
        // reload items for auto complete :)
        this.set('filter', value);
      }
    }
  });
});