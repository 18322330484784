define("mpforst-ember/app/pods/admin-performance/components/x-graph/component", ["exports"], function (exports) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });

    /* global Chart */

    Chart.types.Line.extend({
        name: "AltLine",

        initialize: function initialize() {
            var helpers = Chart.helpers;
            Chart.types.Line.prototype.initialize.apply(this, arguments);
            this.buildScale = function (labels) {
                var scaleOptions = {
                    templateString: this.options.scaleLabel,
                    height: this.chart.height,
                    width: this.chart.width,
                    ctx: this.chart.ctx,
                    textColor: this.options.scaleFontColor,
                    fontSize: this.options.scaleFontSize,
                    fontStyle: this.options.scaleFontStyle,
                    fontFamily: this.options.scaleFontFamily,
                    valuesCount: labels.length,
                    beginAtZero: this.options.scaleBeginAtZero,
                    integersOnly: this.options.scaleIntegersOnly,
                    /*calculateYRange : function(currentHeight){
                        var updatedRanges = helpers.calculateScaleRange(
                            dataTotal(),
                            currentHeight,
                            this.fontSize,
                            this.beginAtZero,
                            this.integersOnly
                        );
                        helpers.extend(this, updatedRanges);
                    },*/
                    xLabels: labels,
                    font: helpers.fontString(this.options.scaleFontSize, this.options.scaleFontStyle, this.options.scaleFontFamily),
                    lineWidth: this.options.scaleLineWidth,
                    lineColor: this.options.scaleLineColor,
                    showHorizontalLines: this.options.scaleShowHorizontalLines,
                    showVerticalLines: this.options.scaleShowVerticalLines,
                    gridLineWidth: this.options.scaleShowGridLines ? this.options.scaleGridLineWidth : 0,
                    gridLineColor: this.options.scaleShowGridLines ? this.options.scaleGridLineColor : "rgba(0,0,0,0)",
                    padding: this.options.showScale ? 0 : this.options.pointDotRadius + this.options.pointDotStrokeWidth,
                    showLabels: this.options.scaleShowLabels,
                    display: this.options.showScale
                };
                this.scale = new Chart.Scale(scaleOptions);
            };
        }
    });

    function update($row, data) {
        var time_canvas = $row.find("#time_chart")[0];
        var resp_canvas = $row.find("#resp_chart")[0];
        var requ_canvas = $row.find("#requ_chart")[0];

        time_canvas.chartjs.addData([data.time.avg, data.time.value], "Response Time");
        resp_canvas.chartjs.addData([data.response.avg, data.response.value], "Response Size");
        requ_canvas.chartjs.addData([data.request.avg, data.request.value], "Request Size");

        if (time_canvas.datapoints++ > 30) {
            time_canvas.chartjs.removeData();
        }
        if (resp_canvas.datapoints++ > 30) {
            resp_canvas.chartjs.removeData();
        }
        if (requ_canvas.datapoints++ > 30) {
            requ_canvas.chartjs.removeData();
        }

        $row.find("#method").text(data.method);
        $row.find('#controller').text(data.controller);
        $row.find('#action').text(data.action);
        $row.find('#requests').text(data.rev.toLocaleString('de-DE') + " Requests");

        $row.find('#time_min').text("min: " + data.time.min.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "ms");
        $row.find('#time_avg').text("avg: " + data.time.avg.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "ms");
        $row.find('#time_max').text("max: " + data.time.max.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "ms");
        $row.find('#time_last').text("last: " + data.time.value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "ms");

        $row.find('#resp_min').text("min: " + data.response.min.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "KiB");
        $row.find('#resp_avg').text("avg: " + data.response.avg.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "KiB");
        $row.find('#resp_max').text("max: " + data.response.max.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "KiB");
        $row.find('#resp_last').text("last: " + data.response.value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "KiB");

        $row.find('#requ_min').text("min: " + data.request.min.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "KiB");
        $row.find('#requ_avg').text("avg: " + data.request.avg.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "KiB");
        $row.find('#requ_max').text("max: " + data.request.max.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "KiB");
        $row.find('#requ_last').text("last: " + data.request.value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "KiB");
    }

    function graph(ctx, colorA, colorB, ext) {
        var startingData = {
            labels: [],
            datasets: [{
                fillColor: "rgba(" + colorA + ",0.2)",
                strokeColor: "rgba(" + colorA + ",1)",
                pointColor: "rgba(" + colorA + ",1)",
                pointStrokeColor: "#fff",
                data: []
            }, {
                fillColor: "rgba(" + colorB + ",0.2)",
                strokeColor: "rgba(" + colorB + ",1)",
                pointColor: "rgba(" + colorB + ",1)",
                pointStrokeColor: "#fff",
                data: []
            }]
        };

        return new Chart(ctx).AltLine(startingData, {
            animation: false, //<- too slow
            animationSteps: 5,
            bezierCurve: false,
            responsive: true,
            scaleShowLabels: false,
            showScale: false,
            scaleBeginAtZero: true,
            multiTooltipTemplate: "<%=value.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })%>" + ext
        });
    }

    function parse(data) {
        if (data.controller === 'Stammdaten') {
            //Stammdaten ignorieren
            return;
        }
        var $row = Ember.$('#' + 'row_' + data.id);
        if ($row.length === 0) {
            $row = Ember.$("#firstRow").clone();
            $row.attr('id', 'row_' + data.id);
            $row.appendTo(data.id === 0 ? Ember.$("#for_row_0") : Ember.$("#firstRow").parent());
            $row.show();
            var parent = $row.parent();

            var time_canvas = $row.find("#time_chart")[0];
            var resp_canvas = $row.find("#resp_chart")[0];
            var requ_canvas = $row.find("#requ_chart")[0];

            var time_ctx = time_canvas.getContext('2d');
            var resp_ctx = resp_canvas.getContext('2d');
            var requ_ctx = requ_canvas.getContext('2d');

            time_canvas.chartjs = graph(time_ctx, "220,220,220", "151,187,205", "ms");
            resp_canvas.chartjs = graph(resp_ctx, "220,0,0", "151,0,0", "KiB");
            requ_canvas.chartjs = graph(requ_ctx, "0,220,0", "0,187,0", "KiB");

            time_canvas.datapoints = 0;
            resp_canvas.datapoints = 0;
            requ_canvas.datapoints = 0;

            $row[0].url = data.controller + "/" + data.action + "/" + data.method;

            update($row, data);

            Ember.$(".row_dummy").sort(function (a, b) {
                return a.url > b.url;
            }).appendTo(parent);
        } else {

            update($row, data);
        }
    }

    exports.default = Ember.Component.extend({
        didInsertElement: function didInsertElement() {
            var _this = this;

            // INITIAL WERTE BEKOMMEN
            Ember.$.ajax({ url: 'api/monitor/initial' }).done(function (data) {
                for (var i = 0; i !== data.length; ++i) {
                    if (data[i].time.history.length !== 0) {
                        for (var j = 0; j !== data[i].time.history.length; ++j) {

                            data[i].time.avg = data[i].time.history[j].avg;
                            data[i].time.value = data[i].time.history[j].value;

                            data[i].response.avg = data[i].response.history[j].avg;
                            data[i].response.value = data[i].response.history[j].value;

                            data[i].request.avg = data[i].request.history[j].avg;
                            data[i].request.value = data[i].request.history[j].value;

                            parse(data[i]);
                        }
                    } else {
                        parse(data[i]);
                    }
                }
                // LIVE WERTE
                if (window.EventSource) {
                    _this.source = new window.EventSource('api/monitor');

                    _this.source.addEventListener('monitor', function (e) {
                        var data = JSON.parse(e.data);
                        if (typeof data["stats"] === 'undefined') {
                            return;
                        }
                        parse(data.stats);
                    });
                }
            });
        },
        willDestroyElement: function willDestroyElement() {
            if (this.source) {
                this.source.close();
            }
        }
    });
});