define('mpforst-ember/app/pods/report-hiebsatz/controller', ['exports', 'mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/controller'], function (exports, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var NutzungsklasseObject = Ember.Object.extend({
    model: null,
    value: null,
    caption: null,
    checked: Ember.computed('model', 'model.nutzungsklasse', {
      get: function get() {
        return (this.get('model.nutzungsklasse') || []).indexOf(this.get('value')) !== -1;
      },
      set: function set(key, value) {
        var nutz = this.get('model.nutzungsklasse') || [];
        if (value) {
          nutz.push(this.get('value'));
        } else {
          var v = this.get('value');
          nutz = nutz.filter(function (x) {
            return x !== v;
          });
        }
        this.set('model.nutzungsklasse', nutz);
        return value;
      }
    }),
    id: Ember.computed('value', function () {
      return 'nutzungsklasse-' + this.get('value');
    })
  });

  exports.default = _controller.default.extend({
    breadCrumb: 'Hiebsatz',
    revierList: Ember.computed('model', 'model.teilbetrieb_id', function () {
      return Ember.$.getJSON('api/teilbetrieb/' + this.get('model.teilbetrieb_id')).then(function (x) {
        x.reviere = [{
          id: 0,
          bezeichnung: 'alle'
        }].concat(x.reviere);
        return x.reviere.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung
          };
        });
      });
    }),
    updateNutzungsklasseList: Ember.observer('model', function () {
      var _this = this;

      return Ember.$.getJSON('api/stammdaten/nutzungsklasse').then(function (x) {
        _this.set('nutzungsklasseList', x.map(function (y) {
          return NutzungsklasseObject.create({
            model: _this.get('model'),
            value: y.value,
            caption: y.caption
          });
        }));
      });
    }),
    update: Ember.observer('model.teilbetrieb_id', 'model.bestand_id', 'model.reporttyp_id', 'model.reportfilter_id', function () {
      if (!this.get('model.teilbetrieb_id') && this.get('model.reporttyp_id')) {
        this.set('model.reporttyp_id', null);
      }
      if (!this.get('model.reporttyp_id') && this.get('model.reportfilter_id')) {
        this.set('model.reportfilter_id', null);
      }
      if (!this.get('model.reportfilter_id') && this.get('model.nutzungsklasse')) {
        this.set('model.nutzungsklasse', [1, 2, 3, 4, 5]);
      }
    }),
    nutzungsklasseAsString: Ember.computed('model.nutzungsklasse', function () {
      return (this.get('model.nutzungsklasse') || []).join();
    }),
    actions: {
      refreshKennzahlen: function refreshKennzahlen() {
        this.notifyPropertyChange('countPromise');
      }
    }
  });
});