define('mpforst-ember/app/pods/components/sticky-container/x-sticky/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$().css('position', 'relative');
      this.$().css('will-change', 'transform');

      this.$sticky = this.$();
      this.$container = this.get('container').$();
      if (this.get('below')) {
        this.$below = Ember.$(this.get('below'));
        if (!this.$below.length) {
          console.warn('Couldn\'t find \'' + this.get('below') + '\' element');
          this.$below = null;
        }
      }
      this.stickyClass = this.get('sticky-class');

      //install scroll watcher
      this.scrollHandler = this._updateScroll.bind(this);
      Ember.$(window).on('scroll', null, this.scrollHandler);
      Ember.$(window).on('resize', null, this.scrollHandler);

      this.scrollHandler();
    },
    didDestroyElement: function didDestroyElement() {
      //uninstall scroll watcher
      Ember.$(window).off('scroll', null, this.scrollHandler);
      Ember.$(window).off('resize', null, this.scrollHandler);
    },
    _updateScroll: function _updateScroll() {
      var _this = this;

      var scrollTop = Math.max(Ember.$('body').scrollTop(), Ember.$(document.documentElement).scrollTop());
      var containerTop = this.$container.offset().top;
      var belowBottom = 0;
      if (this.$below) {
        belowBottom = this.$below.position().top + this.$below.height();
      }
      var stickyAreaHeight = Math.ceil(this.$container.height() - this.$sticky.height());

      console.log('update sticky', {
        container: this.$container[0],
        sticky: this.$sticky[0],
        scrollTop: scrollTop,
        containerTop: containerTop,
        belowBottom: belowBottom,
        stickyAreaHeight: stickyAreaHeight
      });

      scrollTop += belowBottom;

      var y = 0;
      if (scrollTop > containerTop) {
        y = Math.min(scrollTop - containerTop, stickyAreaHeight);
      }
      y = Math.floor(y);
      this.$sticky.css('transform', 'translateY(' + y + 'px)');

      if (y > 0) {
        this.$sticky.css('z-index', 10);
      } else {
        this.$sticky.css('z-index', '');
      }

      if (this.requestAnimation) {
        window.cancelAnimationFrame(this.requestAnimation);
      }
      if (this.oldy !== y && y > 0 && y < stickyAreaHeight && window.requestAnimationFrame) {
        this.oldy = y;
        this.requestAnimation = window.requestAnimationFrame(function () {
          _this._updateScroll();
        });
      }

      if (this.stickyClass) {
        if (y > 0) {
          if (!this.$sticky.hasClass(this.stickyClass)) {
            this.$sticky.addClass(this.stickyClass);
          }
        } else {
          this.$sticky.removeClass(this.stickyClass);
        }
      }
    }
  });
});