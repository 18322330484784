define('mpforst-ember/app/objects/local-storage-object', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Object.extend({
    cache_key: null,
    data: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('data', Ember.Object.create({}));
    },
    load: Ember.on('init', Ember.observer('cache_key', function () {
      var json = void 0;
      try {
        json = JSON.parse(localStorage.getItem(this.get('cache_key')));
      } catch (e) {
        // ignore problems
      }
      this.data.setProperties(json);
    })),
    save: function save() {
      try {
        localStorage.setItem(this.get('cache_key'), JSON.stringify(this.data));
      } catch (e) {
        // ignore problems
      }
    },
    unknownProperty: function unknownProperty(key) {
      return this.data[key];
    },
    setUnknownProperty: function setUnknownProperty(key, value) {
      this._super.apply(this, arguments);
      this.data.set(key, value);
      this.save();
      this.notifyPropertyChange(key);
    }
  });
});