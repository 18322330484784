define('mpforst-ember/app/pods/report-hiebsatz/generate/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(param) {
      return Ember.$.ajax({
        method: 'POST',
        url: 'api/reportHiebsatz/prepare',
        data: JSON.stringify({
          teilbetrieb_id: parseInt(param.teilbetrieb_id),
          revier_id: parseInt(param.revier_id) === 0 ? null : parseInt(param.revier_id),
          nutzungsklassen: param.nuzungsklasse.split(',').map(function (x) {
            return parseInt(x);
          })
        }),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function (hiebsatz) {
        return Ember.$.ajax({
          method: 'POST',
          url: 'api/report',
          data: JSON.stringify({
            reportId: 2,
            id: parseInt(param.teilbetrieb_id),
            revier_id: parseInt(param.revier_id) === 0 ? null : parseInt(param.revier_id),
            typ_id: parseInt(param.type),
            darstellung_id: parseInt(param.darstellung),
            nutzungsklasse_id: param.nuzungsklasse.split(',').map(function (x) {
              return parseInt(x);
            }),
            hiebsatz_id: hiebsatz.hiebsatz_id
          }),
          contentType: "application/json; charset=utf-8",
          dataType: "json"
        }).then(function (x) {
          if (x.download) {
            var start = x.download.indexOf('/api');
            if (start !== -1) {
              x.download = x.download.substr(start + 1);
            }
          }
          x.okay = hiebsatz.okay;
          return x;
        });
      });
    },
    afterModel: function afterModel(model) {
      if (model && model.download) {
        var $iframe = Ember.$(document.createElement('iframe'));
        $iframe.css({
          visibility: 'hidden',
          position: 'absolute',
          width: 1,
          height: 1,
          left: 1,
          top: 1
        });
        $iframe.appendTo(Ember.$('body'));
        $iframe.attr('src', model.download);
        window.setTimeout(function () {
          $iframe.remove();
        }.bind($iframe), 60 * 1000); //nach 60 sek löschen
      }
      if (model.okay) {
        this.replaceWith('report-hiebsatz');
      } else {
        this.replaceWith('report-hiebsatz.nan');
      }
    }
  });
});