define('mpforst-ember/app/pods/components/editor-image-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function generateUUID() {
    var d = new Date().getTime();
    if (window.performance && typeof window.performance.now === "function") {
      d += performance.now(); //use high-precision timer if available
    }
    var uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
      var r = (d + Math.random() * 16) % 16 | 0;
      d = Math.floor(d / 16);
      return (c == 'x' ? r : r & 0x3 | 0x8).toString(16);
    });
    return uuid;
  }

  exports.default = Ember.Component.extend({
    payload: null,
    name: Ember.computed(function () {
      return 'zpage_file_' + generateUUID();
    }),
    src: Ember.computed.alias('payload.src'),
    didInsertElement: function didInsertElement() {
      var _this = this;

      var form = this.$('form');
      form.ajaxForm();
      this.$('input[type=file]').change(function () {
        _this.set('upload', true);
        form.ajaxSubmit({
          url: 'api/file/upload',
          type: 'POST',
          dataType: 'json',
          resetForm: true,

          beforeSend: function beforeSend() {
            Ember.run(function () {
              _this.set('uploadProgress', 0);
            });
          },
          uploadProgress: function uploadProgress(event, postion, total, percent) {
            Ember.run(function () {
              _this.set('uploadProgress', percent);
            });
          },
          success: function success() {
            Ember.run(function () {
              _this.set('uploadProgress', 100);
            });
          },
          complete: function complete(xhr) {
            Ember.run(function () {
              form.trigger('reset');
              _this.set('upload', false);
              var json = {
                okay: false,
                error: [{ field: 'unbekannt', message: 'Unbekannt' }]
              };
              try {
                json = JSON.parse(xhr.responseText);
              } catch (e) {
                // ignore
              }
              if (!json.okay) {
                _this.set('error', json.error);
              } else {
                /*this.transitionToRoute('admin-file');
                this.get('target').router.refresh();*/
                _this.set('src', 'api/file/' + _this.get('name'));
                _this.sendAction('saveCard', {
                  src: _this.get('src')
                });
                _this.send('close');
              }
              _this.notifyPropertyChange('name');
            });
          }
        });
      });
    },
    actions: {
      open: function open() {
        this.get('editor').disableEditing(); //fixes stupid bugs..
        this.set('showEditor', true);
      },
      close: function close() {
        this.set('showEditor', false);
        this.get('editor').enableEditing();
      }
    }
  });
});