define('mpforst-ember/app/pods/assistent-kennzahlen/einleitung/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    beforeModel: function beforeModel() {
      // Einleitung überspringen
      this.replaceWith('assistent-kennzahlen-setup');
    }
  });
});