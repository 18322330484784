define("mpforst-ember/app/pods/components/editor-wysiwyg/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/editor-wysiwyg/template.hbs"
        },
        isEmpty: false,
        arity: 1,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          return morphs;
        },
        statements: [["inline", "yield", [["get", "editor", ["loc", [null, [2, 10], [2, 16]]], 0, 0, 0, 0]], [], ["loc", [null, [2, 2], [2, 18]]], 0, 0]],
        locals: ["editor"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 3,
            "column": 21
          }
        },
        "moduleName": "mpforst-ember/app/pods/components/editor-wysiwyg/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "mobiledoc-editor", [], ["mobiledoc", ["subexpr", "@mut", [["get", "_mobiledoc", ["loc", [null, [1, 30], [1, 40]]], 0, 0, 0, 0]], [], [], 0, 0], "on-change", ["subexpr", "@mut", [["get", "on-change", ["loc", [null, [1, 51], [1, 60]]], 0, 0, 0, 0]], [], [], 0, 0], "options", ["subexpr", "hash", [], ["parserPlugins", ["get", "parserPlugins", ["loc", [null, [1, 89], [1, 102]]], 0, 0, 0, 0]], ["loc", [null, [1, 69], [1, 103]]], 0, 0], "atoms", ["subexpr", "@mut", [["get", "atoms", ["loc", [null, [1, 110], [1, 115]]], 0, 0, 0, 0]], [], [], 0, 0], "cards", ["subexpr", "@mut", [["get", "cards", ["loc", [null, [1, 122], [1, 127]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [3, 21]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});