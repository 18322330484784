define('mpforst-ember/app/pods/assistent-planung-editor/save/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      var model = this.modelFor('assistent-planung-editor');
      return Ember.$.ajax({
        method: 'POST',
        url: 'api/planung/' + model.get('id'),
        data: JSON.stringify(model),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function () {
        return params;
      });
    },
    afterModel: function afterModel(params) {
      window.frameElement.actions.saved(params.bestand);
      return;
      this.replaceWith('assistent-planung.success', params.betrieb, params.teilbetrieb, params.bestand);
    }
  });
});