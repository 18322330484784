define('mpforst-ember/app/pods/components/numeric-input/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.formatFloat = formatFloat;


  function cleanUpFloat(value) {
    if (!value) {
      return '';
    }
    value = value.toString();
    var valueClean = '';
    for (var i = 0; i < value.length; ++i) {
      if (value[i] >= '0' && value[i] <= '9' || value[i] === ',' || value[i] === '-') {
        valueClean += value[i];
      }
    }
    return valueClean;
  }

  function formatFloat(value, fraction) {
    fraction = fraction === 0 ? 0 : fraction || 2;

    /* jslint eqeq: true */
    if (value || value === 0) {
      return value.toLocaleString('de-DE', {
        minimumFractionDigits: fraction,
        maximumFractionDigits: fraction
      });
    }

    return '';
  }

  exports.default = Ember.TextField.extend({
    fraction: 2,
    min: 0,
    max: Number.POSITIVE_INFINITY,
    classNames: ['numeric-input'],
    numericValue: Ember.computed('value', {
      get: function get() {
        var value = parseFloat(cleanUpFloat(this.get('value')).replace(',', '.').replace(/\.$/, '.0'));
        if (value !== value) {
          //NaN check
          return null;
        }
        return value;
      },
      set: function set(key, value) {
        var nvalue = this.get('numericValue');
        if (key === 'numericValue' && value !== nvalue) {
          Ember.run.once(this, this._format);
        }
        return value;
      }
    }),

    change: function change(evt) {
      if (this._catchevent) {
        this._catchevent = false;
        return;
      }
      evt.stopPropagation();
      this._super();
      Ember.run.once(this, this._format);
    },


    _format: function _format() {
      var _this = this;

      var first_format = !this._notfirst;
      this._notfirst = true;

      var value = this.get('numericValue');
      if (value == null) {
        this.set('value', null);
        return;
      }
      var options = this.getProperties('fraction', 'min', 'max');
      var need_change = false;
      if (!first_format) {
        if (value < options.min) {
          value = options.min;
        } else if (value > options.max) {
          value = options.max;
        }
      }

      var n = formatFloat(value, options.fraction);
      if (n !== this.get('value')) {
        this.set('value', formatFloat(value, options.fraction));
        if (!first_format) {
          var element = this.element;
          Ember.run.schedule('afterRender', function () {
            //trigger change event !
            _this._catchevent = true;
            if ("createEvent" in document) {
              var evt = document.createEvent("HTMLEvents");
              evt.initEvent("change", true, true);
              element.dispatchEvent(evt);
            } else {
              element.fireEvent("onchange");
            }
          });
        }
      }
    }
  });
});