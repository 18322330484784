define('mpforst-ember/app/pods/assistent-taxation-entwurf/auswahl/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    actions: {
      newTaxation: function newTaxation() {
        var _this = this;

        Ember.$.ajax({
          method: 'POST',
          url: 'api/entwurfTaxation'
        }).then(function (data) {
          Ember.run(function () {
            _this.transitionToRoute('assistent-taxation-entwurf.selected', data.id);
          });
        }, function () {
          // ignore
        });
      }
    }
  });
});