define("mpforst-ember/app/pods/admin-page/page/edit/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 6,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("h2");
            var el2 = dom.createTextNode("\n      Seite: ");
            dom.appendChild(el1, el2);
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            var el2 = dom.createTextNode("\n    ");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
            return morphs;
          },
          statements: [["inline", "if", [["get", "model.name", ["loc", [null, [4, 18], [4, 28]]], 0, 0, 0, 0], ["get", "model.name", ["loc", [null, [4, 29], [4, 39]]], 0, 0, 0, 0], "?"], [], ["loc", [null, [4, 13], [4, 45]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 11,
                    "column": 6
                  },
                  "end": {
                    "line": 13,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "model.id", ["loc", [null, [12, 38], [12, 46]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "not", [["get", "id", ["loc", [null, [12, 57], [12, 59]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 52], [12, 60]]], 0, 0]], ["loc", [null, [12, 8], [12, 62]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 4
                },
                "end": {
                  "line": 14,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Name"], 0, null, ["loc", [null, [11, 6], [13, 27]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 16,
                    "column": 6
                  },
                  "end": {
                    "line": 18,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "model.name", ["loc", [null, [17, 38], [17, 48]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", true], ["loc", [null, [17, 8], [17, 60]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 15,
                  "column": 4
                },
                "end": {
                  "line": 19,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Überschrift"], 0, null, ["loc", [null, [16, 6], [18, 27]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 21,
                    "column": 6
                  },
                  "end": {
                    "line": 23,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["type", "select", "edit", true, "value", ["subexpr", "@mut", [["get", "model.parent", ["loc", [null, [22, 50], [22, 62]]], 0, 0, 0, 0]], [], [], 0, 0], "items", ["subexpr", "@mut", [["get", "model.parents", ["loc", [null, [22, 69], [22, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "displayEmptyItem", true], ["loc", [null, [22, 8], [22, 106]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 20,
                  "column": 4
                },
                "end": {
                  "line": 24,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-label", [], ["titel", "Parent (Übergeordnete Seite)"], 0, null, ["loc", [null, [21, 6], [23, 27]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 2
              },
              "end": {
                "line": 25,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(3);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-row-column", [], ["large", 3], 0, null, ["loc", [null, [10, 4], [14, 30]]]], ["block", "foundation-row-column", [], ["large", 3], 1, null, ["loc", [null, [15, 4], [19, 30]]]], ["block", "foundation-row-column", [], ["large", 3], 2, null, ["loc", [null, [20, 4], [24, 30]]]]],
          locals: [],
          templates: [child0, child1, child2]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 29,
                    "column": 4
                  },
                  "end": {
                    "line": 32,
                    "column": 4
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n      ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("b");
                var el2 = dom.createTextNode("Text bitte leeren und unterhalb Eingeben !");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "mpf-input", [], ["type", "textarea", "value", ["subexpr", "@mut", [["get", "model.markdown", ["loc", [null, [30, 40], [30, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", true], ["loc", [null, [30, 6], [30, 66]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 28,
                  "column": 2
                },
                "end": {
                  "line": 33,
                  "column": 2
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [29, 4], [32, 30]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 27,
                "column": 0
              },
              "end": {
                "line": 34,
                "column": 0
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "mpf-magellan-section", [], ["titel", "Inhalt - Legacy", "class", "clearfix"], 0, null, ["loc", [null, [28, 2], [33, 27]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 40,
                      "column": 10
                    },
                    "end": {
                      "line": 42,
                      "column": 10
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "sec.title", ["loc", [null, [41, 42], [41, 51]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", true], ["loc", [null, [41, 12], [41, 63]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 39,
                    "column": 6
                  },
                  "end": {
                    "line": 43,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-label", [], ["titel", "Titel"], 0, null, ["loc", [null, [40, 10], [42, 31]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 45,
                      "column": 10
                    },
                    "end": {
                      "line": 47,
                      "column": 10
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("            ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "sec.subtitle", ["loc", [null, [46, 42], [46, 54]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", true], ["loc", [null, [46, 12], [46, 66]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 44,
                    "column": 6
                  },
                  "end": {
                    "line": 48,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-label", [], ["titel", "Subtitel"], 0, null, ["loc", [null, [45, 10], [47, 31]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child2 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 49,
                    "column": 6
                  },
                  "end": {
                    "line": 55,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "style", "float: right");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "class", "secondary radius tiny button");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "fa fa-chevron-up");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "class", "secondary radius tiny button");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "fa fa-chevron-down");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("button");
                dom.setAttribute(el2, "class", "secondary radius tiny button");
                var el3 = dom.createElement("span");
                dom.setAttribute(el3, "class", "fa fa-trash");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var element6 = dom.childAt(element5, [1]);
                var element7 = dom.childAt(element5, [3]);
                var element8 = dom.childAt(element5, [5]);
                var morphs = new Array(3);
                morphs[0] = dom.createAttrMorph(element6, 'onClick');
                morphs[1] = dom.createAttrMorph(element7, 'onClick');
                morphs[2] = dom.createAttrMorph(element8, 'onClick');
                return morphs;
              },
              statements: [["attribute", "onClick", ["subexpr", "action", ["upSection", ["get", "sec", ["loc", [null, [51, 84], [51, 87]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [51, 89]]], 0, 0], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["downSection", ["get", "sec", ["loc", [null, [52, 86], [52, 89]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [52, 91]]], 0, 0], 0, 0, 0, 0], ["attribute", "onClick", ["subexpr", "action", ["deleteSection", ["get", "sec", ["loc", [null, [53, 88], [53, 91]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [53, 93]]], 0, 0], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          var child3 = function () {
            var child0 = function () {
              var child0 = function () {
                var child0 = function () {
                  var child0 = function () {
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 61,
                            "column": 18
                          },
                          "end": {
                            "line": 98,
                            "column": 18
                          }
                        },
                        "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("li");
                        dom.setAttribute(el1, "class", "mobiledoc-toolbar__control");
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("button");
                        dom.setAttribute(el2, "type", "button");
                        dom.setAttribute(el2, "title", "Link");
                        dom.setAttribute(el2, "class", "mobiledoc-toolbar__button secondary radius tiny button");
                        var el3 = dom.createTextNode("\n                        ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("span");
                        dom.setAttribute(el3, "class", "fa fa-link");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                      ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("li");
                        dom.setAttribute(el1, "class", "mobiledoc-toolbar__control");
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("button");
                        dom.setAttribute(el2, "type", "button");
                        dom.setAttribute(el2, "title", "Image");
                        dom.setAttribute(el2, "class", "mobiledoc-toolbar__button secondary radius tiny button");
                        var el3 = dom.createTextNode("\n                        ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("span");
                        dom.setAttribute(el3, "class", "fa fa-file-image-o");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                      ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("li");
                        dom.setAttribute(el1, "class", "mobildoc-toolbard__control");
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("button");
                        dom.setAttribute(el2, "type", "button");
                        dom.setAttribute(el2, "title", "Html");
                        dom.setAttribute(el2, "class", "mobiledoc-toolbar__button secondary radius tiny button");
                        var el3 = dom.createTextNode("\n                        ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("span");
                        dom.setAttribute(el3, "class", "fa fa-file-code-o");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                      ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n                    ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createElement("li");
                        dom.setAttribute(el1, "class", "mobildoc-toolbard__control");
                        var el2 = dom.createTextNode("\n                      ");
                        dom.appendChild(el1, el2);
                        var el2 = dom.createElement("button");
                        dom.setAttribute(el2, "type", "button");
                        dom.setAttribute(el2, "title", "Html");
                        dom.setAttribute(el2, "class", "mobiledoc-toolbar__button secondary radius tiny button");
                        var el3 = dom.createTextNode("\n                        ");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createElement("span");
                        dom.setAttribute(el3, "class", "fa fa-sticky-note-o");
                        dom.appendChild(el2, el3);
                        var el3 = dom.createTextNode("\n                      ");
                        dom.appendChild(el2, el3);
                        dom.appendChild(el1, el2);
                        var el2 = dom.createTextNode("\n                    ");
                        dom.appendChild(el1, el2);
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var element1 = dom.childAt(fragment, [1, 1]);
                        var element2 = dom.childAt(fragment, [3, 1]);
                        var element3 = dom.childAt(fragment, [5, 1]);
                        var element4 = dom.childAt(fragment, [7, 1]);
                        var morphs = new Array(4);
                        morphs[0] = dom.createElementMorph(element1);
                        morphs[1] = dom.createElementMorph(element2);
                        morphs[2] = dom.createElementMorph(element3);
                        morphs[3] = dom.createElementMorph(element4);
                        return morphs;
                      },
                      statements: [["element", "action", ["insertAtom", ["get", "editor", ["loc", [null, [67, 46], [67, 52]]], 0, 0, 0, 0], "editor-link-atom"], [], ["loc", [null, [67, 24], [67, 73]]], 0, 0], ["element", "action", ["insertCard", ["get", "editor", ["loc", [null, [76, 46], [76, 52]]], 0, 0, 0, 0], "editor-image-card"], [], ["loc", [null, [76, 24], [76, 74]]], 0, 0], ["element", "action", ["insertCard", ["get", "editor", ["loc", [null, [85, 46], [85, 52]]], 0, 0, 0, 0], "editor-html-card"], [], ["loc", [null, [85, 24], [85, 73]]], 0, 0], ["element", "action", ["insertCard", ["get", "editor", ["loc", [null, [94, 46], [94, 52]]], 0, 0, 0, 0], "editor-latex-card"], [], ["loc", [null, [94, 24], [94, 74]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  }();
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 59,
                          "column": 14
                        },
                        "end": {
                          "line": 100,
                          "column": 14
                        }
                      },
                      "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createTextNode("                ");
                      dom.appendChild(el0, el1);
                      var el1 = dom.createElement("div");
                      dom.setAttribute(el1, "class", "toolbar");
                      var el2 = dom.createElement("div");
                      var el3 = dom.createTextNode("\n");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createComment("");
                      dom.appendChild(el2, el3);
                      var el3 = dom.createTextNode("                ");
                      dom.appendChild(el2, el3);
                      dom.appendChild(el1, el2);
                      dom.appendChild(el0, el1);
                      var el1 = dom.createTextNode("\n");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 0]), 1, 1);
                      return morphs;
                    },
                    statements: [["block", "mobiledoc-toolbar", [], ["editor", ["subexpr", "@mut", [["get", "editor", ["loc", [null, [61, 46], [61, 52]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [61, 18], [98, 40]]]]],
                    locals: [],
                    templates: [child0]
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 58,
                        "column": 12
                      },
                      "end": {
                        "line": 101,
                        "column": 12
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "s.sticky", [], ["below", "#magellan-header"], 0, null, ["loc", [null, [59, 14], [100, 27]]]]],
                  locals: ["editor"],
                  templates: [child0]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 57,
                      "column": 10
                    },
                    "end": {
                      "line": 102,
                      "column": 10
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "editor-wysiwyg", [], ["mobiledoc", ["subexpr", "readonly", [["get", "sec.content", ["loc", [null, [58, 50], [58, 61]]], 0, 0, 0, 0]], [], ["loc", [null, [58, 40], [58, 62]]], 0, 0], "on-change", ["subexpr", "action", [["subexpr", "mut", [["get", "sec.content", ["loc", [null, [58, 86], [58, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [58, 81], [58, 98]]], 0, 0]], [], ["loc", [null, [58, 73], [58, 99]]], 0, 0]], 0, null, ["loc", [null, [58, 12], [101, 31]]]]],
                locals: ["s"],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 56,
                    "column": 6
                  },
                  "end": {
                    "line": 103,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "sticky-container", [], [], 0, null, ["loc", [null, [57, 10], [102, 31]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 37,
                  "column": 4
                },
                "end": {
                  "line": 104,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
            },
            isEmpty: false,
            arity: 1,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("hr");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
              morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
              morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-row-column", [], ["class", "h3input", "large", 9], 0, null, ["loc", [null, [39, 6], [43, 32]]]], ["block", "foundation-row-column", [], ["large", 9], 1, null, ["loc", [null, [44, 6], [48, 32]]]], ["block", "foundation-row-column", [], ["large", 3], 2, null, ["loc", [null, [49, 6], [55, 32]]]], ["block", "foundation-row-column", [], ["large", 12], 3, null, ["loc", [null, [56, 6], [103, 34]]]]],
            locals: ["sec"],
            templates: [child0, child1, child2, child3]
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 106,
                  "column": 6
                },
                "end": {
                  "line": 108,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "secondary radius tiny button");
              var el2 = dom.createTextNode("Abschnitt hinzufügen");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(1);
              morphs[0] = dom.createAttrMorph(element0, 'onClick');
              return morphs;
            },
            statements: [["attribute", "onClick", ["subexpr", "action", ["addSection"], [], ["loc", [null, [null, null], [107, 84]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 36,
                "column": 2
              },
              "end": {
                "line": 109,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("hr");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(2);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "each", [["get", "model.sections", ["loc", [null, [37, 12], [37, 26]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [37, 4], [104, 15]]]], ["block", "foundation-row-column", [], ["large", 12], 1, null, ["loc", [null, [106, 6], [108, 32]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 110,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 12, "class", "clearfix"], 0, null, ["loc", [null, [2, 2], [6, 24]]]], ["block", "mpf-magellan-section", [], ["titel", "Einstellungen", "class", "clearfix"], 1, null, ["loc", [null, [9, 2], [25, 27]]]], ["block", "if", [["get", "model.markdown", ["loc", [null, [27, 6], [27, 20]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [27, 0], [34, 7]]]], ["block", "mpf-magellan-section", [], ["titel", "Texte", "class", "editor clearfix"], 3, null, ["loc", [null, [36, 2], [109, 27]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 113,
                "column": 2
              },
              "end": {
                "line": 113,
                "column": 68
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("Abbrechen");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 116,
                  "column": 6
                },
                "end": {
                  "line": 116,
                  "column": 88
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Löschen");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 115,
                "column": 4
              },
              "end": {
                "line": 117,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "link-to", ["admin-page.page.edit.delete"], ["class", "radius alert tiny button"], 0, null, ["loc", [null, [116, 6], [116, 100]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 120,
                  "column": 4
                },
                "end": {
                  "line": 120,
                  "column": 94
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("Speichern");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes() {
              return [];
            },
            statements: [],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 119,
                "column": 2
              },
              "end": {
                "line": 121,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["block", "link-to", ["admin-page.page.edit.save"], ["class", "right radius success tiny button"], 0, null, ["loc", [null, [120, 4], [120, 106]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 112,
              "column": 0
            },
            "end": {
              "line": 122,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("span");
          dom.setAttribute(el1, "class", "right");
          dom.setAttribute(el1, "style", "margin-left: 1.25em;");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[1] = dom.createMorphAt(dom.childAt(fragment, [3]), 1, 1);
          morphs[2] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "link-to", ["admin-page.page"], ["class", "button radius tiny"], 0, null, ["loc", [null, [113, 2], [113, 80]]]], ["block", "if", [["get", "id", ["loc", [null, [115, 10], [115, 12]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [115, 4], [117, 11]]]], ["block", "if", [["get", "model.name", ["loc", [null, [119, 8], [119, 18]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [119, 2], [121, 9]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 125,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/admin-page/page/edit/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "mpf-magellan", [], [], 0, null, ["loc", [null, [1, 0], [110, 17]]]], ["block", "knopf-bar", [], [], 1, null, ["loc", [null, [112, 0], [122, 14]]]], ["content", "outlet", ["loc", [null, [124, 0], [124, 10]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});