define('mpforst-ember/app/pods/grunddaten-bestand/components/x-details/x-grunddaten/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    revierList: Ember.computed(function () {
      return Ember.getOwner(this).lookup('controller:grunddaten-teilbetrieb').get('model.reviere').map(function (x) {
        return {
          value: x.id,
          caption: x.bezeichnung
        };
      });
    }),
    JaNeinItems: [{
      value: false,
      caption: 'Nein'
    }, {
      value: true,
      caption: 'Ja'
    }],
    FlaecheQuelleItems: [{
      value: false,
      caption: 'Eingabe'
    }, {
      value: true,
      caption: 'Upload'
    }]
  });
});