define('mpforst-ember/app/pods/components/kennzahlen-dirty/component', ['exports', 'mpforst-ember/app/pods/assistent-kennzahlen-setup/auswahl/save/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',

    isBetrieb: Ember.computed('betrieb_id', function () {
      return (this.get('betrieb_id') || 0) !== 0;
    }),
    isTeilbetrieb: Ember.computed('teilbetrieb_id', function () {
      return (this.get('teilbetrieb_id') || 0) !== 0;
    }),

    countPromise: Ember.computed('betrieb_id', 'teilbetrieb_id', function () {
      var setup = {
        betrieb_id: this.get('betrieb_id') || 0,
        teilbetrieb_id: this.get('teilbetrieb_id') || 0
      };
      return Ember.$.ajax({
        method: 'POST',
        url: 'api/kennzahlen',
        dataType: 'json',
        data: JSON.stringify(setup),
        contentType: "application/json"
      }).then(function (x) {
        return x.count;
      });
    }),

    actions: {
      close: function close() {
        this.set('popup', false);
      },
      update: function update() {
        this.set('popup', true);
      },
      calc: function calc() {
        var _this = this;

        this.set('popup', false);
        this.set('updating', true);

        // start calulation
        // ist proto public ?

        _route.default.proto().model({
          betrieb: this.get('betrieb_id') || 0,
          teilbetrieb: this.get('teilbetrieb_id') || 0
        }).then(function () {
          Ember.run(function () {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }
            _this.set('updating', false);
            _this.sendAction('refresh');
          });
        }, function () {
          throw new Error('error refresh kennzahlen');
        });
      }
    }
  });
});