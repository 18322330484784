define('mpforst-ember/app/pods/assistent-taxation-result/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() /*param*/{
      var entwurf = this.modelFor('assistent-taxation-entwurf.selected');
      return Ember.$.getJSON('api/entwurfTaxation/Result/' + entwurf.id).then(function (x) {
        return Ember.Object.create(x);
      });
    },
    afterModel: function afterModel() {
      var entwurf = this.modelFor('assistent-taxation-entwurf.selected');
      this.controllerFor(this.get('routeName')).set('entwurf', entwurf);
    }
  });
});