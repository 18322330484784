define('mpforst-ember/app/pods/components/mpf-input/x-textarea/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    edit: true,
    value: null
  });
});