define('mpforst-ember/app/pods/assistent-wzpx-result/index/components/x-bestand-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    vorrat_wzp_ort: Ember.computed('model.vorrat_wzp', 'flaeche', function () {
      return this.get('model.vorrat_wzp') * this.get('flaeche');
    }),
    zuwachs_ort: Ember.computed('model.zuwachs', 'flaeche', function () {
      return this.get('model.zuwachs') * this.get('flaeche');
    })
  });
});