define('mpforst-ember/app/pods/info-hilfe/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(param) {
      var toc = Ember.$.getJSON('api/page').then(function (arr) {
        for (var i = 0; i < arr.length; ++i) {
          var a = arr[i];
          if (a.parent) {
            var p = arr.find(function (x) {
              return x.id == a.parent;
            });
            if (p) {
              p.model = p.model || [];
              p.model.push(a);
            }
          }
        }
        // only take "Hilfe
        return arr.find(function (x) {
          return x.id == 'Hilfe';
        }).model;
      });

      return toc;
    }
  });
});