define('mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-beschreibung/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    removeKennzahlenPopup: false,
    wuchsklassenObserver: Ember.observer('model.beschreibung.dominierende_wuchsklasse_id', function () {
      var wuchsklasse = this.get('model.beschreibung.dominierende_wuchsklasse_id');
      var kennzahlen = this.get('model.kennzahlen.length') > 0;
      if (kennzahlen && wuchsklasse === 1) {
        this.set('removeKennzahlenPopup', true);
      }
    }),
    wuchsklasse_id: Ember.computed('model.beschreibung.dominierende_wuchsklasse_id', function () {
      return this.get('model.beschreibung.dominierende_wuchsklasse_id') || 0;
    }),

    actions: {
      abortRemoveKennzahlen: function abortRemoveKennzahlen() {
        this.set('removeKennzahlenPopup', false);
        this.set('model.beschreibung.dominierende_wuchsklasse_id', null);
      },
      okayRemoveKennzahlen: function okayRemoveKennzahlen() {
        this.set('removeKennzahlenPopup', false);
      }
    }
  });
});