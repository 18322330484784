define('mpforst-ember/app/pods/components/mpf-help-tooltip/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'sup',
    classNames: ['mpf-input-tooltip'],
    attributeBindings: ['title'],
    title: 'Hilfe',
    tooltips: Ember.inject.service('tooltips'),
    popups: Ember.inject.service('popups'),
    cards: ['editor-html-card', 'editor-image-card', 'editor-br-card', 'editor-latex-card'],
    atoms: ['editor-link-atom'],

    area: null, //z.B Betrieb, Teilbetrieb, Bestand
    name: null, //z.B NameDesBetriebes

    tooltip: Ember.computed('area', 'name', 'tooltips.items', function () {
      var area = this.get('area');
      var name = this.get('name');
      return this.get('tooltips.items').find(function (x) {
        return x.area === area && x.name === name;
      });
    }),
    subject: Ember.computed.alias('tooltip.subject'),
    text: Ember.computed.alias('tooltip.short'),

    mouseEnter: function mouseEnter() {
      this.set('visible', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('visible', false);
    },


    force_show: Ember.computed(function () {
      return Ember.getOwner(this).lookup("controller:application").get('model.admin');
    }),

    show: Ember.computed('subject', 'name', 'area', function () {
      if (!this.get('name') && !this.get('area')) {
        return false;
      }
      return this.get('force_show') || this.get('subject');
    }),

    actions: {
      openHelp: function openHelp(area, name) {
        this.get('popups').openHelp('Quickinfo ' + area, 'hilfe-tooltips/' + area + '/' + name);
      }
    }
  });
});