define('mpforst-ember/app/pods/admin-file/index/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    buttons: [{
      title: 'Neue Datei anlegen',
      action: 'new',
      class: 'success'
    }],
    actions: {
      new: function _new() {
        this.transitionToRoute('admin-file.neu');
      },
      delete: function _delete(name) {
        var _this = this;

        Ember.$.ajax('api/file/delete/' + name).then(function () {
          _this.get('target').router.refresh();
        });
      },
      actionbarHandling: function actionbarHandling(action) {
        this.send.apply(this, [action].concat(_toConsumableArray(Array.prototype.slice.call(arguments, 1))));
      }
    }
  });
});