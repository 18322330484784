define('mpforst-ember/app/pods/assistent-wzpx-entwurf/auswahl/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    breadCrumb: "Entwurf",
    selectedEntwurf: Ember.computed('model', 'selectedEntwurfId', function () {
      var selected = this.get('selectedEntwurfId');
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = this.get('model')[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var item = _step.value;

          /*jshint eqeqeq: false*/
          if (item.id == selected) {
            return item;
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }),
    actions: {
      newWzpx: function newWzpx() {
        var _this = this;

        Ember.$.ajax({
          method: 'POST',
          url: 'api/entwurfWzpx'
        }).then(function (data) {
          Ember.run(function () {
            _this.transitionToRoute('assistent-wzpx-entwurf.selected', data.id);
          });
        }, function () {
          // ignore
        });
      }
    }
  });
});