define('mpforst-ember/app/helpers/in-array', ['exports', 'ember-mobiledoc-editor/helpers/in-array'], function (exports, _inArray) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _inArray.default;
    }
  });
  Object.defineProperty(exports, 'inArray', {
    enumerable: true,
    get: function () {
      return _inArray.inArray;
    }
  });
});