define('mpforst-ember/app/pods/admin-stats/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  exports.default = Ember.Controller.extend({
    breadCrumb: 'Statistik',

    csv: Ember.computed('model.stats2', function () {
      function toNum(x) {
        if (x === null || x === undefined) {
          return '';
        }
        return ("" + x).replace('.', ',');
      }
      function toText(x) {
        if (x === null || x === undefined) {
          return '';
        }
        return x;
      }
      var m = this.get('model.stats2');
      var csv = [["Bundesland", "Jahr", "Monat", "Type", "Betriebe", "Teilbetriebe", "Bestaende", "Bestand sum. Flaeche", "Bestand min. Flaeche", "Bestand max. Flaeche"].join(';')].concat(_toConsumableArray(m.map(function (x) {
        return [toText(x.bundesland), toText(x.jahr), toText(x.monat), toText(x.typ), toText(x.anzahl_betriebe), toText(x.anzahl_teilbetriebe), toText(x.anzahl_bestaende), toNum(x.flaeche_ha_sum), toNum(x.flaeche_ha_min), toNum(x.flaeche_ha_max)].join(';');
      }))).join('\r\n');
      return 'data:text/csv;base64,' + btoa(csv);
    }),
    actions: {
      ie11hack: function ie11hack(csv, e) {
        if (window.navigator.msSaveOrOpenBlob) {
          e.preventDefault();

          var oj = atob(csv.split(',', 2)[1]).split('').map(function (x) {
            return x.charCodeAt(0);
          });

          var bb = new Uint8Array(oj);

          var blob = new Blob([bb], { type: 'text/csv' });
          window.navigator.msSaveOrOpenBlob(blob, 'statistik2.csv');
        }
      }
    }
  });
});