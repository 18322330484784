define('mpforst-ember/app/pods/components/editor-html-card/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    payload: null,
    html: Ember.computed.alias('payload.html'),
    showEditor: false,
    htmlCorrected: Ember.computed('html', function () {
      var d = document.createElement('div');
      d.innerHTML = this.get('html');
      console.log(d.innerHTML);
      return d.innerHTML;
    }),
    actions: {
      close: function close() {
        this.sendAction('saveCard', {
          html: this.get('html')
        });
        this.set('showEditor', false);
        this.get('editor').enableEditing();
      },
      open: function open() {
        this.get('editor').disableEditing(); //fixes stupid bugs..
        this.set('showEditor', true);
      }
    }
  });
});