define('mpforst-ember/app/pods/info-hilfe-tooltips/index/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var empty_doc = { version: "0.3.1", atoms: [], cards: [], markups: [], sections: [] };

  exports.default = Ember.Route.extend({
    model: function model(param) {
      var area = this.paramsFor('info-hilfe-tooltips').area;
      return Ember.$.getJSON('api/tooltips?with_long=True').then(function (x) {
        return x.find(function (x) {
          return x.name == param.name && x.area == area;
        });
      }).then(function (y) {
        var res = function res() {
          return {
            auswahlen_text: y.auswahlen_text,
            sections: [{
              title: y.subject,
              content: y.long || empty_doc
            }]
          };
        };

        if (y.auswahlen) {
          return Ember.$.getJSON(y.auswahlen).then(function (z) {
            // modifi y
            y.auswahlen_text = z.map(function (aa) {
              return {
                value: aa.value,
                caption: aa.caption,
                text: y.auswahlen_text.find(function (um) {
                  return um.value == aa.value;
                })
              };
            });
            return res();
          });
        } else {
          y.auswahlen_text = null;
          return res();
        }
      });
    }
  });
});