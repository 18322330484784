define('mpforst-ember/app/pods/assistent-wzpx-setup/selected/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      var model = this.modelFor('assistent-wzpx-entwurf.selected');
      return model;
    },
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'assistent-wzpx-setup.selected.index') {
        // force save
        return Ember.$.ajax({
          method: 'PUT',
          url: 'api/entwurfWzpx/' + model.get('id'),
          data: JSON.stringify(model),
          contentType: "application/json; charset=utf-8",
          dataType: "json"
        });
      }
    }
  });
});