define('mpforst-ember/app/pods/report-betrieb/generate/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(param) {
      return Ember.$.ajax({
        method: 'POST',
        url: 'api/report',
        data: JSON.stringify({
          reportId: 3,
          id: parseInt(param.betrieb_id)
        }),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function (x) {
        if (x.download) {
          var start = x.download.indexOf('/api');
          if (start !== -1) {
            x.download = x.download.substr(start + 1);
          }
        }
        return x;
      });
    },
    afterModel: function afterModel(model) {
      if (model && model.download) {
        var $iframe = Ember.$(document.createElement('iframe'));
        $iframe.css({
          visibility: 'hidden',
          position: 'absolute',
          width: 1,
          height: 1,
          left: 1,
          top: 1
        });
        $iframe.appendTo(Ember.$('body'));
        $iframe.attr('src', model.download);
        window.setTimeout(function () {
          $iframe.remove();
        }.bind($iframe), 60 * 1000); //nach 60 sek löschen
      }
      this.replaceWith('report-betrieb');
    }
  });
});