define('mpforst-ember/app/pods/debug/error-test/route', ['exports'], function (exports) {
    'use strict';

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = Ember.Route.extend({
        model: function model() {
            return Ember.$.ajax('http://franz.am.klo');
        }
    });
});