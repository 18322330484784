define("mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 12
                },
                "end": {
                  "line": 6,
                  "column": 12
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("                ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "text", "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [5, 45], [5, 49]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.bezeichnung", ["loc", [null, [5, 56], [5, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "maxlength", 50], ["loc", [null, [5, 16], [5, 88]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 8
              },
              "end": {
                "line": 7,
                "column": 8
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Name des Teilbetriebs", "area", "Teilbetrieb", "name", "Name", "required", true], 0, null, ["loc", [null, [4, 12], [6, 33]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 10,
                  "column": 10
                },
                "end": {
                  "line": 12,
                  "column": 10
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("            ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "class", "tiny button tiny radius");
              dom.setAttribute(el1, "style", "display: block");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element9 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createElementMorph(element9);
              morphs[1] = dom.createMorphAt(element9, 0, 0);
              return morphs;
            },
            statements: [["element", "action", ["mapOpen"], [], ["loc", [null, [11, 75], [11, 95]]], 0, 0], ["inline", "if", [["get", "edit", ["loc", [null, [11, 101], [11, 105]]], 0, 0, 0, 0], "Teilbetrieb verorten", "Landkarte anzeigen"], [], ["loc", [null, [11, 96], [11, 151]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 9,
                "column": 8
              },
              "end": {
                "line": 13,
                "column": 8
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", ["subexpr", "if", [["get", "edit", ["loc", [null, [10, 40], [10, 44]]], 0, 0, 0, 0], "Weitere Parameter über eine Koordinate bestimmen"], [], ["loc", [null, [10, 36], [10, 96]]], 0, 0], "area", "Teilbetrieb", "name", "Verorten"], 0, null, ["loc", [null, [10, 10], [12, 31]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 18,
                      "column": 16
                    },
                    "end": {
                      "line": 20,
                      "column": 16
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "select", "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [19, 49], [19, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.wuchsgebiet_id", ["loc", [null, [19, 60], [19, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/wuchsgebiet"], ["loc", [null, [19, 18], [19, 117]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 22,
                      "column": 16
                    },
                    "end": {
                      "line": 24,
                      "column": 16
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "select", "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [23, 49], [23, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.betriebsart_id", ["loc", [null, [23, 60], [23, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/betriebsart"], ["loc", [null, [23, 18], [23, 117]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child2 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 16
                    },
                    "end": {
                      "line": 28,
                      "column": 16
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "number", "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [27, 49], [27, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.mit_see_hoehe", ["loc", [null, [27, 60], [27, 79]]], 0, 0, 0, 0]], [], [], 0, 0], "postfix", "m", "min", 0, "max", 3000, "fraction", 0], ["loc", [null, [27, 18], [27, 119]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child3 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 30,
                      "column": 16
                    },
                    "end": {
                      "line": 32,
                      "column": 16
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "number", "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [31, 49], [31, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.d_temp", ["loc", [null, [31, 60], [31, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "postfix", "°C", "min", -5, "max", 10, "fraction", 0], ["loc", [null, [31, 18], [31, 112]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child4 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 34,
                      "column": 16
                    },
                    "end": {
                      "line": 36,
                      "column": 16
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                  ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "number", "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [35, 49], [35, 53]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "model.d_niederschlag", ["loc", [null, [35, 60], [35, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "postfix", "%", "min", -50, "max", 50, "fraction", 0], ["loc", [null, [35, 18], [35, 120]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 17,
                    "column": 12
                  },
                  "end": {
                    "line": 37,
                    "column": 12
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(5);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
                morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
                morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-label", [], ["titel", "Wuchsgebiet", "area", "Teilbetrieb", "name", "Wuchsgebiet", "required", true, "kennzahlen", true], 0, null, ["loc", [null, [18, 16], [20, 37]]]], ["block", "foundation-label", [], ["titel", "Betriebsart", "area", "Teilbetrieb", "name", "Betriebsart", "required", true, "kennzahlen", true], 1, null, ["loc", [null, [22, 16], [24, 37]]]], ["block", "foundation-label", [], ["titel", "Mittlere Seehöhe des Teilbetriebs", "area", "Teilbetrieb", "name", "Seehoehe", "required", true], 2, null, ["loc", [null, [26, 16], [28, 37]]]], ["block", "foundation-label", [], ["titel", "Temperaturänderung", "required", true], 3, null, ["loc", [null, [30, 16], [32, 37]]]], ["block", "foundation-label", [], ["titel", "Niederschlagsänderung", "required", true], 4, null, ["loc", [null, [34, 16], [36, 37]]]]],
              locals: [],
              templates: [child0, child1, child2, child3, child4]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 41,
                        "column": 16
                      },
                      "end": {
                        "line": 45,
                        "column": 16
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "like-input");
                    var el2 = dom.createTextNode("\n                    ");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createComment("");
                    dom.appendChild(el1, el2);
                    var el2 = dom.createTextNode(" in EPSG:4326 (WGS1984)\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element8 = dom.childAt(fragment, [1]);
                    var morphs = new Array(2);
                    morphs[0] = dom.createAttrMorph(element8, 'style');
                    morphs[1] = dom.createMorphAt(element8, 1, 1);
                    return morphs;
                  },
                  statements: [["attribute", "style", ["concat", [["subexpr", "if", [["get", "edit", ["loc", [null, [42, 54], [42, 58]]], 0, 0, 0, 0], "min-height: 2.7em"], [], ["loc", [null, [42, 49], [42, 80]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "format-latlng", [["get", "model.lat", ["loc", [null, [43, 36], [43, 45]]], 0, 0, 0, 0], ["get", "model.lng", ["loc", [null, [43, 46], [43, 55]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 20], [43, 57]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 45,
                        "column": 16
                      },
                      "end": {
                        "line": 49,
                        "column": 16
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("div");
                    dom.setAttribute(el1, "class", "like-input");
                    var el2 = dom.createTextNode("\n                    -\n                  ");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var element7 = dom.childAt(fragment, [1]);
                    var morphs = new Array(1);
                    morphs[0] = dom.createAttrMorph(element7, 'style');
                    return morphs;
                  },
                  statements: [["attribute", "style", ["concat", [["subexpr", "if", [["get", "edit", ["loc", [null, [46, 54], [46, 58]]], 0, 0, 0, 0], "min-height: 2.7em"], [], ["loc", [null, [46, 49], [46, 80]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 40,
                      "column": 14
                    },
                    "end": {
                      "line": 50,
                      "column": 14
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["subexpr", "and", [["get", "model.lat", ["loc", [null, [41, 27], [41, 36]]], 0, 0, 0, 0], ["get", "model.lng", ["loc", [null, [41, 37], [41, 46]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 22], [41, 47]]], 0, 0]], [], 0, 1, ["loc", [null, [41, 16], [49, 23]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 52,
                      "column": 14
                    },
                    "end": {
                      "line": 56,
                      "column": 14
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "like-input");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element6 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element6, 'style');
                  morphs[1] = dom.createMorphAt(element6, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "style", ["concat", [["subexpr", "if", [["get", "edit", ["loc", [null, [53, 52], [53, 56]]], 0, 0, 0, 0], "min-height: 2.7em"], [], ["loc", [null, [53, 47], [53, 78]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "if", [["get", "model.bundesland", ["loc", [null, [54, 23], [54, 39]]], 0, 0, 0, 0], ["get", "model.bundesland", ["loc", [null, [54, 40], [54, 56]]], 0, 0, 0, 0], "-"], [], ["loc", [null, [54, 18], [54, 62]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child2 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 14
                    },
                    "end": {
                      "line": 62,
                      "column": 14
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "like-input");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element5, 'style');
                  morphs[1] = dom.createMorphAt(element5, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "style", ["concat", [["subexpr", "if", [["get", "edit", ["loc", [null, [59, 52], [59, 56]]], 0, 0, 0, 0], "min-height: 2.7em"], [], ["loc", [null, [59, 47], [59, 78]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "if", [["get", "model.bezirk", ["loc", [null, [60, 23], [60, 35]]], 0, 0, 0, 0], ["get", "model.bezirk", ["loc", [null, [60, 36], [60, 48]]], 0, 0, 0, 0], "-"], [], ["loc", [null, [60, 18], [60, 54]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child3 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 14
                    },
                    "end": {
                      "line": 68,
                      "column": 14
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("                ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "class", "like-input");
                  var el2 = dom.createTextNode("\n                  ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n                ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element4 = dom.childAt(fragment, [1]);
                  var morphs = new Array(2);
                  morphs[0] = dom.createAttrMorph(element4, 'style');
                  morphs[1] = dom.createMorphAt(element4, 1, 1);
                  return morphs;
                },
                statements: [["attribute", "style", ["concat", [["subexpr", "if", [["get", "edit", ["loc", [null, [65, 52], [65, 56]]], 0, 0, 0, 0], "min-height: 2.7em"], [], ["loc", [null, [65, 47], [65, 78]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["inline", "if", [["get", "model.pefc", ["loc", [null, [66, 23], [66, 33]]], 0, 0, 0, 0], ["get", "model.pefc", ["loc", [null, [66, 34], [66, 44]]], 0, 0, 0, 0], "-"], [], ["loc", [null, [66, 18], [66, 50]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 39,
                    "column": 12
                  },
                  "end": {
                    "line": 69,
                    "column": 12
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(4);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
                morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-label", [], ["titel", "Koordinaten", "area", "Teilbetrieb", "name", "Koordinaten"], 0, null, ["loc", [null, [40, 14], [50, 35]]]], ["block", "foundation-label", [], ["titel", "Bundesland", "area", "Teilbetrieb", "name", "Bundesland"], 1, null, ["loc", [null, [52, 14], [56, 35]]]], ["block", "foundation-label", [], ["titel", "Bezirk", "area", "Teilbetrieb", "name", "Bezirk"], 2, null, ["loc", [null, [58, 14], [62, 35]]]], ["block", "foundation-label", [], ["titel", "PEFC-Region", "area", "Teilbetrieb", "name", "PEFC"], 3, null, ["loc", [null, [64, 14], [68, 35]]]]],
              locals: [],
              templates: [child0, child1, child2, child3]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 16,
                  "column": 10
                },
                "end": {
                  "line": 70,
                  "column": 10
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-row-column", [], ["large", 6], 0, null, ["loc", [null, [17, 12], [37, 38]]]], ["block", "foundation-row-column", [], ["large", 6], 1, null, ["loc", [null, [39, 12], [69, 38]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 15,
                "column": 8
              },
              "end": {
                "line": 71,
                "column": 8
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-column", [], ["large", 12, "class", "no-padding-left-right"], 0, null, ["loc", [null, [16, 10], [70, 32]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 2,
              "column": 4
            },
            "end": {
              "line": 72,
              "column": 4
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-row-column", [], ["large", 6], 0, null, ["loc", [null, [3, 8], [7, 34]]]], ["block", "foundation-row-column", [], ["large", 6, "class", "columns-no-padding"], 1, null, ["loc", [null, [9, 8], [13, 34]]]], ["block", "unless", [["get", "hideForNew", ["loc", [null, [15, 18], [15, 28]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [15, 8], [71, 19]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 89,
                "column": 12
              },
              "end": {
                "line": 91,
                "column": 12
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("              ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("button");
            dom.setAttribute(el1, "class", "button radius success tiny right no-mb");
            var el2 = dom.createTextNode("Koordinaten übernehmen");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [1]);
            var morphs = new Array(1);
            morphs[0] = dom.createElementMorph(element0);
            return morphs;
          },
          statements: [["element", "action", ["mapSave"], [], ["loc", [null, [90, 69], [90, 89]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 74,
              "column": 4
            },
            "end": {
              "line": 95,
              "column": 4
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reveal-modal-bg");
          dom.setAttribute(el1, "style", "display: block;");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n      ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reveal-modal open");
          dom.setAttribute(el1, "data-reveal", "");
          dom.setAttribute(el1, "aria-labelledby", "errorModalTitle");
          dom.setAttribute(el1, "aria-hidden", "false");
          dom.setAttribute(el1, "role", "dialog");
          dom.setAttribute(el1, "style", "padding: 0.5em;\n          position: fixed;\n          display: block;\n          opacity: 1;\n          visibility: visible;\n          top: 88px;\n          left: 50%;\n          top: 50%;\n          transform: translate(-50%, -50%);\n          width: auto;");
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n          ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("div");
          var el3 = dom.createTextNode("\n");
          dom.appendChild(el2, el3);
          var el3 = dom.createComment("");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("            ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("button");
          dom.setAttribute(el3, "class", "button radius secondary tiny no-mb");
          var el4 = dom.createTextNode("Zurück");
          dom.appendChild(el3, el4);
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n          ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element1 = dom.childAt(fragment, [3]);
          var element2 = dom.childAt(element1, [3]);
          var element3 = dom.childAt(element2, [3]);
          var morphs = new Array(3);
          morphs[0] = dom.createMorphAt(element1, 1, 1);
          morphs[1] = dom.createMorphAt(element2, 1, 1);
          morphs[2] = dom.createElementMorph(element3);
          return morphs;
        },
        statements: [["inline", "grunddaten-teilbetrieb/components/x-map", [], ["class", "map-iframe", "lat", ["subexpr", "@mut", [["get", "lat_tmp", ["loc", [null, [87, 75], [87, 82]]], 0, 0, 0, 0]], [], [], 0, 0], "lng", ["subexpr", "@mut", [["get", "lng_tmp", ["loc", [null, [87, 87], [87, 94]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [87, 100], [87, 104]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [87, 10], [87, 106]]], 0, 0], ["block", "if", [["get", "edit", ["loc", [null, [89, 18], [89, 22]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [89, 12], [91, 19]]]], ["element", "action", ["mapClose"], [], ["loc", [null, [92, 63], [92, 84]]], 0, 0]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 103,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-details/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("form");
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        var el2 = dom.createComment("");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element10 = dom.childAt(fragment, [0]);
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(element10, 1, 1);
        morphs[1] = dom.createMorphAt(element10, 3, 3);
        return morphs;
      },
      statements: [["block", "foundation-row", [], ["class", "clearfix"], 0, null, ["loc", [null, [2, 4], [72, 23]]]], ["block", "if", [["get", "isMapVisible", ["loc", [null, [74, 10], [74, 22]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [74, 4], [95, 11]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});