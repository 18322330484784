define('mpforst-ember/app/pods/grunddaten-bestand-neu/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    templateName: 'grunddaten-bestand.anzeigen',
    model: function model() {
      var params = this.paramsFor('grunddaten-teilbetrieb');
      return Ember.$.getJSON('api/bestand/0?teilbetrieb_id=' + params.teilbetrieb).then(function (x) {
        return Ember.Object.create(x);
      });
    }
  });
});