define('mpforst-ember/app/pods/admin-activity/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model() {
      return Ember.$.ajax('api/monitor/session').then(function (x) {
        return Ember.A(x.map(function (y) {
          return Ember.Object.create(y);
        }));
      });
    }
  });
});