define("mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 4,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "mpf-actionbar", [], ["buttons", ["subexpr", "@mut", [["get", "buttons", ["loc", [null, [3, 28], [3, 35]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "actionbarHandling"], ["loc", [null, [3, 4], [3, 64]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 2
              },
              "end": {
                "line": 13,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "mpf-actionbar", [], ["buttons", ["subexpr", "@mut", [["get", "assistents", ["loc", [null, [12, 28], [12, 38]]], 0, 0, 0, 0]], [], [], 0, 0], "action", "actionbarHandling", "title", "Tools"], ["loc", [null, [12, 4], [12, 81]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 35,
                "column": 2
              },
              "end": {
                "line": 37,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("    ");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
            return morphs;
          },
          statements: [["inline", "grunddaten-teilbetrieb/components/x-details", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [36, 56], [36, 61]]], 0, 0, 0, 0]], [], [], 0, 0], "hideForNew", ["subexpr", "@mut", [["get", "hideForNew", ["loc", [null, [36, 73], [36, 83]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [36, 89], [36, 93]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 4], [36, 95]]], 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 42,
                      "column": 8
                    },
                    "end": {
                      "line": 52,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "data-alert", "");
                  dom.setAttribute(el1, "style", "margin: 1em;");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("h3");
                  var el3 = dom.createElement("i");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("  Regionale Standardwerte laden");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("p");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("ul");
                  var el4 = dom.createTextNode("\n                ");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createElement("li");
                  var el5 = dom.createTextNode("Die Standardwerte für ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("b");
                  var el6 = dom.createTextNode("Ertragstafeln/Baumartengruppen/Zieldurchmesser");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode(" und das ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("b");
                  var el6 = dom.createTextNode("Waldbaukonzept");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode(" werden durch die Auswahl vom ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("b");
                  var el6 = dom.createTextNode("Wuchsgebiet");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode(" und der ");
                  dom.appendChild(el4, el5);
                  var el5 = dom.createElement("b");
                  var el6 = dom.createTextNode("Betriebsart");
                  dom.appendChild(el5, el6);
                  dom.appendChild(el4, el5);
                  var el5 = dom.createTextNode(" beeinflusst.");
                  dom.appendChild(el4, el5);
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("button");
                  dom.setAttribute(el2, "class", "button tiny radius no-mb");
                  var el3 = dom.createTextNode("Standardwerte laden");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element5 = dom.childAt(fragment, [1]);
                  var element6 = dom.childAt(element5, [1, 0]);
                  var element7 = dom.childAt(element5, [5]);
                  var morphs = new Array(3);
                  morphs[0] = dom.createAttrMorph(element5, 'class');
                  morphs[1] = dom.createAttrMorph(element6, 'class');
                  morphs[2] = dom.createElementMorph(element7);
                  return morphs;
                },
                statements: [["attribute", "class", ["concat", ["alert-box ", ["subexpr", "if", [["get", "warnVorgabewerte", ["loc", [null, [43, 48], [43, 64]]], 0, 0, 0, 0], "info", "secondary"], [], ["loc", [null, [43, 43], [43, 85]]], 0, 0], " radius"], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "class", ["concat", ["fa ", ["subexpr", "if", [["get", "warnVorgabewerte", ["loc", [null, [44, 34], [44, 50]]], 0, 0, 0, 0], "fa-info", "fa-exclamation-circle"], [], ["loc", [null, [44, 29], [44, 86]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["element", "action", ["loadDefaults"], [], ["loc", [null, [50, 20], [50, 45]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 41,
                    "column": 6
                  },
                  "end": {
                    "line": 53,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-column", [], ["large", 12], 0, null, ["loc", [null, [42, 8], [52, 30]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 4
                },
                "end": {
                  "line": 54,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-row", [], ["class", "clearfix"], 0, null, ["loc", [null, [41, 6], [53, 25]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child1 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 76,
                        "column": 14
                      },
                      "end": {
                        "line": 78,
                        "column": 14
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                  ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "grunddaten-teilbetrieb/components/x-gruppe-row", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [77, 73], [77, 78]]], 0, 0, 0, 0]], [], [], 0, 0], "item", ["subexpr", "@mut", [["get", "item", ["loc", [null, [77, 84], [77, 88]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [77, 94], [77, 98]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [77, 18], [77, 100]]], 0, 0]],
                  locals: ["item"],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 58,
                      "column": 8
                    },
                    "end": {
                      "line": 81,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("table");
                  dom.setAttribute(el1, "class", "table-no-tbody-padding custom-table");
                  dom.setAttribute(el1, "style", "width: 100%;");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("col");
                  dom.setAttribute(el2, "style", "width: 20em");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("thead");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("th");
                  var el4 = dom.createTextNode("\n                Baumart");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("th");
                  var el4 = dom.createTextNode("\n                Baumartengruppe");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("th");
                  var el4 = dom.createTextNode("\n                Ertragstafel");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("th");
                  var el4 = dom.createTextNode("\n                Zieldurchmesser");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("tbody");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element3 = dom.childAt(fragment, [1]);
                  var element4 = dom.childAt(element3, [3]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element4, [3]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element4, [5]), 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element4, [7]), 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element3, [5]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "Baumart"], ["loc", [null, [63, 23], [63, 77]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "Baumartengruppe"], ["loc", [null, [66, 31], [66, 93]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "Ertragstafeln"], ["loc", [null, [69, 28], [69, 88]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "Zieldurchmesser"], ["loc", [null, [72, 31], [72, 93]]], 0, 0], ["block", "each", [["get", "ertragstafel1", ["loc", [null, [76, 22], [76, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [76, 14], [78, 23]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 100,
                        "column": 14
                      },
                      "end": {
                        "line": 102,
                        "column": 14
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 1,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "grunddaten-teilbetrieb/components/x-gruppe-row", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [101, 71], [101, 76]]], 0, 0, 0, 0]], [], [], 0, 0], "item", ["subexpr", "@mut", [["get", "item", ["loc", [null, [101, 82], [101, 86]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [101, 92], [101, 96]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [101, 16], [101, 98]]], 0, 0]],
                  locals: ["item"],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 82,
                      "column": 8
                    },
                    "end": {
                      "line": 105,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("table");
                  dom.setAttribute(el1, "class", "table-no-tbody-padding custom-table");
                  dom.setAttribute(el1, "style", "width: 100%;");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("col");
                  dom.setAttribute(el2, "style", "width: 20em");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("thead");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("th");
                  var el4 = dom.createTextNode("\n                Baumart");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("th");
                  var el4 = dom.createTextNode("\n                Baumartengruppe");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("th");
                  var el4 = dom.createTextNode("\n                Ertragstafel");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("th");
                  var el4 = dom.createTextNode("\n                Zieldurchmesser");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("\n              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("tbody");
                  var el3 = dom.createTextNode("\n");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createComment("");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element1 = dom.childAt(fragment, [1]);
                  var element2 = dom.childAt(element1, [3]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
                  morphs[1] = dom.createMorphAt(dom.childAt(element2, [3]), 1, 1);
                  morphs[2] = dom.createMorphAt(dom.childAt(element2, [5]), 1, 1);
                  morphs[3] = dom.createMorphAt(dom.childAt(element2, [7]), 1, 1);
                  morphs[4] = dom.createMorphAt(dom.childAt(element1, [5]), 1, 1);
                  return morphs;
                },
                statements: [["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "Baumart"], ["loc", [null, [87, 23], [87, 77]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "Baumartengruppe"], ["loc", [null, [90, 31], [90, 93]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "Ertragstafeln"], ["loc", [null, [93, 28], [93, 88]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "Zieldurchmesser"], ["loc", [null, [96, 31], [96, 93]]], 0, 0], ["block", "each", [["get", "ertragstafel2", ["loc", [null, [100, 22], [100, 35]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [100, 14], [102, 23]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 57,
                    "column": 6
                  },
                  "end": {
                    "line": 106,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-column", [], ["large", 6], 0, null, ["loc", [null, [58, 8], [81, 30]]]], ["block", "foundation-column", [], ["large", 6], 1, null, ["loc", [null, [82, 8], [105, 30]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 108,
                      "column": 8
                    },
                    "end": {
                      "line": 110,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "grunddaten-teilbetrieb/components/x-baumartgruppe", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [109, 68], [109, 73]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [109, 79], [109, 83]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [109, 10], [109, 85]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 107,
                    "column": 6
                  },
                  "end": {
                    "line": 111,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-column", [], ["large", 12], 0, null, ["loc", [null, [108, 8], [110, 30]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 56,
                  "column": 4
                },
                "end": {
                  "line": 112,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-row", [], [], 0, null, ["loc", [null, [57, 6], [106, 25]]]], ["block", "foundation-row", [], ["class", "clearfix"], 1, null, ["loc", [null, [107, 6], [111, 25]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        var child2 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 115,
                    "column": 6
                  },
                  "end": {
                    "line": 117,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "grunddaten-teilbetrieb/components/x-waldbaukonzept", [], ["model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [116, 67], [116, 72]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [116, 78], [116, 82]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [116, 8], [116, 84]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 114,
                  "column": 4
                },
                "end": {
                  "line": 118,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [115, 6], [117, 32]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        var child3 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 120,
                  "column": 4
                },
                "end": {
                  "line": 122,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "grunddaten-teilbetrieb/components/x-reviere", [], ["model", ["subexpr", "@mut", [["get", "model.reviere", ["loc", [null, [121, 58], [121, 71]]], 0, 0, 0, 0]], [], [], 0, 0], "cacheKey", ["subexpr", "concat", ["teilbetrieb_", ["subexpr", "unbound", [["get", "model.id", ["loc", [null, [121, 113], [121, 121]]], 0, 0, 0, 0]], [], ["loc", [null, [121, 104], [121, 122]]], 0, 0], "_reviere"], [], ["loc", [null, [121, 81], [121, 134]]], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [121, 140], [121, 144]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [121, 6], [121, 146]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child4 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 125,
                    "column": 6
                  },
                  "end": {
                    "line": 134,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                return morphs;
              },
              statements: [["inline", "grunddaten-teilbetrieb/components/x-bestaende", [], ["model", ["subexpr", "@mut", [["get", "model.bestaende", ["loc", [null, [126, 62], [126, 77]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [126, 8], [126, 79]]], 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 124,
                  "column": 4
                },
                "end": {
                  "line": 135,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "mpf-magellan-section", [], ["titel", "Bestände", "class", "clearfix"], 0, null, ["loc", [null, [125, 6], [134, 31]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 2
              },
              "end": {
                "line": 136,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(5);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
            morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
            morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
            morphs[4] = dom.createMorphAt(fragment, 8, 8, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "edit", ["loc", [null, [40, 10], [40, 14]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [40, 4], [54, 11]]]], ["block", "mpf-magellan-section", [], ["titel", "Ertragstafeln/Baumartengruppen/Zieldurchmesser", "class", "clearfix", "kennzahlen", true], 1, null, ["loc", [null, [56, 4], [112, 29]]]], ["block", "mpf-magellan-section", [], ["titel", "Waldbaukonzept", "subtitel", "für die Nutzungspotentialanalyse", "class", "clearfix", "kennzahlen", true, "area", "Teilbetrieb", "name", "Waldbaukonzept"], 2, null, ["loc", [null, [114, 4], [118, 29]]]], ["block", "mpf-magellan-section", [], ["titel", "Reviere", "class", "clearfix pb"], 3, null, ["loc", [null, [120, 4], [122, 29]]]], ["block", "unless", [["get", "edit", ["loc", [null, [124, 14], [124, 18]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [124, 4], [135, 15]]]]],
          locals: [],
          templates: [child0, child1, child2, child3, child4]
        };
      }();
      var child4 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 144,
                      "column": 12
                    },
                    "end": {
                      "line": 146,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("li");
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
                  return morphs;
                },
                statements: [["content", "error", ["loc", [null, [145, 18], [145, 27]]], 0, 0, 0, 0]],
                locals: ["error"],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 139,
                    "column": 6
                  },
                  "end": {
                    "line": 150,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("div");
                dom.setAttribute(el1, "data-alert", "");
                dom.setAttribute(el1, "class", "alert-box alert radius");
                dom.setAttribute(el1, "style", "margin: 1em;");
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("h2");
                dom.setAttribute(el2, "style", "color: white");
                var el3 = dom.createElement("i");
                dom.setAttribute(el3, "class", "fa fa-warning");
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("  Fehler");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n          ");
                dom.appendChild(el1, el2);
                var el2 = dom.createElement("p");
                var el3 = dom.createTextNode("\n            ");
                dom.appendChild(el2, el3);
                var el3 = dom.createElement("ul");
                var el4 = dom.createTextNode("\n");
                dom.appendChild(el3, el4);
                var el4 = dom.createComment("");
                dom.appendChild(el3, el4);
                var el4 = dom.createTextNode("            ");
                dom.appendChild(el3, el4);
                dom.appendChild(el2, el3);
                var el3 = dom.createTextNode("\n          ");
                dom.appendChild(el2, el3);
                dom.appendChild(el1, el2);
                var el2 = dom.createTextNode("\n        ");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1, 3, 1]), 1, 1);
                return morphs;
              },
              statements: [["block", "each", [["get", "model.error.messages", ["loc", [null, [144, 20], [144, 40]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [144, 12], [146, 21]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 161,
                        "column": 12
                      },
                      "end": {
                        "line": 161,
                        "column": 107
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Löschen");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 159,
                      "column": 8
                    },
                    "end": {
                      "line": 163,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("span");
                  dom.setAttribute(el1, "class", "right");
                  dom.setAttribute(el1, "style", "margin-left: 1.25em;");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createComment("");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
                  return morphs;
                },
                statements: [["block", "link-to", ["grunddaten-teilbetrieb.bearbeiten.delete"], ["class", "radius alert tiny button"], 0, null, ["loc", [null, [161, 12], [161, 119]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            var child1 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 165,
                        "column": 10
                      },
                      "end": {
                        "line": 165,
                        "column": 106
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Speichern");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 164,
                      "column": 8
                    },
                    "end": {
                      "line": 166,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "link-to", ["grunddaten-teilbetrieb-neu.save"], ["class", "right radius success tiny button"], 0, null, ["loc", [null, [165, 10], [165, 118]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            var child2 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 167,
                        "column": 10
                      },
                      "end": {
                        "line": 167,
                        "column": 113
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("Speichern");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 166,
                      "column": 8
                    },
                    "end": {
                      "line": 168,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "link-to", ["grunddaten-teilbetrieb.bearbeiten.save"], ["class", "right radius success tiny button"], 0, null, ["loc", [null, [167, 10], [167, 125]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 151,
                    "column": 6
                  },
                  "end": {
                    "line": 169,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(2);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "unless", [["get", "new", ["loc", [null, [159, 18], [159, 21]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [159, 8], [163, 19]]]], ["block", "if", [["get", "new", ["loc", [null, [164, 14], [164, 17]]], 0, 0, 0, 0]], [], 1, 2, ["loc", [null, [164, 8], [168, 15]]]]],
              locals: [],
              templates: [child0, child1, child2]
            };
          }();
          var child2 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 175,
                        "column": 16
                      },
                      "end": {
                        "line": 177,
                        "column": 16
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createTextNode("Der Name des Teilbetriebs muss ausgefüllt werden");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 178,
                        "column": 16
                      },
                      "end": {
                        "line": 180,
                        "column": 16
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createTextNode("Die Seehöhe muss ausgefüllt werden");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child2 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 181,
                        "column": 16
                      },
                      "end": {
                        "line": 183,
                        "column": 16
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createTextNode("Die Temperaturänderung muss ausgefüllt werden");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child3 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 184,
                        "column": 16
                      },
                      "end": {
                        "line": 186,
                        "column": 16
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createTextNode("Die Niederschlagsänderung muss ausgefüllt werden");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child4 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 187,
                        "column": 16
                      },
                      "end": {
                        "line": 189,
                        "column": 16
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("li");
                    var el2 = dom.createTextNode("Es muss eine Koordinate auf der Karte ausgewählt werden");
                    dom.appendChild(el1, el2);
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 170,
                      "column": 8
                    },
                    "end": {
                      "line": 193,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("          ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createElement("div");
                  dom.setAttribute(el1, "data-alert", "");
                  dom.setAttribute(el1, "class", "alert-box alert radius");
                  dom.setAttribute(el1, "style", "margin: 1em;");
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("h2");
                  dom.setAttribute(el2, "style", "color: white");
                  var el3 = dom.createElement("i");
                  dom.setAttribute(el3, "class", "fa fa-warning");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("  Fehler");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n            ");
                  dom.appendChild(el1, el2);
                  var el2 = dom.createElement("p");
                  var el3 = dom.createTextNode("\n              ");
                  dom.appendChild(el2, el3);
                  var el3 = dom.createElement("ul");
                  var el4 = dom.createTextNode("\n");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createComment("");
                  dom.appendChild(el3, el4);
                  var el4 = dom.createTextNode("              ");
                  dom.appendChild(el3, el4);
                  dom.appendChild(el2, el3);
                  var el3 = dom.createTextNode("\n            ");
                  dom.appendChild(el2, el3);
                  dom.appendChild(el1, el2);
                  var el2 = dom.createTextNode("\n          ");
                  dom.appendChild(el1, el2);
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var element0 = dom.childAt(fragment, [1, 3, 1]);
                  var morphs = new Array(5);
                  morphs[0] = dom.createMorphAt(element0, 1, 1);
                  morphs[1] = dom.createMorphAt(element0, 2, 2);
                  morphs[2] = dom.createMorphAt(element0, 3, 3);
                  morphs[3] = dom.createMorphAt(element0, 4, 4);
                  morphs[4] = dom.createMorphAt(element0, 5, 5);
                  return morphs;
                },
                statements: [["block", "unless", [["get", "model.bezeichnung", ["loc", [null, [175, 26], [175, 43]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [175, 16], [177, 27]]]], ["block", "unless", [["subexpr", "not-empty", [["get", "model.mit_see_hoehe", ["loc", [null, [178, 37], [178, 56]]], 0, 0, 0, 0]], [], ["loc", [null, [178, 26], [178, 57]]], 0, 0]], [], 1, null, ["loc", [null, [178, 16], [180, 27]]]], ["block", "unless", [["subexpr", "not-empty", [["get", "model.d_temp", ["loc", [null, [181, 37], [181, 49]]], 0, 0, 0, 0]], [], ["loc", [null, [181, 26], [181, 50]]], 0, 0]], [], 2, null, ["loc", [null, [181, 16], [183, 27]]]], ["block", "unless", [["subexpr", "not-empty", [["get", "model.d_niederschlag", ["loc", [null, [184, 37], [184, 57]]], 0, 0, 0, 0]], [], ["loc", [null, [184, 26], [184, 58]]], 0, 0]], [], 3, null, ["loc", [null, [184, 16], [186, 27]]]], ["block", "unless", [["subexpr", "and", [["get", "model.lat", ["loc", [null, [187, 31], [187, 40]]], 0, 0, 0, 0], ["get", "model.lng", ["loc", [null, [187, 41], [187, 50]]], 0, 0, 0, 0]], [], ["loc", [null, [187, 26], [187, 51]]], 0, 0]], [], 4, null, ["loc", [null, [187, 16], [189, 27]]]]],
                locals: [],
                templates: [child0, child1, child2, child3, child4]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 169,
                    "column": 6
                  },
                  "end": {
                    "line": 196,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("button");
                dom.setAttribute(el1, "class", "right radius success tiny button");
                dom.setAttribute(el1, "disabled", "true");
                var el2 = dom.createTextNode("Speichern");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "unless", [["get", "hideForNew", ["loc", [null, [170, 18], [170, 28]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [170, 8], [193, 19]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 138,
                  "column": 4
                },
                "end": {
                  "line": 203,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("button");
              dom.setAttribute(el1, "onclick", "javascript:window.frameElement.actions.aborted()");
              dom.setAttribute(el1, "class", "radius tiny button");
              var el2 = dom.createTextNode("Abbrechen");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(2);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "if", [["get", "model.error", ["loc", [null, [139, 12], [139, 23]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [139, 6], [150, 13]]]], ["block", "if", [["subexpr", "x-and", [["get", "model.lat", ["loc", [null, [152, 8], [152, 17]]], 0, 0, 0, 0], ["get", "model.lng", ["loc", [null, [153, 8], [153, 17]]], 0, 0, 0, 0], ["subexpr", "not-empty", [["get", "model.bezeichnung", ["loc", [null, [154, 19], [154, 36]]], 0, 0, 0, 0]], [], ["loc", [null, [154, 8], [154, 37]]], 0, 0], ["subexpr", "not-empty", [["get", "model.mit_see_hoehe", ["loc", [null, [155, 19], [155, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [155, 8], [155, 39]]], 0, 0], ["subexpr", "not-empty", [["get", "model.d_niederschlag", ["loc", [null, [156, 19], [156, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [156, 8], [156, 40]]], 0, 0], ["subexpr", "not-empty", [["get", "model.d_temp", ["loc", [null, [157, 19], [157, 31]]], 0, 0, 0, 0]], [], ["loc", [null, [157, 8], [157, 32]]], 0, 0]], [], ["loc", [null, [151, 12], [158, 7]]], 0, 0]], [], 1, 2, ["loc", [null, [151, 6], [196, 13]]]]],
            locals: [],
            templates: [child0, child1, child2]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 137,
                "column": 2
              },
              "end": {
                "line": 204,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "knopf-bar", [], [], 0, null, ["loc", [null, [138, 4], [203, 18]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 205,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(5);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          morphs[4] = dom.createMorphAt(fragment, 7, 7, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "if", [["get", "buttons", ["loc", [null, [2, 8], [2, 15]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [2, 2], [4, 9]]]], ["block", "if", [["get", "assistents", ["loc", [null, [11, 8], [11, 18]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [11, 2], [13, 9]]]], ["block", "mpf-magellan-section", [], ["titel", "Allgemein", "class", "clearfix"], 2, null, ["loc", [null, [35, 2], [37, 27]]]], ["block", "unless", [["get", "hideForNew", ["loc", [null, [39, 12], [39, 22]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [39, 2], [136, 13]]]], ["block", "if", [["get", "edit", ["loc", [null, [137, 8], [137, 12]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [137, 2], [204, 9]]]]],
        locals: [],
        templates: [child0, child1, child2, child3, child4]
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 207,
              "column": 0
            },
            "end": {
              "line": 224,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reveal-modal-bg");
          dom.setAttribute(el1, "style", "display: block; background:transparent !important");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "class", "reveal-modal open");
          dom.setAttribute(el1, "data-reveal", "");
          dom.setAttribute(el1, "aria-labelledby", "errorModalTitle");
          dom.setAttribute(el1, "aria-hidden", "false");
          dom.setAttribute(el1, "role", "dialog");
          dom.setAttribute(el1, "style", "padding: 0.5em;\n      position: fixed;\n      display: block;\n      opacity: 1;\n      visibility: visible;\n      top: 88px;\n      left: 50%;\n      top: 50%;\n      transform: translate(-50%, -50%);\n      /* display: inline-block; */\n      width: auto;\n      text-align: center;");
          var el2 = dom.createTextNode("\n      ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("i");
          dom.setAttribute(el2, "class", "fa fa-cog fa-spin");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode(" Bitte warten...\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes() {
          return [];
        },
        statements: [],
        locals: [],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 227,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/anzeigen/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(3);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, 0);
        return morphs;
      },
      statements: [["block", "mpf-magellan", [], ["restoreScrollWith", ["subexpr", "@mut", [["get", "s", ["loc", [null, [1, 34], [1, 35]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [1, 0], [205, 17]]]], ["block", "if", [["get", "loading", ["loc", [null, [207, 6], [207, 13]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [207, 0], [224, 7]]]], ["content", "outlet", ["loc", [null, [226, 0], [226, 10]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});