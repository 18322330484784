define('mpforst-ember/app/pods/assistent-wzpx-result/index/components/x-bestand/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var wuchsklasse_loaded = null;
  var wuchsklasse = Ember.$.getJSON('api/stammdaten/wuchsklasse').then(function (data) {
    wuchsklasse_loaded = data;
    return data;
  });
  var baumart_loaded = null;
  var baumart = Ember.$.getJSON('api/stammdaten/baumart').then(function (data) {
    baumart_loaded = data;
    return data;
  });
  var bestockungsgrad_loaded = null;
  var bestockungsgrad = Ember.$.getJSON('api/stammdaten/wald_best_grd').then(function (data) {
    bestockungsgrad_loaded = data;
    return data;
  });

  exports.default = Ember.Component.extend({
    tagName: 'tbody',
    cachedComponent: null,
    wuchsklasse: null,
    baumart: null,
    bestockungsgrad: null,
    loadData: Ember.on('init', function () {
      this.setProperties({
        'wuchsklasse': wuchsklasse_loaded,
        'baumart': baumart_loaded,
        'bestockungsgrad': bestockungsgrad_loaded
      });
    }),
    willInsertElement: function willInsertElement() {
      var _this = this;

      var values = this.getProperties('wuchsklasse', 'baumart', 'bestockungsgrad');
      if (Object.keys(values).filter(function (x) {
        return values[x] === null;
      }).length === 0) {
        Ember.run.next(function () {
          if (_this.isDestroyed || _this.isDestroying) {
            return;
          }
          _this.set('cachedComponent', _this.$().children().clone());
          Ember.run.schedule('afterRender', function () {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }
            _this.$().append(_this.get('cachedComponent'));
          });
        });
      } else {
        Ember.$.when(wuchsklasse, baumart, bestockungsgrad).then(function () {
          Ember.run.next(function () {
            if (_this.isDestroyed || _this.isDestroying) {
              return;
            }
            _this.setProperties({
              'wuchsklasse': wuchsklasse_loaded,
              'baumart': baumart_loaded,
              'bestockungsgrad': bestockungsgrad_loaded
            });
            Ember.run.next(function () {
              if (_this.isDestroyed || _this.isDestroying) {
                return;
              }
              _this.set('cachedComponent', _this.$().children().clone());
              Ember.run.schedule('afterRender', function () {
                if (_this.isDestroyed || _this.isDestroying) {
                  return;
                }
                _this.$().append(_this.get('cachedComponent'));
              });
            });
          });
        });
      }
    },
    willDestroyElement: function willDestroyElement() {
      var cached = this.get('cachedComponent');
      if (cached) {
        this.set('cachedComponent', null);
        cached.remove();
      }
    },
    firstResult: Ember.computed('model.result', function () {
      return (this.get('model.result') || [])[0];
    }),
    eingriffsflaeche: Ember.computed('model.flaechenprozent', 'model.flaeche', function () {
      return this.get('model.flaechenprozent') * this.get('model.flaeche');
    }),

    summeAnteil: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.anteil_proz;
      }, 0);
    }),
    summeAlterErg: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.alter_erg * c.anteil_proz;
      }, 0);
    }),
    summeAlterRel: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.rel_alter * c.anteil_proz;
      }, 0);
    }),

    summeVorrat: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.vorrat_wzp;
      }, 0);
    }),
    summeVorratOrt: Ember.computed(function () {
      var flaeche = this.get('flaeche');
      return this.get('model.result').reduce(function (p, c) {
        return p + c.vorrat_wzp * flaeche;
      }, 0);
    }),
    summeGenauigkeitV: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.genauigkeit_v;
      }, 0) / this.get('model.result.length');
    }),
    summeZuwachs: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.zuwachs;
      }, 0);
    }),
    summeZuwachsOrt: Ember.computed(function () {
      var flaeche = this.get('flaeche');
      return this.get('model.result').reduce(function (p, c) {
        return p + c.zuwachs * flaeche;
      }, 0);
    }),
    summeGenauigkeitZ: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.genauigkeit_z;
      }, 0) / this.get('model.result.length');
    }),
    summeStammzahlen: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.stammzahl;
      }, 0);
    }),

    summeSchaftSchaed: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.schaft_schaed;
      }, 0) / this.get('model.result.length');
    }),

    summeGenauigkeitGes: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.genauigkeit_ges;
      }, 0) / this.get('model.result.length');
    }),

    summeFlaeche: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.grundflaeche_ha;
      }, 0);
    }),

    summeStarkbloch: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.starkbloch;
      }, 0);
    }),
    summeBloch: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.bloch;
      }, 0);
    }),
    summeBraunbloch: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.braubbloch;
      }, 0);
    }),
    summeSchwachbloch: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.schwachbloch;
      }, 0);
    }),
    summeIhBrennholz: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.ih_brennholz;
      }, 0);
    }),
    summeSekundaFaulholz: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.sekunda_faulholz;
      }, 0);
    }),
    summeErntebareBiomasse: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.erntbare_biomasse;
      }, 0);
    }),
    summeCo2: Ember.computed(function () {
      return this.get('model.result').reduce(function (p, c) {
        return p + c.co2_tonnen;
      }, 0);
    })
  });
});