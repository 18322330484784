define('mpforst-ember/app/pods/components/tri-checkbox/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'input',
    attributeBindings: ['type'],
    type: 'checkbox',
    init: function init() {
      this._super.apply(this, arguments);
      this._updateChecked = this._updateChecked.bind(this);
    },
    willInsertElement: function willInsertElement() {
      this.element.addEventListener('change', this._updateChecked, false);
      this.element.checked = this.get('checked');
      this.element.indeterminate = this.get('indeterminate');
    },
    willDestroyElement: function willDestroyElement() {
      this.element.removeEventListener('change', this._updateChecked);
    },
    _updateDOM: function _updateDOM() {
      console.log("update DOM");
      if (this.element) {
        this.element.checked = this.get('checked');
        this.element.indeterminate = this.get('indeterminate');
      }
    },
    _updateChecked: function _updateChecked() {
      console.log("set");
      this.set('tri-checked', ((this.get('tri-checked') | 0) + 1) % 3);
      this._updateDOM();
    },

    _obsUpdateDom: Ember.observer('tri-checked', function () {
      this._updateDOM();
    }),
    indeterminate: Ember.computed('tri-checked', function () {
      console.log("update indeterminate ", this.get('tri-checked') === 1 || this.get('tri-checked') === true);
      return this.get('tri-checked') === 2;
    }),
    checked: Ember.computed('tri-checked', function () {
      console.log("update checked ", this.get('tri-checked') === 1 || this.get('tri-checked') === true);
      return this.get('tri-checked') === 1 || this.get('tri-checked') === true;
    })
  });
});