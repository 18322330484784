define('mpforst-ember/app/pods/grunddaten-betrieb/bearbeiten/route', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function jsonToEmber(obj) {
    if (obj instanceof Array) {
      return Ember.A(obj.map(function (y) {
        return jsonToEmber(y);
      }));
    } else if (obj instanceof Object) {
      var tmp = {};
      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = Object.keys(obj)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var x = _step.value;

          if (obj[x] instanceof Array) {
            tmp[x] = Ember.A(obj[x].map(function (y) {
              return jsonToEmber(y);
            }));
          } else if (obj[x] instanceof Object) {
            tmp[x] = jsonToEmber(obj[x]);
          } else {
            tmp[x] = obj[x];
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return Ember.Object.create(tmp);
    } else {
      return obj;
    }
  }

  exports.default = Ember.Route.extend({
    templateName: 'grunddaten-betrieb.anzeigen',
    model: function model() {
      return jsonToEmber(JSON.parse(JSON.stringify(this.modelFor('grunddaten-betrieb'))));
    },
    afterModel: function afterModel() {
      var controller = this.controllerFor(this.get('routeName'));
      controller.set('s', 0);
    }
  });
});