define('mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-waldbaukonzept/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: '',
    _extra: [{
      waldbaukonzept: 1,
      nutzungsart: [2, 3, 4, 5] // sollte vllt vom SQL kommen.. :(
    }, {
      waldbaukonzept: 2,
      nutzungsart: [2, 3, 4, 5]
    }, {
      waldbaukonzept: 3,
      nutzungsart: [1, 2, 3, 4, 5, 6, 7]
    }, {
      waldbaukonzept: 4,
      nutzungsart: [1, 2, 3, 4, 5]
    }, {
      waldbaukonzept: 5,
      nutzungsart: [2, 3, 4, 5]
    }, {
      waldbaukonzept: 6,
      nutzungsart: [2, 3, 4, 5]
    }, {
      waldbaukonzept: 7,
      nutzungsart: [6, 7]
    }, {
      waldbaukonzept: 8,
      nutzungsart: [6]
    }, {
      waldbaukonzept: 9,
      nutzungsart: [6]
    }],
    rows: Ember.computed('model.parameter', function () {
      var result = Ember.A();
      var parameters = this.get('model.parameter') || [];
      var extra = this.get('_extra') || [];

      var _loop = function _loop(e) {
        var tmp = {
          extra: e,
          parameter: parameters.filter(function (x) {
            return x.id === e.waldbaukonzept;
          })[0]
        };
        function addF10(name) {
          Ember.defineProperty(tmp, name + '_f10', Ember.computed('parameter.' + name, {
            get: function get(key) {
              return Ember.get(this, 'parameter.' + name) / 10;
            },
            set: function set(key, value) {
              Ember.set(this, 'parameter.' + name, value * 10);
              return value;
            }
          }));
        }
        function add999(name) {
          Ember.defineProperty(tmp, name + '_999', Ember.computed('parameter.' + name, {
            get: function get(key) {
              var value = Ember.get(this, 'parameter.' + name);
              return value === 999 ? null : value;
            },
            set: function set(key, value) {
              Ember.set(this, 'parameter.' + name, value === null ? 999 : value);
              return value;
            }
          }));
        }
        addF10("raeumung");
        addF10("kahlhieb");
        add999("dickungspflege");
        add999("erstdurchforstung");
        add999("durchforstung");
        add999("einzelstammentnahme");
        add999("vorlichtung");
        add999("raeumung");
        add999("kahlhieb");

        // nicht grad die sauberste lösung, aber die einfachste
        for (var i = 1; i < 8; ++i) {
          tmp["nutzungsart_" + i] = e.nutzungsart.indexOf(i) !== -1;
        }
        result.pushObject(tmp);
      };

      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = extra[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          var e = _step.value;

          _loop(e);
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }

      return result;
    })
  });
});