define('mpforst-ember/app/pods/components/foundation-column/component', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	exports.default = Ember.Component.extend({
		// className: ['columns'], //not working ?
		columns: 'columns',
		classNameBindings: ['columns', 'cpLarge', 'cpMedium', 'cpSmall'],

		cpLarge: Ember.computed('large', function () {
			var v = this.get('large');
			if (v) {
				return 'large-' + v;
			}
			return null;
		}),
		cpMedium: Ember.computed('large', function () {
			var v = this.get('medium');
			if (v) {
				return 'large-' + v;
			}
			return null;
		}),
		cpSmall: Ember.computed('large', function () {
			var v = this.get('small');
			if (v) {
				return 'large-' + v;
			}
			return null;
		})
	});
});