define('mpforst-ember/app/pods/assistent-wzpx-editor/components/x-wzpx-row/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'tr',
    displayRow: false,
    classNameBindings: 'hasErrors:error',
    hasErrors: Ember.computed('model.error.length', function () {
      return (this.get('model.error') || []).length > 0;
    }),
    actions: {
      delete: function _delete() {
        this.sendAction('actionDelete', this.get('model'));
      }
    },
    didInsertElement: function didInsertElement() {
      var _this = this;

      this._change = function () {
        if (_this.get('empty')) {
          _this.sendAction('actionNew', _this.get('model'));
        }
        console.log("x-wzpx-row change");
        _this.sendAction('actionChange', _this.get('model'));
      };
      this.$().on("change", "input, select", this._change);
    },
    willDestroyElement: function willDestroyElement() {
      if (this._change) {
        this.$().off("change", "input, select", this._change);
      }
    },

    anzahl: Ember.computed(function () {
      var arr = [];
      arr.push({
        value: null,
        caption: null
      });
      for (var i = 1; i <= 50; ++i) {
        arr.push({
          value: i,
          caption: i
        });
      }
      return arr;
    })
  });
});