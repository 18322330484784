define('mpforst-ember/app/pods/assistent-wzpx-setup/selected/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    betriebList: Ember.computed('model', function () {
      return Ember.$.getJSON('api/betrieb').then(function (x) {
        return x.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung
          };
        });
      });
    }),
    teilbetriebList: Ember.computed('model.betrieb_id', function () {
      return Ember.$.getJSON('api/betrieb/' + this.get('model.betrieb_id')).then(function (x) {
        return x.teilbetriebe.map(function (y) {
          return {
            value: y.id,
            caption: y.bezeichnung,
            seehoehe: y.mit_see_hoehe
          };
        });
      });
    }),
    seehoeheList: Ember.computed('teilbetriebList', 'model.teilbetrieb_id', function () {
      return this.get('teilbetriebList').then(function (x) {
        return x.map(function (y) {
          return {
            value: y.value,
            caption: y.seehoehe
          };
        });
      });
    }),
    actions: {
      closeError: function closeError() {
        this.set('error', null);
      },
      actionDidInsertFormElement: function actionDidInsertFormElement(component) {
        var _this = this;

        component.$().ajaxForm();
        component.$('input').change(function () {
          _this.set('upload', true);
          component.$().ajaxSubmit({
            url: 'api/entwurfwzpx/csv/' + _this.get('model.id'),
            type: 'POST',
            dataType: 'json',
            resetForm: true,

            beforeSend: function beforeSend() {
              Ember.run(function () {
                _this.set('uploadProgress', 0);
              });
            },
            uploadProgress: function uploadProgress(event, postion, total, percent) {
              Ember.run(function () {
                _this.set('uploadProgress', percent);
              });
            },
            success: function success() {
              Ember.run(function () {
                _this.set('uploadProgress', 100);
              });
            },
            complete: function complete(xhr) {
              Ember.run(function () {
                component.$().trigger('reset');
                _this.set('upload', false);
                var json = {
                  okay: false,
                  error: [{ field: 'unbekannt', message: 'Unbekannt' }]
                };
                try {
                  json = JSON.parse(xhr.responseText);
                } catch (e) {
                  // ignore
                }
                if (!json.okay) {
                  _this.set('error', json.error);
                } else {
                  _this.transitionToRoute('assistent-wzpx-summary');
                  _this.get('target').router.refresh();
                }
              });
            }
          });
        });
      }
    }
  });
});