define("mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    passend: [{
      value: true,
      caption: "passend"
    }, {
      value: false,
      caption: "nicht passend"
    }]
  });
});