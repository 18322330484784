define("mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-baumartgruppe/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 33,
              "column": 12
            },
            "end": {
              "line": 35,
              "column": 12
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-baumartgruppe/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [34, 35], [34, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "number", "min", 0, "max", 1000, "fraction", 0, "value", ["subexpr", "mut", [["subexpr", "get", [["get", "min_umtrieb.gruppe", ["loc", [null, [34, 96], [34, 114]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "i", ["loc", [null, [34, 123], [34, 124]]], 0, 0, 0, 0]], [], ["loc", [null, [34, 115], [34, 125]]], 0, 0]], [], ["loc", [null, [34, 91], [34, 126]]], 0, 0]], [], ["loc", [null, [34, 86], [34, 127]]], 0, 0]], ["loc", [null, [34, 18], [34, 129]]], 0, 0]],
        locals: ["_", "i"],
        templates: []
      };
    }();
    var child1 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 42,
              "column": 12
            },
            "end": {
              "line": 44,
              "column": 12
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-baumartgruppe/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [43, 35], [43, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "number", "min", 0, "max", 1000, "fraction", 0, "value", ["subexpr", "mut", [["subexpr", "get", [["get", "umtrieb.gruppe", ["loc", [null, [43, 96], [43, 110]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "i", ["loc", [null, [43, 119], [43, 120]]], 0, 0, 0, 0]], [], ["loc", [null, [43, 111], [43, 121]]], 0, 0]], [], ["loc", [null, [43, 91], [43, 122]]], 0, 0]], [], ["loc", [null, [43, 86], [43, 123]]], 0, 0]], ["loc", [null, [43, 18], [43, 125]]], 0, 0]],
        locals: ["_", "i"],
        templates: []
      };
    }();
    var child2 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 12
            },
            "end": {
              "line": 53,
              "column": 12
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-baumartgruppe/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [52, 35], [52, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "number", "min", 0, "max", 200, "fraction", 0, "value", ["subexpr", "mut", [["subexpr", "get", [["get", "korrektur.gruppe", ["loc", [null, [52, 95], [52, 111]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "i", ["loc", [null, [52, 120], [52, 121]]], 0, 0, 0, 0]], [], ["loc", [null, [52, 112], [52, 122]]], 0, 0]], [], ["loc", [null, [52, 90], [52, 123]]], 0, 0]], [], ["loc", [null, [52, 85], [52, 124]]], 0, 0]], ["loc", [null, [52, 18], [52, 126]]], 0, 0]],
        locals: ["_", "i"],
        templates: []
      };
    }();
    var child3 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 91,
              "column": 12
            },
            "end": {
              "line": 93,
              "column": 12
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-baumartgruppe/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [92, 35], [92, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "number", "min", 0, "max", 100, "fraction", 0, "value", ["subexpr", "mut", [["subexpr", "get", [["get", "verringerung_menge.gruppe", ["loc", [null, [92, 95], [92, 120]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "i", ["loc", [null, [92, 129], [92, 130]]], 0, 0, 0, 0]], [], ["loc", [null, [92, 121], [92, 131]]], 0, 0]], [], ["loc", [null, [92, 90], [92, 132]]], 0, 0]], [], ["loc", [null, [92, 85], [92, 133]]], 0, 0]], ["loc", [null, [92, 18], [92, 135]]], 0, 0]],
        locals: ["_", "i"],
        templates: []
      };
    }();
    var child4 = function () {
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 100,
              "column": 12
            },
            "end": {
              "line": 102,
              "column": 12
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-baumartgruppe/template.hbs"
        },
        isEmpty: false,
        arity: 2,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("              ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("td");
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 0, 0);
          return morphs;
        },
        statements: [["inline", "mpf-input", [], ["edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [101, 35], [101, 39]]], 0, 0, 0, 0]], [], [], 0, 0], "type", "number", "min", 0, "max", 100, "fraction", 0, "value", ["subexpr", "mut", [["subexpr", "get", [["get", "verringerung_menge_schutzwald.gruppe", ["loc", [null, [101, 95], [101, 131]]], 0, 0, 0, 0], ["subexpr", "concat", [["get", "i", ["loc", [null, [101, 140], [101, 141]]], 0, 0, 0, 0]], [], ["loc", [null, [101, 132], [101, 142]]], 0, 0]], [], ["loc", [null, [101, 90], [101, 143]]], 0, 0]], [], ["loc", [null, [101, 85], [101, 144]]], 0, 0]], ["loc", [null, [101, 18], [101, 146]]], 0, 0]],
        locals: ["_", "i"],
        templates: []
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 107,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-baumartgruppe/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "class", "table-no-tbody-padding custom-table large-12");
        dom.setAttribute(el1, "style", "table-layout: fixed");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width: 20em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "rowspan", "2");
        var el5 = dom.createTextNode("\n          Reifegrad");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "colspan", "5");
        var el5 = dom.createTextNode("\n          Baumartengruppe\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "rowspan", "2");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("1");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("2");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("3");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("4");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("5");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tbody");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createTextNode("\n                Mindestumtriebszeit");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "postfix");
        dom.setAttribute(el5, "style", "border-left: solid 1px #cccccc; display: inline-block; width: 4em;");
        var el6 = dom.createTextNode("Jahre");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createTextNode("\n                Maximale Umtriebszeit");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "postfix");
        dom.setAttribute(el5, "style", "border-left: solid 1px #cccccc; display: inline-block; width: 4em;");
        var el6 = dom.createTextNode("Jahre");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createTextNode("\n                Korrekturfaktor");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "postfix");
        dom.setAttribute(el5, "style", "border-left: solid 1px #cccccc; display: inline-block; width: 4em;");
        var el6 = dom.createTextNode("%");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("table");
        dom.setAttribute(el1, "class", "table-no-tbody-padding custom-table large-12");
        dom.setAttribute(el1, "style", "table-layout: fixed");
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width: 20em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.setAttribute(el2, "style", "width:4em");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("col");
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("thead");
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "rowspan", "2");
        var el5 = dom.createTextNode("\n          Hiebsatz");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "colspan", "5");
        var el5 = dom.createTextNode("\n          Baumartengruppe\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        dom.setAttribute(el4, "rowspan", "2");
        var el5 = dom.createTextNode("\n        ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n      ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("1");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("2");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("3");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("4");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("th");
        var el5 = dom.createTextNode("5");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n      ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n    ");
        dom.appendChild(el1, el2);
        var el2 = dom.createElement("tbody");
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createTextNode("\n                Reduktionsfaktor Hiebsatzberechnung Wirtschaftswald mit eingeschränkter Nutzung");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "postfix");
        dom.setAttribute(el5, "style", "border-left: solid 1px #cccccc; display: inline-block; width: 4em;");
        var el6 = dom.createTextNode("%");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n        ");
        dom.appendChild(el2, el3);
        var el3 = dom.createElement("tr");
        var el4 = dom.createTextNode("\n            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createTextNode("\n                Reduktionsfaktor Hiebsatzberechnung");
        dom.appendChild(el4, el5);
        var el5 = dom.createElement("br");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("Schutzwald im Ertrag");
        dom.appendChild(el4, el5);
        var el5 = dom.createComment("");
        dom.appendChild(el4, el5);
        var el5 = dom.createTextNode("\n            ");
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n");
        dom.appendChild(el3, el4);
        var el4 = dom.createComment("");
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("            ");
        dom.appendChild(el3, el4);
        var el4 = dom.createElement("td");
        var el5 = dom.createElement("span");
        dom.setAttribute(el5, "class", "postfix");
        dom.setAttribute(el5, "style", "border-left: solid 1px #cccccc; display: inline-block; width: 4em;");
        var el6 = dom.createTextNode("%");
        dom.appendChild(el5, el6);
        dom.appendChild(el4, el5);
        dom.appendChild(el3, el4);
        var el4 = dom.createTextNode("\n        ");
        dom.appendChild(el3, el4);
        dom.appendChild(el2, el3);
        var el3 = dom.createTextNode("\n    ");
        dom.appendChild(el2, el3);
        dom.appendChild(el1, el2);
        var el2 = dom.createTextNode("\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var element0 = dom.childAt(fragment, [0]);
        var element1 = dom.childAt(element0, [17]);
        var element2 = dom.childAt(element1, [1]);
        var element3 = dom.childAt(element1, [3]);
        var element4 = dom.childAt(element1, [5]);
        var element5 = dom.childAt(fragment, [2]);
        var element6 = dom.childAt(element5, [17]);
        var element7 = dom.childAt(element6, [1]);
        var element8 = dom.childAt(element6, [3]);
        var morphs = new Array(12);
        morphs[0] = dom.createMorphAt(dom.childAt(element0, [15, 1, 1]), 1, 1);
        morphs[1] = dom.createMorphAt(dom.childAt(element2, [1]), 1, 1);
        morphs[2] = dom.createMorphAt(element2, 3, 3);
        morphs[3] = dom.createMorphAt(dom.childAt(element3, [1]), 1, 1);
        morphs[4] = dom.createMorphAt(element3, 3, 3);
        morphs[5] = dom.createMorphAt(dom.childAt(element4, [1]), 1, 1);
        morphs[6] = dom.createMorphAt(element4, 3, 3);
        morphs[7] = dom.createMorphAt(dom.childAt(element5, [15, 1, 1]), 1, 1);
        morphs[8] = dom.createMorphAt(dom.childAt(element7, [1]), 1, 1);
        morphs[9] = dom.createMorphAt(element7, 3, 3);
        morphs[10] = dom.createMorphAt(dom.childAt(element8, [1]), 3, 3);
        morphs[11] = dom.createMorphAt(element8, 3, 3);
        return morphs;
      },
      statements: [["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "BaumartengruppenRelativesAlter"], ["loc", [null, [12, 19], [12, 96]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "BaumartengruppenMinUmtriebszeit"], ["loc", [null, [31, 35], [31, 113]]], 0, 0], ["block", "each", [["get", "min_umtrieb.gruppe", ["loc", [null, [33, 20], [33, 38]]], 0, 0, 0, 0]], [], 0, null, ["loc", [null, [33, 12], [35, 21]]]], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "BaumartengruppenMaxUmtriebszeit"], ["loc", [null, [40, 37], [40, 115]]], 0, 0], ["block", "each", [["get", "umtrieb.gruppe", ["loc", [null, [42, 20], [42, 34]]], 0, 0, 0, 0]], [], 1, null, ["loc", [null, [42, 12], [44, 21]]]], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "BaumartengruppenKorrekturfaktor"], ["loc", [null, [49, 31], [49, 109]]], 0, 0], ["block", "each", [["get", "min_umtrieb.gruppe", ["loc", [null, [51, 20], [51, 38]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [51, 12], [53, 21]]]], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "BaumartengruppenHiebsatz"], ["loc", [null, [70, 18], [70, 89]]], 0, 0], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "BaumartengruppenReduktionsfaktor1"], ["loc", [null, [89, 95], [89, 175]]], 0, 0], ["block", "each", [["get", "verringerung_menge.gruppe", ["loc", [null, [91, 20], [91, 45]]], 0, 0, 0, 0]], [], 3, null, ["loc", [null, [91, 12], [93, 21]]]], ["inline", "mpf-help-tooltip", [], ["area", "Teilbetrieb", "name", "BaumartengruppenReduktionsfaktor2"], ["loc", [null, [98, 75], [98, 155]]], 0, 0], ["block", "each", [["get", "verringerung_menge_schutzwald.gruppe", ["loc", [null, [100, 20], [100, 56]]], 0, 0, 0, 0]], [], 4, null, ["loc", [null, [100, 12], [102, 21]]]]],
      locals: [],
      templates: [child0, child1, child2, child3, child4]
    };
  }());
});