define('mpforst-ember/app/pods/assistent-wzpx-editor/components/x-editor/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var defaultValues = {
    zaehlfaktor: 4,
    alterszuschlag_bohrung: 7
  };

  exports.default = Ember.Component.extend({
    tagName: '',

    openBestand: null,
    edit: true,
    empty: false,
    errorOnly: false,

    actions: {
      actionOpenBestand: function actionOpenBestand(bestand) {
        this.set('openBestand', bestand);
      },
      actionCloseBestand: function actionCloseBestand() /*bestand*/{
        this.set('openBestand', null);
      },
      actionDeleteBestand: function actionDeleteBestand(bestand) {
        var model = this.get('model');
        model.removeObject(bestand);
        this.sendAction('actionChange', this.get('model'));
      },
      actionNewBestand: function actionNewBestand(bestand) {
        var model = this.get('model') || Ember.A();
        //default werte setzen
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = Object.keys(defaultValues)[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var key = _step.value;

            if (bestand.get(key) === undefined || bestand.get(key) === null) {
              bestand.set(key, defaultValues[key]);
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        if (!bestand.get('seehoehe')) {
          bestand.set('seehoehe', this.get('seehoehe'));
        }
        model.addObject(bestand);
        this.set('model', model);
        this.set('emptyBestand', Ember.Object.create({
          wzpx: Ember.A(),
          error: Ember.A()
        }));
        this.set('openBestand', bestand);
      },
      actionChange: function actionChange() {
        console.log("x-editor change");
        this.sendAction('actionChange', this.get('model'));
      }
    },

    modelWithEmpty: Ember.computed('edit', 'model', 'model.length', 'emptyBestand', function () {
      var array = (this.get('model') || Ember.A()).slice();
      if (this.get('edit')) {
        array.addObject(this.get('emptyBestand'));
      }
      if (this.get('errorOnly')) {
        array = array.filter(function (x) {
          return (x.error || []).length > 0;
        });
        if (array.length > 10) {
          array = array.slice(0, 10);
        }
      }
      return array;
    }),

    emptyBestand: null,
    initEmptyBestand: Ember.on('init', function () {
      this.set('emptyBestand', Ember.Object.create({
        wzpx: Ember.A(),
        error: Ember.A()
      }));
    })
  });
});