define("mpforst-ember/app/pods/components/editor-link-atom/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 4,
                  "column": 6
                },
                "end": {
                  "line": 6,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("        ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "target", "_blank");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element7 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element7, 'href');
              morphs[1] = dom.createMorphAt(element7, 0, 0);
              return morphs;
            },
            statements: [["attribute", "href", ["get", "extern", ["loc", [null, [5, 18], [5, 24]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "caption", ["loc", [null, [5, 43], [5, 54]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          var child0 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 6,
                    "column": 6
                  },
                  "end": {
                    "line": 8,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element6 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createElementMorph(element6);
                morphs[1] = dom.createMorphAt(element6, 0, 0);
                return morphs;
              },
              statements: [["element", "action", ["openHelp"], [], ["loc", [null, [7, 11], [7, 32]]], 0, 0], ["content", "caption", ["loc", [null, [7, 33], [7, 44]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          var child1 = function () {
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 8,
                    "column": 6
                  },
                  "end": {
                    "line": 10,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createTextNode("        ");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("a");
                dom.setAttribute(el1, "target", "_blank");
                var el2 = dom.createComment("");
                dom.appendChild(el1, el2);
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n      ");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var element5 = dom.childAt(fragment, [1]);
                var morphs = new Array(2);
                morphs[0] = dom.createAttrMorph(element5, 'href');
                morphs[1] = dom.createMorphAt(element5, 0, 0);
                return morphs;
              },
              statements: [["attribute", "href", ["subexpr", "concat", ["page/", ["get", "intern", ["loc", [null, [9, 33], [9, 39]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [9, 41]]], 0, 0], 0, 0, 0, 0], ["content", "caption", ["loc", [null, [9, 58], [9, 69]]], 0, 0, 0, 0]],
              locals: [],
              templates: []
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 6,
                  "column": 6
                },
                "end": {
                  "line": 10,
                  "column": 6
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "if", [["get", "showTooltip", ["loc", [null, [6, 16], [6, 27]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [6, 6], [10, 6]]]]],
            locals: [],
            templates: [child0, child1]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 3,
                "column": 4
              },
              "end": {
                "line": 11,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "showHref", ["loc", [null, [4, 12], [4, 20]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [4, 6], [10, 13]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 11,
                "column": 4
              },
              "end": {
                "line": 13,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("code");
            var el2 = dom.createTextNode("Neuer Link");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 24,
                      "column": 12
                    },
                    "end": {
                      "line": 26,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "select", "edit", true, "items", ["subexpr", "@mut", [["get", "dropdownTyp", ["loc", [null, [25, 56], [25, 67]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "typ", ["loc", [null, [25, 74], [25, 77]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [25, 14], [25, 79]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 23,
                    "column": 8
                  },
                  "end": {
                    "line": 27,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-label", [], ["titel", "Typ"], 0, null, ["loc", [null, [24, 12], [26, 33]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child1 = function () {
            var child0 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 29,
                      "column": 12
                    },
                    "end": {
                      "line": 31,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "caption", ["loc", [null, [30, 40], [30, 47]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [30, 14], [30, 49]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 28,
                    "column": 8
                  },
                  "end": {
                    "line": 32,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-label", [], ["titel", "Titel"], 0, null, ["loc", [null, [29, 12], [31, 33]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child2 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 35,
                        "column": 14
                      },
                      "end": {
                        "line": 37,
                        "column": 14
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "input", [], ["type", "text", "value", ["subexpr", "@mut", [["get", "extern", ["loc", [null, [36, 42], [36, 48]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [36, 16], [36, 50]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 34,
                      "column": 10
                    },
                    "end": {
                      "line": 38,
                      "column": 10
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "foundation-label", [], ["titel", "URL"], 0, null, ["loc", [null, [35, 14], [37, 35]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 33,
                    "column": 8
                  },
                  "end": {
                    "line": 39,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [34, 10], [38, 36]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          var child3 = function () {
            var child0 = function () {
              var child0 = function () {
                var child0 = function () {
                  var child0 = function () {
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 42,
                            "column": 16
                          },
                          "end": {
                            "line": 44,
                            "column": 16
                          }
                        },
                        "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["inline", "mpf-input", [], ["type", "select", "edit", true, "items", ["subexpr", "@mut", [["get", "dropdownTooltip", ["loc", [null, [43, 60], [43, 75]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "tooltip", ["loc", [null, [43, 82], [43, 89]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [43, 18], [43, 91]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  }();
                  var child1 = function () {
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 44,
                            "column": 16
                          },
                          "end": {
                            "line": 46,
                            "column": 16
                          }
                        },
                        "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  Bitte warten...\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  }();
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 41,
                          "column": 14
                        },
                        "end": {
                          "line": 47,
                          "column": 14
                        }
                      },
                      "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "if", [["get", "dropdownTooltip", ["loc", [null, [42, 22], [42, 37]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [42, 16], [46, 23]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 40,
                        "column": 10
                      },
                      "end": {
                        "line": 48,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "foundation-label", [], ["titel", "Tooltip"], 0, null, ["loc", [null, [41, 14], [47, 35]]]]],
                  locals: [],
                  templates: [child0]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 39,
                      "column": 8
                    },
                    "end": {
                      "line": 49,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [40, 10], [48, 36]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            var child1 = function () {
              var child0 = function () {
                var child0 = function () {
                  var child0 = function () {
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 52,
                            "column": 16
                          },
                          "end": {
                            "line": 54,
                            "column": 16
                          }
                        },
                        "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  ");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createComment("");
                        dom.appendChild(el0, el1);
                        var el1 = dom.createTextNode("\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                        var morphs = new Array(1);
                        morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                        return morphs;
                      },
                      statements: [["inline", "mpf-input", [], ["type", "select", "edit", true, "items", ["subexpr", "@mut", [["get", "dropdownIntern", ["loc", [null, [53, 60], [53, 74]]], 0, 0, 0, 0]], [], [], 0, 0], "value", ["subexpr", "@mut", [["get", "intern", ["loc", [null, [53, 81], [53, 87]]], 0, 0, 0, 0]], [], [], 0, 0]], ["loc", [null, [53, 18], [53, 89]]], 0, 0]],
                      locals: [],
                      templates: []
                    };
                  }();
                  var child1 = function () {
                    return {
                      meta: {
                        "revision": "Ember@2.9.1",
                        "loc": {
                          "source": null,
                          "start": {
                            "line": 54,
                            "column": 16
                          },
                          "end": {
                            "line": 56,
                            "column": 16
                          }
                        },
                        "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                      },
                      isEmpty: false,
                      arity: 0,
                      cachedFragment: null,
                      hasRendered: false,
                      buildFragment: function buildFragment(dom) {
                        var el0 = dom.createDocumentFragment();
                        var el1 = dom.createTextNode("                  Bitte warten...\n");
                        dom.appendChild(el0, el1);
                        return el0;
                      },
                      buildRenderNodes: function buildRenderNodes() {
                        return [];
                      },
                      statements: [],
                      locals: [],
                      templates: []
                    };
                  }();
                  return {
                    meta: {
                      "revision": "Ember@2.9.1",
                      "loc": {
                        "source": null,
                        "start": {
                          "line": 51,
                          "column": 14
                        },
                        "end": {
                          "line": 57,
                          "column": 14
                        }
                      },
                      "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                    },
                    isEmpty: false,
                    arity: 0,
                    cachedFragment: null,
                    hasRendered: false,
                    buildFragment: function buildFragment(dom) {
                      var el0 = dom.createDocumentFragment();
                      var el1 = dom.createComment("");
                      dom.appendChild(el0, el1);
                      return el0;
                    },
                    buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                      var morphs = new Array(1);
                      morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                      dom.insertBoundary(fragment, 0);
                      dom.insertBoundary(fragment, null);
                      return morphs;
                    },
                    statements: [["block", "if", [["get", "dropdownIntern", ["loc", [null, [52, 22], [52, 36]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [52, 16], [56, 23]]]]],
                    locals: [],
                    templates: [child0, child1]
                  };
                }();
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 50,
                        "column": 10
                      },
                      "end": {
                        "line": 58,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["block", "foundation-label", [], ["titel", "Seite"], 0, null, ["loc", [null, [51, 14], [57, 35]]]]],
                  locals: [],
                  templates: [child0]
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 49,
                      "column": 8
                    },
                    "end": {
                      "line": 59,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [50, 10], [58, 36]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 39,
                    "column": 8
                  },
                  "end": {
                    "line": 59,
                    "column": 8
                  }
                },
                "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "if", [["get", "showTooltip", ["loc", [null, [39, 18], [39, 29]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [39, 8], [59, 8]]]]],
              locals: [],
              templates: [child0, child1]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 19,
                  "column": 4
                },
                "end": {
                  "line": 63,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("    ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("div");
              dom.setAttribute(el1, "style", "position: fixed; left: 0; top: 0; right: 0; bottom: 0; background: rgba(0,0,0,0.4); z-index: 10000;");
              var el2 = dom.createTextNode("\n      ");
              dom.appendChild(el1, el2);
              var el2 = dom.createElement("div");
              dom.setAttribute(el2, "style", "position: absolute; left: 50%; top: 50%; transform: translate(-50%, -50%); background: white; border: 1px solid black; padding: 1em");
              var el3 = dom.createTextNode("\n        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("h2");
              var el4 = dom.createTextNode("Link");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createComment("");
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("        ");
              dom.appendChild(el2, el3);
              var el3 = dom.createElement("button");
              var el4 = dom.createTextNode("Zurück");
              dom.appendChild(el3, el4);
              dom.appendChild(el2, el3);
              var el3 = dom.createTextNode("\n      ");
              dom.appendChild(el2, el3);
              dom.appendChild(el1, el2);
              var el2 = dom.createTextNode("\n    ");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element3 = dom.childAt(fragment, [1, 1]);
              var element4 = dom.childAt(element3, [7]);
              var morphs = new Array(4);
              morphs[0] = dom.createMorphAt(element3, 3, 3);
              morphs[1] = dom.createMorphAt(element3, 4, 4);
              morphs[2] = dom.createMorphAt(element3, 5, 5);
              morphs[3] = dom.createAttrMorph(element4, 'onClick');
              return morphs;
            },
            statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [23, 8], [27, 34]]]], ["block", "foundation-row-column", [], ["large", 12], 1, null, ["loc", [null, [28, 8], [32, 34]]]], ["block", "if", [["get", "showHref", ["loc", [null, [33, 14], [33, 22]]], 0, 0, 0, 0]], [], 2, 3, ["loc", [null, [33, 8], [59, 15]]]], ["attribute", "onClick", ["subexpr", "action", ["close"], [], ["loc", [null, [null, null], [60, 42]]], 0, 0], 0, 0, 0, 0]],
            locals: [],
            templates: [child0, child1, child2, child3]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 18,
                "column": 2
              },
              "end": {
                "line": 64,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "ember-wormhole", [], ["to", "popup"], 0, null, ["loc", [null, [19, 4], [63, 23]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 65,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("    ");
          dom.appendChild(el1, el2);
          var el2 = dom.createElement("a");
          var el3 = dom.createTextNode("\n      ");
          dom.appendChild(el2, el3);
          var el3 = dom.createElement("span");
          dom.setAttribute(el3, "class", "fa fa-pencil-square");
          dom.appendChild(el2, el3);
          var el3 = dom.createTextNode("\n    ");
          dom.appendChild(el2, el3);
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("\n  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var element8 = dom.childAt(fragment, [1]);
          var element9 = dom.childAt(element8, [3]);
          var morphs = new Array(4);
          morphs[0] = dom.createAttrMorph(element8, 'style');
          morphs[1] = dom.createMorphAt(element8, 1, 1);
          morphs[2] = dom.createAttrMorph(element9, 'onclick');
          morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["attribute", "style", ["concat", ["display: inline; position: relative; outline: 1px solid rgba(0,0,255,0.3); ", ["subexpr", "if", [["get", "src", ["loc", [null, [2, 94], [2, 97]]], 0, 0, 0, 0], "cursor: default"], [], ["loc", [null, [2, 89], [2, 117]]], 0, 0]], 0, 0, 0, 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "caption", ["loc", [null, [3, 10], [3, 17]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [3, 4], [13, 11]]]], ["attribute", "onclick", ["subexpr", "action", ["open"], [], ["loc", [null, [null, null], [14, 32]]], 0, 0], 0, 0, 0, 0], ["block", "if", [["get", "showEditor", ["loc", [null, [18, 8], [18, 18]]], 0, 0, 0, 0]], [], 2, null, ["loc", [null, [18, 2], [64, 9]]]]],
        locals: [],
        templates: [child0, child1, child2]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 67,
                "column": 4
              },
              "end": {
                "line": 69,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createTextNode("      ");
            dom.appendChild(el0, el1);
            var el1 = dom.createElement("a");
            dom.setAttribute(el1, "target", "_blank");
            var el2 = dom.createComment("");
            dom.appendChild(el1, el2);
            dom.appendChild(el0, el1);
            var el1 = dom.createTextNode("\n");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element2 = dom.childAt(fragment, [1]);
            var morphs = new Array(2);
            morphs[0] = dom.createAttrMorph(element2, 'href');
            morphs[1] = dom.createMorphAt(element2, 0, 0);
            return morphs;
          },
          statements: [["attribute", "href", ["get", "extern", ["loc", [null, [68, 16], [68, 22]]], 0, 0, 0, 0], 0, 0, 0, 0], ["content", "caption", ["loc", [null, [68, 41], [68, 52]]], 0, 0, 0, 0]],
          locals: [],
          templates: []
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 69,
                  "column": 4
                },
                "end": {
                  "line": 71,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element1 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element1, 'href');
              morphs[1] = dom.createAttrMorph(element1, 'onclick');
              morphs[2] = dom.createMorphAt(element1, 0, 0);
              return morphs;
            },
            statements: [["attribute", "href", ["subexpr", "concat", ["hilfe-tooltips/", ["get", "tooltip", ["loc", [null, [70, 41], [70, 48]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [70, 50]]], 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["openHelp"], [], ["loc", [null, [null, null], [70, 80]]], 0, 0], 0, 0, 0, 0], ["content", "caption", ["loc", [null, [70, 81], [70, 92]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        var child1 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 71,
                  "column": 4
                },
                "end": {
                  "line": 73,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              var el2 = dom.createComment("");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n    ");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(2);
              morphs[0] = dom.createAttrMorph(element0, 'href');
              morphs[1] = dom.createMorphAt(element0, 0, 0);
              return morphs;
            },
            statements: [["attribute", "href", ["subexpr", "concat", ["hilfe/", ["get", "intern", ["loc", [null, [72, 32], [72, 38]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [72, 40]]], 0, 0], 0, 0, 0, 0], ["content", "caption", ["loc", [null, [72, 41], [72, 52]]], 0, 0, 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 69,
                "column": 4
              },
              "end": {
                "line": 73,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "if", [["get", "showTooltip", ["loc", [null, [69, 14], [69, 25]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [69, 4], [73, 4]]]]],
          locals: [],
          templates: [child0, child1]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 65,
              "column": 0
            },
            "end": {
              "line": 75,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createElement("div");
          dom.setAttribute(el1, "style", "display: inline; position: relative");
          var el2 = dom.createTextNode("\n");
          dom.appendChild(el1, el2);
          var el2 = dom.createComment("");
          dom.appendChild(el1, el2);
          var el2 = dom.createTextNode("  ");
          dom.appendChild(el1, el2);
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(dom.childAt(fragment, [1]), 1, 1);
          return morphs;
        },
        statements: [["block", "if", [["get", "showHref", ["loc", [null, [67, 10], [67, 18]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [67, 4], [73, 11]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 75,
            "column": 7
          }
        },
        "moduleName": "mpforst-ember/app/pods/components/editor-link-atom/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "if", [["get", "editor", ["loc", [null, [1, 6], [1, 12]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [1, 0], [75, 7]]]]],
      locals: [],
      templates: [child0, child1]
    };
  }());
});