define("mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 8
                },
                "end": {
                  "line": 5,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.verjuengung_passend", ["loc", [null, [4, 42], [4, 80]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [4, 86], [4, 90]]], 0, 0, 0, 0]], [], [], 0, 0], "items", ["subexpr", "@mut", [["get", "passend", ["loc", [null, [4, 97], [4, 104]]], 0, 0, 0, 0]], [], [], 0, 0], "displayEmptyItem", true], ["loc", [null, [4, 10], [4, 128]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 4
              },
              "end": {
                "line": 6,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Herkünfte für Naturverjüngung", "area", "Bestand", "name", "HerkuenfteNaturverjuengung"], 0, null, ["loc", [null, [3, 8], [5, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 7,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 3], 0, null, ["loc", [null, [2, 4], [6, 26]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    var child1 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 11,
                  "column": 8
                },
                "end": {
                  "line": 13,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.verjuengung_flaechenanteile_id", ["loc", [null, [12, 42], [12, 91]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [12, 97], [12, 101]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/verjuengung_flaechenanteile"], ["loc", [null, [12, 10], [12, 154]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 10,
                "column": 4
              },
              "end": {
                "line": 14,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Flächenanteil", "kennzahlen", true, "area", "Bestand", "name", "Flaechenanteil"], 0, null, ["loc", [null, [11, 8], [13, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 17,
                  "column": 8
                },
                "end": {
                  "line": 19,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.verjuengung_hoehe_id", ["loc", [null, [18, 42], [18, 81]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [18, 87], [18, 91]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/verjuengung_hoehe"], ["loc", [null, [18, 10], [18, 134]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 16,
                "column": 4
              },
              "end": {
                "line": 20,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Verjüngungshöhe", "kennzahlen", true, "area", "Bestand", "name", "Verjuengungshoehe"], 0, null, ["loc", [null, [17, 8], [19, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 23,
                  "column": 8
                },
                "end": {
                  "line": 25,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.verjuengung_ueberwiegende_baumart_id", ["loc", [null, [24, 42], [24, 97]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [24, 103], [24, 107]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/verjuengung", "displayEmptyItem", true], ["loc", [null, [24, 10], [24, 166]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 22,
                "column": 4
              },
              "end": {
                "line": 26,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "überwiegende Verjüngungsbaumart", "area", "Bestand", "name", "ueberweigendeVerjuengungsbaumart", "required", true, "hiebsatz", true], 0, null, ["loc", [null, [23, 8], [25, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 28,
                "column": 4
              },
              "end": {
                "line": 29,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 9,
              "column": 0
            },
            "end": {
              "line": 30,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 3], 0, null, ["loc", [null, [10, 4], [14, 26]]]], ["block", "foundation-column", [], ["large", 3], 1, null, ["loc", [null, [16, 4], [20, 26]]]], ["block", "foundation-column", [], ["large", 3], 2, null, ["loc", [null, [22, 4], [26, 26]]]], ["block", "foundation-column", [], ["large", 3], 3, null, ["loc", [null, [28, 4], [29, 26]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    var child2 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 34,
                  "column": 8
                },
                "end": {
                  "line": 36,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.verjuengung_baumart_beigemischt1_id", ["loc", [null, [35, 42], [35, 96]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [35, 102], [35, 106]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/verjuengung", "displayEmptyItem", true], ["loc", [null, [35, 10], [35, 165]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 33,
                "column": 4
              },
              "end": {
                "line": 37,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Baumart beigemischt 1", "area", "Bestand", "name", "BaumartBeigemischt1", "required", true, "hiebsatz", true], 0, null, ["loc", [null, [34, 8], [36, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 40,
                  "column": 8
                },
                "end": {
                  "line": 42,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.verjuengung_baumart_beigemischt2_id", ["loc", [null, [41, 42], [41, 96]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [41, 102], [41, 106]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/verjuengung", "displayEmptyItem", true], ["loc", [null, [41, 10], [41, 165]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 39,
                "column": 4
              },
              "end": {
                "line": 43,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Baumart beigemischt 2", "area", "Bestand", "name", "BaumartBeigemischt2", "required", true, "hiebsatz", true], 0, null, ["loc", [null, [40, 8], [42, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 45,
                "column": 4
              },
              "end": {
                "line": 46,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 47,
                "column": 4
              },
              "end": {
                "line": 48,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 32,
              "column": 0
            },
            "end": {
              "line": 49,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 5, 5, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 3], 0, null, ["loc", [null, [33, 4], [37, 26]]]], ["block", "foundation-column", [], ["large", 3], 1, null, ["loc", [null, [39, 4], [43, 26]]]], ["block", "foundation-column", [], ["large", 3], 2, null, ["loc", [null, [45, 4], [46, 26]]]], ["block", "foundation-column", [], ["large", 3], 3, null, ["loc", [null, [47, 4], [48, 26]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    var child3 = function () {
      var child0 = function () {
        var child0 = function () {
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 53,
                  "column": 8
                },
                "end": {
                  "line": 55,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("          ");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
              return morphs;
            },
            statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "@mut", [["get", "model.beschreibung.verjuengung_verbiss_situation_id", ["loc", [null, [54, 42], [54, 93]]], 0, 0, 0, 0]], [], [], 0, 0], "edit", ["subexpr", "@mut", [["get", "edit", ["loc", [null, [54, 99], [54, 103]]], 0, 0, 0, 0]], [], [], 0, 0], "items", "api/stammdaten/verbiss", "displayEmptyItem", true], ["loc", [null, [54, 10], [54, 158]]], 0, 0]],
            locals: [],
            templates: []
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 52,
                "column": 4
              },
              "end": {
                "line": 56,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-label", [], ["titel", "Verbisssituation", "required", true, "area", "Bestand", "name", "Verbisssituation"], 0, null, ["loc", [null, [53, 8], [55, 29]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 58,
                "column": 4
              },
              "end": {
                "line": 59,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child2 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 4
              },
              "end": {
                "line": 61,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      var child3 = function () {
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 62,
                "column": 4
              },
              "end": {
                "line": 63,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
          },
          isEmpty: true,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            return el0;
          },
          buildRenderNodes: function buildRenderNodes() {
            return [];
          },
          statements: [],
          locals: [],
          templates: []
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 51,
              "column": 0
            },
            "end": {
              "line": 64,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(4);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
          morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
          morphs[3] = dom.createMorphAt(fragment, 4, 4, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 3], 0, null, ["loc", [null, [52, 4], [56, 26]]]], ["block", "foundation-column", [], ["large", 3], 1, null, ["loc", [null, [58, 4], [59, 26]]]], ["block", "foundation-column", [], ["large", 3], 2, null, ["loc", [null, [60, 4], [61, 26]]]], ["block", "foundation-column", [], ["large", 3], 3, null, ["loc", [null, [62, 4], [63, 26]]]]],
        locals: [],
        templates: [child0, child1, child2, child3]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 65,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-bestand/components/x-beschreibung/x-verjuengung/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(4);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[2] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        morphs[3] = dom.createMorphAt(fragment, 6, 6, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "foundation-row", [], ["class", "clearfix"], 0, null, ["loc", [null, [1, 0], [7, 19]]]], ["block", "foundation-row", [], ["class", "clearfix"], 1, null, ["loc", [null, [9, 0], [30, 19]]]], ["block", "foundation-row", [], ["class", "clearfix"], 2, null, ["loc", [null, [32, 0], [49, 19]]]], ["block", "foundation-row", [], ["class", "clearfix"], 3, null, ["loc", [null, [51, 0], [64, 19]]]]],
      locals: [],
      templates: [child0, child1, child2, child3]
    };
  }());
});