define('mpforst-ember/app/pods/admin-help-toc/controller', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function findPage(model, id) {
    if (id == 'Hilfe') {
      return null;
    }
    var m = model.find(function (x) {
      return x.id == id;
    });
    if (m) {
      return m;
    }
    for (var i = 0; i < model.length; ++i) {
      m = findPage(model[i].model, id);
      if (m) {
        return m;
      }
    }
    return null;
  }

  exports.default = Ember.Controller.extend({
    breadCrumb: 'Hilfe - Inhaltsverzeichnis',
    nmodel: Ember.computed('model', function () {
      return JSON.parse(JSON.stringify(this.get('model')));
    }),
    actions: {
      actionUp: function actionUp(page) {
        var model = this.get('model');
        var parent = findPage(this.get('model'), page.parent);
        if (parent || page.parent == 'Hilfe') {
          model = parent ? parent.model : model;
          for (var i = 0; i < model.length - 1; ++i) {
            if (model[i + 1].id == page.id) {
              model[i + 1] = model[i];
              model[i] = page;
              break;
            }
          }
        }
        this.notifyPropertyChange('nmodel');
      },
      actionDown: function actionDown(page) {
        debugger;
        var model = this.get('model');
        var parent = findPage(this.get('model'), page.parent);
        if (parent || page.parent == 'Hilfe') {
          model = parent ? parent.model : model;
          for (var i = 1; i < model.length; ++i) {
            if (model[i - 1].id == page.id) {
              model[i - 1] = model[i];
              model[i] = page;
              break;
            }
          }
        }
        this.notifyPropertyChange('nmodel');
      }
    }
  });
});