define('mpforst-ember/app/pods/components/mpf-input/x-select/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function ensurePromise(x) {
    return new Ember.RSVP.Promise(function (resolve) {
      resolve(x);
    });
  }

  var cache = {};
  var cache_def = {};
  var cache_todo = [];

  function mergedRequestForCache() {
    if (cache_todo.length === 0) {
      return;
    }

    cache_todo.forEach(function (res) {
      var key = 'legacy_mpforst_stammdaten_' + res.replace(/\//g, '_');
      try {
        var data = sessionStorage.getItem(key);
        if (data) {
          data = JSON.parse(data);
          cache_def[res].resolve(data);
          cache[res] = data;
          return;
        }
      } catch (e) {
        e;
        /* IGNORE */
      }
      Ember.$.ajax({
        url: res,
        method: 'GET',
        //data: JSON.stringify(cache_todo),
        contentType: "application/json; charset=utf-8",
        dataType: "json"
      }).then(function (data) {
        try {
          sessionStorage.setItem(key, JSON.stringify(data));
        } catch (e) {
          e;
          /* IGNORE */
        }
        cache_def[res].resolve(data);
        cache[res] = data;
      });
    });

    cache_todo = [];
  }

  exports.default = Ember.Component.extend({
    tagName: '',
    loading: true,
    edit: true,
    value: null,
    items: null,
    _items: null,
    showAsEmpty: false,
    _version: 0,
    displayEmptyItem: false,

    itemsObserver: Ember.on('init', Ember.observer('items', function () {
      var _this = this;

      this.setProperties({
        loading: true
      });
      var items = this.get('items');
      var promise;
      if (typeof items === 'string' || items instanceof String) {
        if (cache[items]) {
          if (Array.isArray(cache[items])) {
            this.setProperties({
              loading: false,
              _items: cache[items]
            });
            return;
          } else {
            promise = cache[items];
          }
        } else {
          var deferred = Ember.$.Deferred();

          promise = deferred.promise();
          cache_def[items] = deferred;
          cache[items] = promise;
          cache_todo.push(items);

          //Ember.run.next(() => {
          mergedRequestForCache();
          //});
        }
      } else if (Array.isArray(items)) {
        this.setProperties({
          loading: false,
          _items: items
        });
        return;
      } else {
        promise = ensurePromise(this.get('items'));
      }

      // promise route
      this._version += 1;
      // get the items
      var captured_version = this._version;
      promise.then(function (x) {
        if (captured_version !== _this._version) {
          return;
        }
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.setProperties({
          loading: false,
          _items: x
        });
      }, function () {
        if (captured_version !== _this._version) {
          return;
        }
        if (_this.isDestroyed || _this.isDestroying) {
          return;
        }

        _this.setProperties({
          loading: false,
          _items: []
        });
      });
    })),

    _update_value: 0,
    _value_observer: Ember.observer('value', '_items', 'showAsEmpty', function () {
      if (this.get('showAsEmpty')) {
        return;
      }
      this.set('_update_value', this.get('_update_value') + 1);
    }),

    _value_reset_observer: Ember.observer('_update_value', function () {
      var _this2 = this;

      var data = this.getProperties('value', '_items');
      if (!data._items) {
        return null;
      }
      var items = data._items.filter(function (x) {
        /* jslint eqeq: true */
        return x.value == data.value;
      });
      if (items.length !== 1) {
        if (this.get('displayEmptyItem')) {
          Ember.run.next(function () {
            if (_this2.isDestroyed || _this2.isDestroying) {
              return;
            }
            _this2.set('value', null);
          });
        } else if (data._items && data._items.length) {
          // automatisch auf ersten wert setzen
          Ember.run.next(function () {
            if (_this2.isDestroyed || _this2.isDestroying) {
              return;
            }
            _this2.set('value', data._items[0].value);
          });
        } else {
          Ember.run.next(function () {
            if (_this2.isDestroyed || _this2.isDestroying) {
              return;
            }
            _this2.set('value', null);
          });
        }
      } else {
        // change value to the selected value..
        if (items[0].value !== data.value) {
          this.set('value', items[0].value);
        }
      }
    }),

    _caption: Ember.computed('_update_value', function () {
      var data = this.getProperties('value', '_items');
      if (!data._items || !data._items.length) {
        return null;
      }
      var items = data._items.filter(function (x) {
        /* jslint eqeq: true */
        return x.value == data.value;
      });
      if (!items.length) {
        // keine items gefunden..
        return null;
      }
      return items[0].caption;
    }),

    caption: Ember.computed('_caption', {
      get: function get() {
        return this.get('_caption');
      },
      set: function set(key, value) {
        if (value !== this.get('_caption')) {
          this.set('value', null);
          this.sendAction('autocomplete_change', value);
        }
        return value;
      }
    }),

    firstitem: Ember.computed('_items', function () {
      return (this.get('_items') || [])[0];
    }),

    isFirefox: Ember.computed(function () {
      //return navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      return true;
    }),

    actions: {
      onSelectFocus: function onSelectFocus() {
        this.set('inFocus', true);
      },
      onSelectBlur: function onSelectBlur() {
        this.set('inFocus', false);
      },
      onSelectChange: function onSelectChange(e) {
        var items = this.get('_items');
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = items[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var item = _step.value;

            var value_str = "";
            if (item.value !== null) {
              value_str = item.value.toString();
            }
            if (value_str === e.target.value) {
              this.set('value', item.value);
              return;
            }
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        this.set('value', null);
      }
    }
  });
});