define("mpforst-ember/app/pods/admin-stats/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 16,
                        "column": 10
                      },
                      "end": {
                        "line": 18,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "tbl.row.typ", ["loc", [null, [17, 12], [17, 27]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 19,
                        "column": 10
                      },
                      "end": {
                        "line": 21,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.users", ["loc", [null, [20, 53], [20, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [20, 44], [20, 67]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [20, 12], [20, 91]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child2 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 22,
                        "column": 10
                      },
                      "end": {
                        "line": 24,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.betriebe", ["loc", [null, [23, 53], [23, 69]]], 0, 0, 0, 0]], [], ["loc", [null, [23, 44], [23, 70]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [23, 12], [23, 94]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child3 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 25,
                        "column": 10
                      },
                      "end": {
                        "line": 27,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.betriebe_mit_bestaende", ["loc", [null, [26, 53], [26, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [26, 44], [26, 84]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [26, 12], [26, 108]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child4 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 28,
                        "column": 10
                      },
                      "end": {
                        "line": 30,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.teilbetriebe", ["loc", [null, [29, 53], [29, 73]]], 0, 0, 0, 0]], [], ["loc", [null, [29, 44], [29, 74]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [29, 12], [29, 98]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child5 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 31,
                        "column": 10
                      },
                      "end": {
                        "line": 33,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.teilbetriebe_mit_bestaende", ["loc", [null, [32, 53], [32, 87]]], 0, 0, 0, 0]], [], ["loc", [null, [32, 44], [32, 88]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [32, 12], [32, 112]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child6 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 34,
                        "column": 10
                      },
                      "end": {
                        "line": 36,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.bestaende", ["loc", [null, [35, 53], [35, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [35, 44], [35, 71]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [35, 12], [35, 95]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child7 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 37,
                        "column": 10
                      },
                      "end": {
                        "line": 39,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.bestaende_mit_tax", ["loc", [null, [38, 53], [38, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [38, 44], [38, 79]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [38, 12], [38, 103]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child8 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 40,
                        "column": 10
                      },
                      "end": {
                        "line": 42,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.bestaende_mit_wzp", ["loc", [null, [41, 53], [41, 78]]], 0, 0, 0, 0]], [], ["loc", [null, [41, 44], [41, 79]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [41, 12], [41, 103]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child9 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 43,
                        "column": 10
                      },
                      "end": {
                        "line": 45,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.betrieb_min_flaeche_ha", ["loc", [null, [44, 53], [44, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [44, 44], [44, 84]]], 0, 0], "postfix", "ha", "fraction", 2, "edit", false], ["loc", [null, [44, 12], [44, 121]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child10 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 46,
                        "column": 10
                      },
                      "end": {
                        "line": 48,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.betrieb_avg_flaeche_ha", ["loc", [null, [47, 53], [47, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [47, 44], [47, 84]]], 0, 0], "postfix", "ha", "fraction", 2, "edit", false], ["loc", [null, [47, 12], [47, 121]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child11 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 49,
                        "column": 10
                      },
                      "end": {
                        "line": 51,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.betrieb_max_flaeche_ha", ["loc", [null, [50, 53], [50, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [50, 44], [50, 84]]], 0, 0], "postfix", "ha", "fraction", 2, "edit", false], ["loc", [null, [50, 12], [50, 121]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child12 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 52,
                        "column": 10
                      },
                      "end": {
                        "line": 54,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.betrieb_sum_flaeche_ha", ["loc", [null, [53, 53], [53, 83]]], 0, 0, 0, 0]], [], ["loc", [null, [53, 44], [53, 84]]], 0, 0], "postfix", "ha", "fraction", 2, "edit", false], ["loc", [null, [53, 12], [53, 121]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 15,
                      "column": 8
                    },
                    "end": {
                      "line": 55,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(13);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                  morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
                  morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                  morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
                  morphs[7] = dom.createMorphAt(fragment, 7, 7, contextualElement);
                  morphs[8] = dom.createMorphAt(fragment, 8, 8, contextualElement);
                  morphs[9] = dom.createMorphAt(fragment, 9, 9, contextualElement);
                  morphs[10] = dom.createMorphAt(fragment, 10, 10, contextualElement);
                  morphs[11] = dom.createMorphAt(fragment, 11, 11, contextualElement);
                  morphs[12] = dom.createMorphAt(fragment, 12, 12, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["block", "tbl.column", [], ["id", 1, "title", ""], 0, null, ["loc", [null, [16, 10], [18, 25]]]], ["block", "tbl.column", [], ["id", 2, "title", "Benutzer"], 1, null, ["loc", [null, [19, 10], [21, 25]]]], ["block", "tbl.column", [], ["id", 3, "title", "Betriebe"], 2, null, ["loc", [null, [22, 10], [24, 25]]]], ["block", "tbl.column", [], ["id", 4, "title", "Betriebe mit Beständen"], 3, null, ["loc", [null, [25, 10], [27, 25]]]], ["block", "tbl.column", [], ["id", 5, "title", "Teilbetriebe"], 4, null, ["loc", [null, [28, 10], [30, 25]]]], ["block", "tbl.column", [], ["id", 6, "title", "Teilbetriebe mit Beständen"], 5, null, ["loc", [null, [31, 10], [33, 25]]]], ["block", "tbl.column", [], ["id", 7, "title", "Bestände"], 6, null, ["loc", [null, [34, 10], [36, 25]]]], ["block", "tbl.column", [], ["id", 8, "title", "Bestände mit Taxation"], 7, null, ["loc", [null, [37, 10], [39, 25]]]], ["block", "tbl.column", [], ["id", 9, "title", "Bestände mit WZPX"], 8, null, ["loc", [null, [40, 10], [42, 25]]]], ["block", "tbl.column", [], ["id", 10, "title", "Betriebe min. Fläche"], 9, null, ["loc", [null, [43, 10], [45, 25]]]], ["block", "tbl.column", [], ["id", 11, "title", "Betriebe avg. Fläche"], 10, null, ["loc", [null, [46, 10], [48, 25]]]], ["block", "tbl.column", [], ["id", 12, "title", "Betriebe max. Fläche"], 11, null, ["loc", [null, [49, 10], [51, 25]]]], ["block", "tbl.column", [], ["id", 13, "title", "Betriebe sum. Fläche"], 12, null, ["loc", [null, [52, 10], [54, 25]]]]],
                locals: ["tbl"],
                templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9, child10, child11, child12]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 14,
                    "column": 6
                  },
                  "end": {
                    "line": 56,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "x-table", [], ["paging", 15, "model", ["subexpr", "@mut", [["get", "model.stats1", ["loc", [null, [15, 35], [15, 47]]], 0, 0, 0, 0]], [], [], 0, 0], "cacheKey", ["subexpr", "concat", ["stats1"], [], ["loc", [null, [15, 57], [15, 74]]], 0, 0]], 0, null, ["loc", [null, [15, 8], [55, 20]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 13,
                  "column": 4
                },
                "end": {
                  "line": 58,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              return morphs;
            },
            statements: [["block", "x-delayed", [], [], 0, null, ["loc", [null, [14, 6], [56, 20]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 12,
                "column": 2
              },
              "end": {
                "line": 59,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [13, 4], [58, 30]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      var child1 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 65,
                        "column": 10
                      },
                      "end": {
                        "line": 67,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "tbl.row.bundesland", ["loc", [null, [66, 12], [66, 34]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 68,
                        "column": 10
                      },
                      "end": {
                        "line": 70,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.jahr", ["loc", [null, [69, 53], [69, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [69, 44], [69, 66]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [69, 12], [69, 90]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child2 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 71,
                        "column": 10
                      },
                      "end": {
                        "line": 73,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.monat", ["loc", [null, [72, 53], [72, 66]]], 0, 0, 0, 0]], [], ["loc", [null, [72, 44], [72, 67]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [72, 12], [72, 91]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child3 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 74,
                        "column": 10
                      },
                      "end": {
                        "line": 76,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["content", "tbl.row.typ", ["loc", [null, [75, 12], [75, 27]]], 0, 0, 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child4 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 78,
                        "column": 10
                      },
                      "end": {
                        "line": 80,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.anzahl_betriebe", ["loc", [null, [79, 53], [79, 76]]], 0, 0, 0, 0]], [], ["loc", [null, [79, 44], [79, 77]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [79, 12], [79, 101]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child5 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 81,
                        "column": 10
                      },
                      "end": {
                        "line": 83,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.anzahl_teilbetriebe", ["loc", [null, [82, 53], [82, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [82, 44], [82, 81]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [82, 12], [82, 105]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child6 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 84,
                        "column": 10
                      },
                      "end": {
                        "line": 86,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.anzahl_bestaende", ["loc", [null, [85, 53], [85, 77]]], 0, 0, 0, 0]], [], ["loc", [null, [85, 44], [85, 78]]], 0, 0], "fraction", 0, "edit", false], ["loc", [null, [85, 12], [85, 102]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child7 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 88,
                        "column": 10
                      },
                      "end": {
                        "line": 90,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.flaeche_ha_sum", ["loc", [null, [89, 53], [89, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [89, 44], [89, 76]]], 0, 0], "postfix", "ha", "fraction", 2, "edit", false], ["loc", [null, [89, 12], [89, 113]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child8 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 91,
                        "column": 10
                      },
                      "end": {
                        "line": 93,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.flaeche_ha_min", ["loc", [null, [92, 53], [92, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [92, 44], [92, 76]]], 0, 0], "postfix", "ha", "fraction", 2, "edit", false], ["loc", [null, [92, 12], [92, 113]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              var child9 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 94,
                        "column": 10
                      },
                      "end": {
                        "line": 96,
                        "column": 10
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("            ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                    return morphs;
                  },
                  statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.flaeche_ha_max", ["loc", [null, [95, 53], [95, 75]]], 0, 0, 0, 0]], [], ["loc", [null, [95, 44], [95, 76]]], 0, 0], "postfix", "ha", "fraction", 2, "edit", false], ["loc", [null, [95, 12], [95, 113]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 64,
                      "column": 8
                    },
                    "end": {
                      "line": 97,
                      "column": 8
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
                },
                isEmpty: false,
                arity: 1,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("        ");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(10);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                  morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                  morphs[4] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                  morphs[5] = dom.createMorphAt(fragment, 6, 6, contextualElement);
                  morphs[6] = dom.createMorphAt(fragment, 7, 7, contextualElement);
                  morphs[7] = dom.createMorphAt(fragment, 9, 9, contextualElement);
                  morphs[8] = dom.createMorphAt(fragment, 10, 10, contextualElement);
                  morphs[9] = dom.createMorphAt(fragment, 11, 11, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  return morphs;
                },
                statements: [["block", "tbl.column", [], ["id", 1, "title", "Bundesland"], 0, null, ["loc", [null, [65, 10], [67, 25]]]], ["block", "tbl.column", [], ["id", 2, "title", "Jahr"], 1, null, ["loc", [null, [68, 10], [70, 25]]]], ["block", "tbl.column", [], ["id", 3, "title", "Monat"], 2, null, ["loc", [null, [71, 10], [73, 25]]]], ["block", "tbl.column", [], ["id", 4, "title", "Type"], 3, null, ["loc", [null, [74, 10], [76, 25]]]], ["block", "tbl.column", [], ["id", 5, "title", "Betriebe"], 4, null, ["loc", [null, [78, 10], [80, 25]]]], ["block", "tbl.column", [], ["id", 6, "title", "Teilbetriebe"], 5, null, ["loc", [null, [81, 10], [83, 25]]]], ["block", "tbl.column", [], ["id", 7, "title", "Bestände"], 6, null, ["loc", [null, [84, 10], [86, 25]]]], ["block", "tbl.column", [], ["id", 8, "title", "Bestand sum. Fläche"], 7, null, ["loc", [null, [88, 10], [90, 25]]]], ["block", "tbl.column", [], ["id", 9, "title", "Bestand min. Fläche"], 8, null, ["loc", [null, [91, 10], [93, 25]]]], ["block", "tbl.column", [], ["id", 10, "title", "Bestand max. Fläche"], 9, null, ["loc", [null, [94, 10], [96, 25]]]]],
                locals: ["tbl"],
                templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8, child9]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 63,
                    "column": 6
                  },
                  "end": {
                    "line": 98,
                    "column": 6
                  }
                },
                "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
              },
              isEmpty: false,
              arity: 0,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createElement("br");
                dom.appendChild(el0, el1);
                var el1 = dom.createTextNode("\n");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(1);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                dom.insertBoundary(fragment, 0);
                return morphs;
              },
              statements: [["block", "x-table", [], ["paging", 1500000, "model", ["subexpr", "@mut", [["get", "model.stats2", ["loc", [null, [64, 40], [64, 52]]], 0, 0, 0, 0]], [], [], 0, 0], "cacheKey", ["subexpr", "concat", ["stats2"], [], ["loc", [null, [64, 62], [64, 79]]], 0, 0]], 0, null, ["loc", [null, [64, 8], [97, 20]]]]],
              locals: [],
              templates: [child0]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 61,
                  "column": 4
                },
                "end": {
                  "line": 100,
                  "column": 4
                }
              },
              "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("a");
              dom.setAttribute(el1, "class", "button tiny");
              dom.setAttribute(el1, "download", "statistik2.csv");
              var el2 = dom.createTextNode("Download");
              dom.appendChild(el1, el2);
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("      ");
              dom.appendChild(el0, el1);
              var el1 = dom.createElement("br");
              dom.appendChild(el0, el1);
              var el1 = dom.createTextNode("\n");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var element0 = dom.childAt(fragment, [1]);
              var morphs = new Array(3);
              morphs[0] = dom.createAttrMorph(element0, 'href');
              morphs[1] = dom.createAttrMorph(element0, 'onclick');
              morphs[2] = dom.createMorphAt(fragment, 3, 3, contextualElement);
              return morphs;
            },
            statements: [["attribute", "href", ["get", "csv", ["loc", [null, [62, 16], [62, 19]]], 0, 0, 0, 0], 0, 0, 0, 0], ["attribute", "onclick", ["subexpr", "action", ["ie11hack", ["get", "csv", ["loc", [null, [62, 96], [62, 99]]], 0, 0, 0, 0]], [], ["loc", [null, [null, null], [62, 101]]], 0, 0], 0, 0, 0, 0], ["block", "x-delayed", [], [], 0, null, ["loc", [null, [63, 6], [98, 20]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 60,
                "column": 2
              },
              "end": {
                "line": 101,
                "column": 2
              }
            },
            "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [61, 4], [100, 30]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 11,
              "column": 0
            },
            "end": {
              "line": 102,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "mpf-magellan-section", [], ["titel", "Statistik", "class", "clearfix sum"], 0, null, ["loc", [null, [12, 2], [59, 27]]]], ["block", "mpf-magellan-section", [], ["titel", "Statistik 2", "class", "clearfix"], 1, null, ["loc", [null, [60, 2], [101, 27]]]]],
        locals: [],
        templates: [child0, child1]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 104,
            "column": 10
          }
        },
        "moduleName": "mpforst-ember/app/pods/admin-stats/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("style");
        var el2 = dom.createTextNode("\n  .sum tbody > tr > td:first-child {\n    border-right: 1px solid black !important;\n  }\n  .sum tbody > tr:last-child td {\n    border-top: 2px solid black;\n    font-weight: bold;\n  }\n\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(2);
        morphs[0] = dom.createMorphAt(fragment, 2, 2, contextualElement);
        morphs[1] = dom.createMorphAt(fragment, 4, 4, contextualElement);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "mpf-magellan", [], [], 0, null, ["loc", [null, [11, 0], [102, 17]]]], ["content", "outlet", ["loc", [null, [104, 0], [104, 10]]], 0, 0, 0, 0]],
      locals: [],
      templates: [child0]
    };
  }());
});