define('mpforst-ember/app/pods/assistent-taxation-editor/components/x-relabs/component', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: 'table',
    classNames: ['mpf-postfix'],
    edit: true,
    postfix: null,

    _didChange: false,

    didInsertElement: function didInsertElement() {
      this.$().on('focusout', '.x-relabs', this._blurEvent.bind(this));
      this.$().on('change', '.x-relabs', this._changeEvent.bind(this));
    },
    _changeEvent: function _changeEvent() {
      this._didChange = true;
    },
    _blurEvent: function _blurEvent() {
      if (this._didChange) {
        this._didChange = false;
        Ember.run.schedule('afterRender', this._forceFocus.bind(this));
      }
    },
    _forceFocus: function _forceFocus() {
      if (this.isDestroyed || this.isDestroying) {
        return;
      }
      this.$('.forceFocusHelper').find('input, select').focus();
    },


    boniFixed: Ember.computed('boni', 'valueRel', 'plusMinusItems', function () {
      var boni = this.get('boni');
      var valueRel = this.get('valueRel');
      var plusMinusItems = this.get('plusMinusItems');

      return boni;
    }),

    relAbsItems: [{
      value: 0,
      caption: 'relativ'
    }, {
      value: 1,
      caption: 'absolut'
    }],

    plusMinusItems: [{
      value: 1005,
      caption: '++'
    }, {
      value: 1004,
      caption: '+'
    }, {
      value: 1003,
      caption: '='
    }, {
      value: 1002,
      caption: '−'
    }, {
      value: 1001,
      caption: '−−'
    }]
  });
});