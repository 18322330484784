define("mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.HTMLBars.template(function () {
    var child0 = function () {
      var child0 = function () {
        var child0 = function () {
          var child0 = function () {
            var child0 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 6,
                        "column": 14
                      },
                      "end": {
                        "line": 6,
                        "column": 99
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createComment("");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                    var morphs = new Array(1);
                    morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                    dom.insertBoundary(fragment, 0);
                    dom.insertBoundary(fragment, null);
                    return morphs;
                  },
                  statements: [["inline", "unbound", [["get", "tbl.row.bezeichnung", ["loc", [null, [6, 78], [6, 97]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 68], [6, 99]]], 0, 0]],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 5,
                      "column": 12
                    },
                    "end": {
                      "line": 7,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["block", "link-to", ["grunddaten-bestand", ["subexpr", "unbound", [["get", "tbl.row.id", ["loc", [null, [6, 55], [6, 65]]], 0, 0, 0, 0]], [], ["loc", [null, [6, 46], [6, 66]]], 0, 0]], [], 0, null, ["loc", [null, [6, 14], [6, 111]]]]],
                locals: [],
                templates: [child0]
              };
            }();
            var child1 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 8,
                      "column": 12
                    },
                    "end": {
                      "line": 10,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.flaeche", ["loc", [null, [9, 55], [9, 70]]], 0, 0, 0, 0]], [], ["loc", [null, [9, 46], [9, 71]]], 0, 0], "postfix", "ha", "fraction", 4, "edit", false], ["loc", [null, [9, 14], [9, 108]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child2 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 11,
                      "column": 12
                    },
                    "end": {
                      "line": 13,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "unbound", [["get", "tbl.row.nutzungsklasse_id", ["loc", [null, [12, 55], [12, 80]]], 0, 0, 0, 0]], [], ["loc", [null, [12, 46], [12, 81]]], 0, 0], "items", "api/stammdaten/nutzungsklasse", "edit", false], ["loc", [null, [12, 14], [12, 132]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child3 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 14,
                      "column": 12
                    },
                    "end": {
                      "line": 16,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "unbound", [["get", "tbl.row.bewirtschaftungseinschraenkung_id", ["loc", [null, [15, 55], [15, 96]]], 0, 0, 0, 0]], [], ["loc", [null, [15, 46], [15, 97]]], 0, 0], "items", "api/stammdaten/bewirtschaftungseinschraenkung", "edit", false], ["loc", [null, [15, 14], [15, 164]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child4 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 17,
                      "column": 12
                    },
                    "end": {
                      "line": 19,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.alter", ["loc", [null, [18, 55], [18, 68]]], 0, 0, 0, 0]], [], ["loc", [null, [18, 46], [18, 69]]], 0, 0], "fraction", 0, "postfix", "Jahre", "edit", false], ["loc", [null, [18, 14], [18, 109]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child5 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 20,
                      "column": 12
                    },
                    "end": {
                      "line": 22,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.vorrat", ["loc", [null, [21, 55], [21, 69]]], 0, 0, 0, 0]], [], ["loc", [null, [21, 46], [21, 70]]], 0, 0], "fraction", 0, "postfix", "Vfm", "edit", false], ["loc", [null, [21, 14], [21, 108]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child6 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 23,
                      "column": 12
                    },
                    "end": {
                      "line": 25,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "number", "value", ["subexpr", "unbound", [["get", "tbl.row.seehoehe", ["loc", [null, [24, 55], [24, 71]]], 0, 0, 0, 0]], [], ["loc", [null, [24, 46], [24, 72]]], 0, 0], "postfix", "m", "fraction", 0, "edit", false], ["loc", [null, [24, 14], [24, 108]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child7 = function () {
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 26,
                      "column": 12
                    },
                    "end": {
                      "line": 28,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createTextNode("              ");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  var el1 = dom.createTextNode("\n");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                  return morphs;
                },
                statements: [["inline", "mpf-input", [], ["type", "select", "value", ["subexpr", "unbound", [["get", "tbl.row.status", ["loc", [null, [27, 55], [27, 69]]], 0, 0, 0, 0]], [], ["loc", [null, [27, 46], [27, 70]]], 0, 0], "items", "api/stammdaten/kennzahlen_status"], ["loc", [null, [27, 14], [27, 113]]], 0, 0]],
                locals: [],
                templates: []
              };
            }();
            var child8 = function () {
              var child0 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 30,
                        "column": 14
                      },
                      "end": {
                        "line": 32,
                        "column": 14
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "fa fa-exclamation-triangle");
                    dom.setAttribute(el1, "style", "margin-left: 0.5em; margin-right: 0.5em;");
                    dom.setAttribute(el1, "title", "Kennzahlen nicht aktuell");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              var child1 = function () {
                return {
                  meta: {
                    "revision": "Ember@2.9.1",
                    "loc": {
                      "source": null,
                      "start": {
                        "line": 32,
                        "column": 14
                      },
                      "end": {
                        "line": 34,
                        "column": 14
                      }
                    },
                    "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                  },
                  isEmpty: false,
                  arity: 0,
                  cachedFragment: null,
                  hasRendered: false,
                  buildFragment: function buildFragment(dom) {
                    var el0 = dom.createDocumentFragment();
                    var el1 = dom.createTextNode("                ");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createElement("i");
                    dom.setAttribute(el1, "class", "fa");
                    dom.setAttribute(el1, "style", "margin-left: 0.5em; width: 1em; margin-right: 0.5em;");
                    dom.setAttribute(el1, "title", "Kennzahlen sind nicht aktuell");
                    dom.appendChild(el0, el1);
                    var el1 = dom.createTextNode("\n");
                    dom.appendChild(el0, el1);
                    return el0;
                  },
                  buildRenderNodes: function buildRenderNodes() {
                    return [];
                  },
                  statements: [],
                  locals: [],
                  templates: []
                };
              }();
              return {
                meta: {
                  "revision": "Ember@2.9.1",
                  "loc": {
                    "source": null,
                    "start": {
                      "line": 29,
                      "column": 12
                    },
                    "end": {
                      "line": 35,
                      "column": 12
                    }
                  },
                  "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
                },
                isEmpty: false,
                arity: 0,
                cachedFragment: null,
                hasRendered: false,
                buildFragment: function buildFragment(dom) {
                  var el0 = dom.createDocumentFragment();
                  var el1 = dom.createComment("");
                  dom.appendChild(el0, el1);
                  return el0;
                },
                buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                  var morphs = new Array(1);
                  morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                  dom.insertBoundary(fragment, 0);
                  dom.insertBoundary(fragment, null);
                  return morphs;
                },
                statements: [["block", "if", [["get", "tbl.row.dirty", ["loc", [null, [30, 20], [30, 33]]], 0, 0, 0, 0]], [], 0, 1, ["loc", [null, [30, 14], [34, 21]]]]],
                locals: [],
                templates: [child0, child1]
              };
            }();
            return {
              meta: {
                "revision": "Ember@2.9.1",
                "loc": {
                  "source": null,
                  "start": {
                    "line": 4,
                    "column": 10
                  },
                  "end": {
                    "line": 36,
                    "column": 10
                  }
                },
                "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
              },
              isEmpty: false,
              arity: 1,
              cachedFragment: null,
              hasRendered: false,
              buildFragment: function buildFragment(dom) {
                var el0 = dom.createDocumentFragment();
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                var el1 = dom.createComment("");
                dom.appendChild(el0, el1);
                return el0;
              },
              buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
                var morphs = new Array(9);
                morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
                morphs[1] = dom.createMorphAt(fragment, 1, 1, contextualElement);
                morphs[2] = dom.createMorphAt(fragment, 2, 2, contextualElement);
                morphs[3] = dom.createMorphAt(fragment, 3, 3, contextualElement);
                morphs[4] = dom.createMorphAt(fragment, 4, 4, contextualElement);
                morphs[5] = dom.createMorphAt(fragment, 5, 5, contextualElement);
                morphs[6] = dom.createMorphAt(fragment, 6, 6, contextualElement);
                morphs[7] = dom.createMorphAt(fragment, 7, 7, contextualElement);
                morphs[8] = dom.createMorphAt(fragment, 8, 8, contextualElement);
                dom.insertBoundary(fragment, 0);
                dom.insertBoundary(fragment, null);
                return morphs;
              },
              statements: [["block", "tbl.column", [], ["id", 1, "title", "Bestand", "sortKey", "bezeichnung"], 0, null, ["loc", [null, [5, 12], [7, 27]]]], ["block", "tbl.column", [], ["id", 2, "title", "Fläche", "sortKey", "flaeche"], 1, null, ["loc", [null, [8, 12], [10, 27]]]], ["block", "tbl.column", [], ["id", 3, "title", "Nutzungsklasse", "sortKey", "nutzungsklasse_id"], 2, null, ["loc", [null, [11, 12], [13, 27]]]], ["block", "tbl.column", [], ["id", 4, "title", "Bewirtschaftungseinschränkung", "sortKey", "bewirtschaftungseinschraenkung_id"], 3, null, ["loc", [null, [14, 12], [16, 27]]]], ["block", "tbl.column", [], ["id", 5, "title", "Alter", "sortKey", "alter"], 4, null, ["loc", [null, [17, 12], [19, 27]]]], ["block", "tbl.column", [], ["id", 6, "title", "Vorrat pro Hektar", "sortKey", "vorrat"], 5, null, ["loc", [null, [20, 12], [22, 27]]]], ["block", "tbl.column", [], ["id", 7, "title", "Seehöhe", "sortKey", "seehoehe"], 6, null, ["loc", [null, [23, 12], [25, 27]]]], ["block", "tbl.column", [], ["id", 8, "title", "Kennzahlen", "sortKey", "status"], 7, null, ["loc", [null, [26, 12], [28, 27]]]], ["block", "tbl.column", [], ["id", 9, "title", "", "sortKey", "dirty", "width", "1px"], 8, null, ["loc", [null, [29, 12], [35, 27]]]]],
              locals: ["tbl"],
              templates: [child0, child1, child2, child3, child4, child5, child6, child7, child8]
            };
          }();
          return {
            meta: {
              "revision": "Ember@2.9.1",
              "loc": {
                "source": null,
                "start": {
                  "line": 3,
                  "column": 8
                },
                "end": {
                  "line": 37,
                  "column": 8
                }
              },
              "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
            },
            isEmpty: false,
            arity: 0,
            cachedFragment: null,
            hasRendered: false,
            buildFragment: function buildFragment(dom) {
              var el0 = dom.createDocumentFragment();
              var el1 = dom.createComment("");
              dom.appendChild(el0, el1);
              return el0;
            },
            buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
              var morphs = new Array(1);
              morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
              dom.insertBoundary(fragment, 0);
              dom.insertBoundary(fragment, null);
              return morphs;
            },
            statements: [["block", "x-table", [], ["paging", 15, "searchKey", "bezeichnung", "model", ["subexpr", "@mut", [["get", "model", ["loc", [null, [4, 61], [4, 66]]], 0, 0, 0, 0]], [], [], 0, 0]], 0, null, ["loc", [null, [4, 10], [36, 22]]]]],
            locals: [],
            templates: [child0]
          };
        }();
        return {
          meta: {
            "revision": "Ember@2.9.1",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 4
              },
              "end": {
                "line": 38,
                "column": 4
              }
            },
            "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
          },
          isEmpty: false,
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createComment("");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var morphs = new Array(1);
            morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
            dom.insertBoundary(fragment, 0);
            dom.insertBoundary(fragment, null);
            return morphs;
          },
          statements: [["block", "foundation-row-column", [], ["large", 12], 0, null, ["loc", [null, [3, 8], [37, 34]]]]],
          locals: [],
          templates: [child0]
        };
      }();
      return {
        meta: {
          "revision": "Ember@2.9.1",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 39,
              "column": 0
            }
          },
          "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
        },
        isEmpty: false,
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(1);
          morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
          dom.insertBoundary(fragment, 0);
          dom.insertBoundary(fragment, null);
          return morphs;
        },
        statements: [["block", "foundation-column", [], ["large", 12, "class", "columns-no-padding"], 0, null, ["loc", [null, [2, 4], [38, 26]]]]],
        locals: [],
        templates: [child0]
      };
    }();
    return {
      meta: {
        "revision": "Ember@2.9.1",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 40,
            "column": 0
          }
        },
        "moduleName": "mpforst-ember/app/pods/grunddaten-teilbetrieb/components/x-bestaende/template.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment, 0, 0, contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [["block", "foundation-row", [], [], 0, null, ["loc", [null, [1, 0], [39, 19]]]]],
      locals: [],
      templates: [child0]
    };
  }());
});