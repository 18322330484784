define('mpforst-ember/app/modules/delayed-queue', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.queueWaitUntil = queueWaitUntil;
  exports.default = queue;


  var queue_timer = null;
  var queue_list = [];
  var queue_waiter = [];

  function queueWaitUntil() {
    var deferred = Ember.$.Deferred();
    queue_waiter.push(deferred);
    return deferred.promise();
  }

  function waitFunc(waiter) {
    Ember.run.next(function () {
      Ember.run.next(function () {
        waiter.resolve(true);
      });
    });
  }

  function queue(f) {
    queue_list.push(f);
    if (queue_timer == null) {
      queue_timer = window.setInterval(function () {
        queue_list.shift()();
        if (!queue_list.length) {
          window.clearInterval(queue_timer);
          queue_timer = null;
          for (var i = 0; i < queue_waiter.length; ++i) {
            //3 times next just to make sure everything is rendered
            Ember.run.next(waitFunc, queue_waiter[i]);
          }
          queue_waiter = [];
        }
      }, 1);
    }
  }
});